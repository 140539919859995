import { CreateVoucherRequest, VoucherListResponse } from '@naus-code/naus-admin-types';
import { useCallback } from 'react';

import { useFormModal } from '~/components/Modals/FormModal';
import { useAppNavigation } from '~/hooks/nav.hook';
import { voucherApi } from '~/screens/Vouchers/voucherApi';
import { translate } from '~/utils/translation.utils';

export const useCreateVoucherModal = () => {
  const navigate = useAppNavigation();
  const { open: openFormModal } = useFormModal();

  const useFormHookHandler = useCallback(() => {
    const { data: config, isLoading } = voucherApi.useCreateVoucherFormConfigQuery();
    return { data: { config }, isLoading };
  }, []);

  const onConfirm = async ({
    action,
    value,
  }: {
    action: (req: CreateVoucherRequest) => { data: VoucherListResponse };
    value: CreateVoucherRequest['voucher'];
  }) => {
    const response = await action({
      voucher: { ...value, value: value.value * 100 },
    });
    const voucherList = response.data;
    navigate.navigate('CreateVoucher', { voucherList });
    return response;
  };

  return {
    open: () => {
      openFormModal({
        modalTitle: translate('voucher.createVoucher'),
        useFormHookHandler,
        actionHook: voucherApi.useCreateVoucherMutation,
        onConfirm,
      });
    },
  };
};
