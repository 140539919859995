import { create, StoreApi, UseBoundStore } from 'zustand';

export type LoyaltyToggleStore = UseBoundStore<
  StoreApi<{
    ids: {
      [key: string]: boolean;
    };
    toggleInput: (key: string) => void;
  }>
>;

export const createLoyaltyToggleStore = (): LoyaltyToggleStore =>
  create<{
    ids: {
      [key: string]: boolean;
    };
    toggleInput: (key: string) => void;
  }>((set) => ({
    ids: {},
    toggleInput: (idKey) => {
      set((state) => ({
        ids: {
          ...state.ids,
          [idKey]: !state.ids[idKey],
        },
      }));
    },
  }));
