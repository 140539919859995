import {
  UpdateAdminUserRequest,
  UpdateAdminUserResponse,
} from '@naus-code/naus-admin-types';
import { useCallback } from 'react';

import { useFormModal } from '~/components/Modals/FormModal';
import { usersApi } from '~/screens/Users/Users.api';
import { translate } from '~/utils/translation.utils';

export const useUpdateAdminUserModal = (adminUserId: string) => {
  const { open: openForm, close: closeForm } = useFormModal();

  const useFormHookHandler = useCallback(() => {
    const { data: config, isLoading } = usersApi.useGetUserConfigUpdateQuery(adminUserId);
    return { data: { config }, isLoading };
  }, [adminUserId]);

  const onConfirm = async ({
    action,
    value,
  }: {
    action: (req: UpdateAdminUserRequest) => UpdateAdminUserResponse;
    value: UpdateAdminUserRequest['adminUser'];
  }) => {
    return await action({ adminUser: value });
  };

  return {
    open: () => {
      openForm({
        modalTitle: translate('users.updateUserInfo'),
        useFormHookHandler,
        onConfirm,
        actionHook: usersApi.useUpdateUserMutation,
      });
    },
    close: () => {
      closeForm();
    },
  };
};
