import { ConfigForData } from '@droplet-tech-code/core-common-types';
import { TicketClient } from '@naus-code/naus-admin-types';

import { onReceiveValueWithUndefined } from '~/components/SupportRequest/utils/utils';

export function generateModifyBaseTicketInfoManualForm(
  ticket: TicketClient,
): ConfigForData {
  const {
    value,
    valueNet,
    valueVat,
    valueTax,
    valueCcy,
    ticketName,
    providerTicketCode,
    companyTicketCode,
    companyTicketLetter,
    companyTicketName,
    companyDiscountCode,
    ccy,
    companyCcy,
  } = ticket;

  return [
    {
      items: [
        {
          id: 'value',
          type: 'number',
          label: 'Value ' + ccy,
          optional: !value,
          initialValue: onReceiveValueWithUndefined(value),
        },
        {
          id: 'valueNet',
          type: 'number',
          label: 'Value Net',
          optional: !valueNet,
          initialValue: onReceiveValueWithUndefined(valueNet),
        },
        {
          id: 'valueVat',
          type: 'number',
          label: 'Value Vat',
          optional: !valueVat,
          initialValue: onReceiveValueWithUndefined(valueVat),
        },
        {
          id: 'valueTax',
          type: 'number',
          label: 'Value Tax',
          optional: !valueTax,
          initialValue: onReceiveValueWithUndefined(valueTax),
        },
        {
          id: 'valueCcy',
          type: 'number',
          label: 'Value Company Ccy ' + companyCcy,
          optional: !valueCcy,
          initialValue: onReceiveValueWithUndefined(valueCcy),
        },
      ],
    },
    {
      items: [
        {
          id: 'companyDiscountCode',
          type: 'text',
          label: 'Discount Code',
          optional: !companyDiscountCode,
          initialValue: companyDiscountCode,
        },
        {
          id: 'ticketName',
          type: 'text',
          label: 'Ticket Name',
          optional: !ticketName,
          initialValue: ticketName,
        },
        {
          id: 'companyTicketName',
          type: 'text',
          label: 'Company Ticket Name',
          optional: !companyTicketName,
          initialValue: companyTicketName,
        },
      ],
    },
    {
      items: [
        {
          id: 'providerTicketCode',
          type: 'text',
          label: 'Provider Ticket Code',
          optional: !providerTicketCode,
          initialValue: providerTicketCode,
        },
        {
          id: 'companyTicketCode',
          type: 'text',
          label: 'Company Ticket Code',
          optional: !companyTicketCode,
          initialValue: companyTicketCode,
        },
        {
          id: 'companyTicketLetter',
          type: 'text',
          label: 'Ticket Letter',
          optional: !companyTicketLetter,
          initialValue: companyTicketLetter,
        },
      ],
    },
  ];
}
