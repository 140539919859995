import { UpdateVoucherRequest, UpdateVoucherResponse } from '@naus-code/naus-admin-types';
import { useCallback } from 'react';

import { useFormModal } from '~/components/Modals/FormModal';
import { voucherApi } from '~/screens/Vouchers/voucherApi';
import { translate } from '~/utils/translation.utils';

export const useUpdateVoucher = (voucherId: string) => {
  const { open: openFormModal } = useFormModal();
  const modalTitle = translate('voucher.updateVoucher');

  const useFormHookHandler = useCallback(() => {
    const { data: config, isLoading } =
      voucherApi.useUpdateVoucherFormConfigQuery(voucherId);
    return { data: { config }, isLoading };
  }, [voucherId]);

  const onConfirm = async ({
    action,
    value,
  }: {
    action: (req: UpdateVoucherRequest) => UpdateVoucherResponse;
    value: UpdateVoucherRequest['voucher'];
  }) => {
    return await action({
      voucherId,
      voucher: { ...value, value: value.value ? value.value : 0 },
    });
  };

  return {
    open: () => {
      openFormModal<UpdateVoucherRequest, UpdateVoucherResponse>({
        modalTitle,
        useFormHookHandler,
        onConfirm,
        actionHook: voucherApi.useUpdateVoucherMutation,
      });
    },
  };
};
