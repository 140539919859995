import { Button } from '@droplet-tech-code/core-elements/module/ui/Button';
import { useModal } from '@droplet-tech-code/core-elements/module/ui/Modal';
import { HStack, VStack } from '@droplet-tech-code/core-elements/module/ui/Stack';
import { Text } from '@droplet-tech-code/core-elements/module/ui/Text';
import { notEmpty } from '@droplet-tech-code/core-elements/module/utils/utils.helper';
import { PriceClientVerifyAttention } from '@naus-code/naus-client-types';
import { useEffect } from 'react';

import {
  AttentionTicketChangeItem,
  AttentionTripInfoLine,
} from '~/components/BasketManager/Attention/Attention.TripInfo';
import { useBasketManager } from '~/components/BasketManager/BasketManager.Provider';
import { searchApi } from '~/components/Search/Search.api';
import { getPriceWithSymbol } from '~/screens/Support/Purchases/purchase.utils';
import { translate } from '~/utils/translation.utils';

export const BasketAttentionModalKey = 'BasketAttentionModalKey';

interface BasketAttentionPreInfo {
  tripKeys: string[];
  currency: string;
}

export interface BasketAttentionProps extends BasketAttentionItemProps {
  attention: PriceClientVerifyAttention;
}

export interface BasketAttentionItemProps {
  options: BasketAttentionPreInfo;
  onContinue?: () => void;
}

const BasketAttentionModal = (props: BasketAttentionProps) => {
  if (props.attention.priceChanged) {
    return <PriceChanged {...props} priceChanged={props.attention.priceChanged} />;
  }
  if (props.attention.accChanged) {
    return <AccommodationChanged {...props} accChanged={props.attention.accChanged} />;
  }
  if (props.attention.availabilityChanged) {
    return (
      <AvailabilityChanged
        {...props}
        availabilityChanged={props.attention.availabilityChanged}
      />
    );
  }
  return null;
};

export const useBasketAttentionModal = () => {
  const { openModal, closeModal } = useModal();
  return {
    open: (props: BasketAttentionProps) => {
      openModal(<BasketAttentionModal {...props} />, { key: BasketAttentionModalKey });
    },
    close: () => {
      closeModal(BasketAttentionModalKey);
    },
  };
};

export const useBasketAttentionManager = (props: BasketAttentionItemProps) => {
  const attentionModal = useBasketAttentionModal();
  const basketManager = useBasketManager();

  useEffect(() => {
    basketManager.setOnVerifyAttention((verifyAttention) => {
      attentionModal.open({
        ...props,
        attention: verifyAttention,
      });
    });
    return () => {
      basketManager.setOnVerifyAttention(() => {});
    };
  }, []);
};

const PriceChanged = (
  props: {
    priceChanged: NonNullable<PriceClientVerifyAttention['priceChanged']>;
  } & BasketAttentionItemProps,
) => {
  const { data: tripDetails } = searchApi.useGetTripDetailsQuery({
    tripKeys: props.options.tripKeys ?? [],
    ccy: props.options.currency,
  });

  if (!tripDetails) {
    return null;
  }

  const trips = props.priceChanged.breakdown
    .map((bd) => ({
      breakdown: bd,
      trip: tripDetails.trips.find((trip) => trip.key === bd.tripKey)!,
    }))
    .filter((item) => !!item.trip);

  return (
    <VStack>
      <VStack px="6" space="4">
        <Text.Body2Regular color="monochrome-mid">
          {translate('attention.pricesChanged')}
        </Text.Body2Regular>

        {trips.map(({ trip, breakdown }) => {
          return (
            <VStack key={trip.key} space="2.5">
              <AttentionTripInfoLine trip={trip} />
              <HStack align="center" space="3">
                <AttentionTicketChangeItem
                  icon="ticket"
                  text={
                    <HStack align="center" space="1">
                      <Text.Small>{breakdown.accName}</Text.Small>

                      <Text.Small
                        color="monochrome-mid"
                        style={{
                          textDecorationLine: 'line-through',
                        }}
                      >
                        {getPriceWithSymbol(breakdown.oldPrice, trip.ccySymbol)}
                      </Text.Small>

                      <Text.Small>
                        {getPriceWithSymbol(breakdown.newPrice, trip.ccySymbol)}
                      </Text.Small>
                    </HStack>
                  }
                  active
                />
              </HStack>
            </VStack>
          );
        })}

        <VStack space="2.5">
          <Button
            variant="outline"
            onPress={() => {
              // navigateToTicketSelection();
            }}
          >
            {translate('attention.changeSelection')}
          </Button>
        </VStack>
      </VStack>
    </VStack>
  );
};

const AccommodationChanged = (
  props: {
    accChanged: NonNullable<PriceClientVerifyAttention['accChanged']>;
  } & BasketAttentionItemProps,
) => {
  const { data: tripDetails } = searchApi.useGetTripDetailsQuery({
    tripKeys: props.options.tripKeys ?? [],
    ccy: props.options.currency,
  });

  if (!tripDetails) {
    return null;
  }

  const trips = props.accChanged.breakdown
    .map((bd) => {
      return {
        breakdown: bd,
        trip: tripDetails.trips.find((trip) => trip.key === bd.tripKey)!,
      };
    })
    .filter((item) => !!item.trip);

  return (
    <VStack>
      <VStack px="6" space="4">
        <Text.Body2Regular color="monochrome-mid">
          {translate('attention.notAvailable')}
        </Text.Body2Regular>

        {trips.map(({ trip, breakdown }) => {
          return (
            <VStack key={trip.key} space="2.5">
              <HStack align="center" space="3">
                <Text.Body2Medium>{`${trip.originDisplay} -> ${trip.destDisplay}`}</Text.Body2Medium>
                <Text.Body2Regular color="monochrome-extraLight">•</Text.Body2Regular>
                <Text.Body2Medium color="monochrome-mid">
                  {trip.depDateDisplay}
                </Text.Body2Medium>
              </HStack>
              <HStack align="center" space="3">
                <AttentionTicketChangeItem
                  icon="ticket"
                  text={
                    <Text.Small
                      color="monochrome"
                      style={[
                        {
                          textDecorationLine: 'line-through',
                        },
                      ]}
                    >
                      {breakdown.accName ?? breakdown.accId}
                    </Text.Small>
                  }
                  active={false}
                />
                <Text.Body2Regular color="black">{'->'}</Text.Body2Regular>
                <AttentionTicketChangeItem
                  icon="ticket"
                  text={<Text.Small>{breakdown.newAccName}</Text.Small>}
                  active
                />
              </HStack>
            </VStack>
          );
        })}

        {trips[0]?.trip.ccySymbol ? (
          <HStack my="2.5" p="2.5" b={1} bc="monochrome-extraLight" br={12}>
            <VStack flex={1} align="center" space="2.5">
              <Text.Body2Regular color="monochrome-mid">
                {translate('attention.oldPrice')}
              </Text.Body2Regular>

              <Text.Body2Medium
                style={{
                  textDecorationLine: 'line-through',
                }}
              >
                {getPriceWithSymbol(props.accChanged.oldPrice, trips[0]?.trip.ccySymbol)}
              </Text.Body2Medium>
            </VStack>
            <VStack flex={1} align="center" space="2.5">
              <Text.Body2Regular color="monochrome-mid">
                {translate('attention.newPrice')}
              </Text.Body2Regular>
              <Text.Body2Medium>
                {getPriceWithSymbol(props.accChanged.newPrice, trips[0]?.trip.ccySymbol)}
              </Text.Body2Medium>
            </VStack>
          </HStack>
        ) : null}

        <VStack space="2.5">
          <Button
            variant="outline"
            onPress={() => {
              // navigateToTicketSelection();
            }}
          >
            {translate('attention.verifyChanges')}
          </Button>
        </VStack>
      </VStack>
    </VStack>
  );
};

const AvailabilityChanged = (
  props: {
    availabilityChanged: NonNullable<PriceClientVerifyAttention['availabilityChanged']>;
  } & BasketAttentionItemProps,
) => {
  const basketManager = useBasketManager();
  const trips = props.availabilityChanged.breakdown
    .map((bd) => {
      const trip = basketManager.getRouteTrip(bd.tripKey);
      if (trip) {
        return {
          breakdown: bd,
          trip,
        };
      }
      return null;
    })
    .filter(notEmpty);

  return (
    <VStack>
      <VStack px="6" space="4">
        <Text.Body2Regular color="monochrome-mid">
          {translate('attention.lowAvailableInfo')}
        </Text.Body2Regular>

        {trips.map(({ trip, breakdown }) => {
          return (
            <VStack key={trip.key} space="2.5">
              <AttentionTripInfoLine trip={trip} />
              <HStack align="center" space="3">
                <AttentionTicketChangeItem
                  icon="ticket"
                  text={
                    <HStack align="center" space="1">
                      <Text.Small>{breakdown.accName}</Text.Small>
                      <Text.Small
                        color="secondary-light"
                        style={[
                          {
                            fontWeight: 'bold',
                          },
                        ]}
                      >
                        {translate('attention.left', {
                          count: breakdown.avail + '',
                        })}
                      </Text.Small>
                    </HStack>
                  }
                  active={false}
                />
              </HStack>
            </VStack>
          );
        })}

        <VStack space="2.5">
          <Button
            variant="outline"
            onPress={() => {
              // navigateToTicketSelection();
            }}
          >
            {translate('attention.changeSelection')}
          </Button>
        </VStack>
      </VStack>
    </VStack>
  );
};
