import { ConfigForData } from '@droplet-tech-code/core-common-types';
import {
  SupportConfigAddSupportTypeRequest,
  SupportConfigGetOperationConfigResponse,
  SupportConfigUpdateBufferMinutesRequest,
} from '@naus-code/naus-admin-types';
import { useCallback } from 'react';

import { useFormModal } from '~/components/Modals/FormModal';
import { supportConfigApi } from '~/screens/Configs/Support/supportConfig.api';
import { translate } from '~/utils/translation.utils';

export const useAddSupportTypeModal = () => {
  const { open: openForm, close: closeForm } = useFormModal();

  const useFormHookHandler = useCallback(() => {
    const { data, isLoading } = supportConfigApi.useAddSupportTypeFormConfigQuery();
    return { data, isLoading };
  }, []);

  const onConfirm = async ({
    action,
    value,
    check,
  }: {
    action: (
      req: SupportConfigAddSupportTypeRequest,
    ) => SupportConfigGetOperationConfigResponse;
    value: SupportConfigAddSupportTypeRequest['supportType'];
    check?: string;
  }) => {
    return await action({
      supportType: value,
      check: check || '',
    });
  };

  return {
    open: () => {
      openForm({
        modalTitle: translate('supportConfig.addSupportType'),
        useFormHookHandler,
        onConfirm,
        actionHook: supportConfigApi.useAddSupportTypeMutation(),
      });
    },
    close: () => {
      closeForm();
    },
  };
};

export const useEditBufferMinutes = ({ check }: { check?: string }) => {
  const { open: openForm, close: closeForm } = useFormModal();

  const onConfirm = async ({
    action,
    value,
    check,
  }: {
    action: (
      req: SupportConfigUpdateBufferMinutesRequest,
    ) => SupportConfigGetOperationConfigResponse;
    value: SupportConfigUpdateBufferMinutesRequest;
    check?: string;
  }) => {
    return await action({
      ...value,
      check: check || '',
    });
  };

  return {
    open: () => {
      const config: ConfigForData = [
        {
          items: [
            {
              type: 'text',
              label: translate('supportConfig.minutes'),
              id: 'bufferMinutes',
            },
          ],
        },
      ];
      openForm({
        modalTitle: translate('supportConfig.editBufferMinutes'),
        useFormHookHandler: () => {
          return { data: { config, check }, isLoading: false };
        },
        onConfirm,
        actionHook: supportConfigApi.useUpdateSupportRequestBufferMutation(),
      });
    },
    close: () => {
      closeForm();
    },
  };
};
