import { getErrorResponse } from '@droplet-tech-code/core-elements/module/utils/error';

import { searchApi } from '~/components/Search/Search.api';
import { bookingApi } from '~/screens/Support/Bookings/Bookings.api';
import { purchaseActionManager } from '~/screens/Support/Purchases/PurchaseActions/ActionPanel/Common/ActionPanel.utils';
import { supportRequestApi } from '~/screens/Support/Requests/SupportRequests.api';

import { ModificationType } from './Modify.Panel';

export interface ModifyPanelProps {
  bookingId: string;
  supportId: string;
  purchaseId: string;
}

export const useModifyInitial = (props: ModifyPanelProps) => {
  const { data: supportRequestRes, isLoading: isSupportLoading } =
    supportRequestApi.useGetSupportRequestQuery(props.supportId);
  const { data: bookingRes, isLoading: isLoadingBookingData } =
    bookingApi.useGetBookingQuery(props.bookingId);

  const purchases = bookingRes?.booking.purchases;
  const purchase = bookingRes?.booking.purchases.find((p) => p._id === props.purchaseId);

  const supportRequest = supportRequestRes?.supportRequest;
  const booking = bookingRes?.booking;

  return {
    isSupportLoading,
    isLoadingBookingData,
    supportRequest,
    booking,
    purchase,
    purchases,
  };
};

export const useModifyData = (
  props: ModifyPanelProps & { option?: ModificationType },
) => {
  const [selectedTickets] = purchaseActionManager.state.useGlobalState('selectedTickets');
  const [manuallySelectedRoute] = purchaseActionManager.state.useGlobalState(
    'manuallySelectedRoute',
  );
  const [providerOptions] = purchaseActionManager.state.useGlobalState('providerOptions');
  const [purchaseRequest] = purchaseActionManager.state.useGlobalState('purchaseRequest');

  const {
    supportRequest,
    booking,
    purchase,
    purchases,
    isSupportLoading,
    isLoadingBookingData,
  } = useModifyInitial(props);

  const requestOptions = supportRequest?.requestOptions;
  const tripKeyGen = manuallySelectedRoute?.key ?? purchase?.tripKey ?? '';
  const skipRoutePriceRequest =
    !tripKeyGen || !purchase || selectedTickets.length === 0 || !manuallySelectedRoute;
  // (!!requestOptions &&
  //   requestOptions.type === 'modify_v2' &&
  //   selectedTickets.length === requestOptions.calculation.ticketIds.length);

  const {
    data: newRoutePrice,
    error: routePriceError,
    isLoading: isGettingRoutePrice,
  } = searchApi.useVerifyPricePurchaseGenerateRoutePriceQuery(
    {
      purchaseId: props.purchaseId,
      ticketIds: selectedTickets,
      tripKey: tripKeyGen,
      supportId: props.supportId,
    },
    {
      skip: skipRoutePriceRequest,
    },
  );

  const fetchedRoutePrice = skipRoutePriceRequest ? undefined : newRoutePrice?.routePrice;
  const requestRoutePrice =
    requestOptions?.type === 'modify_v3' ? requestOptions.routePrice : undefined;
  const initialRoutePrice = fetchedRoutePrice ?? requestRoutePrice;

  const tripKeys = manuallySelectedRoute
    ? [manuallySelectedRoute.key]
    : initialRoutePrice?.trips.map((t) => t.key);

  const ccy = manuallySelectedRoute?.ccy ?? initialRoutePrice?.ccy ?? 'EUR';

  const skipTripDetailRequest = !ccy || !tripKeys || tripKeys.length === 0;
  const { data: tripDetails, isLoading: isLoadingTripDetails } =
    searchApi.useGetTripDetailsQuery(
      {
        tripKeys: tripKeys ?? [],
        ccy,
      },
      { skip: skipTripDetailRequest },
    );

  const isLoading =
    isGettingRoutePrice ||
    isLoadingBookingData ||
    isSupportLoading ||
    isLoadingTripDetails;

  return {
    booking,
    purchase,
    purchases,
    supportRequest,
    selectedTickets,
    selectedRoute: tripDetails?.trips[0],
    initialRoutePrice,
    manuallySelectedRoute,
    disabled: isDisabled(
      !!booking,
      !!purchase,
      !!supportRequest,
      !!initialRoutePrice,
      !!tripDetails,
      props.option,
    ),

    isLoading,
    error: getErrorResponse(routePriceError, '').message,
    complete:
      !!booking && !!supportRequest && !!purchase && !!initialRoutePrice && !isLoading,
    purchaseRequest,
    providerOptions,
  };
};

const isDisabled = (
  booking: boolean,
  purchase: boolean,
  supportRequest: boolean,
  initialRoutePrice: boolean,
  tripDetails: boolean,
  option?: ModificationType,
) => {
  if (option === 'Auto-update') {
    return !booking || !purchase || !supportRequest;
  }
  return !booking || !purchase || !supportRequest || !initialRoutePrice || !tripDetails;
};

export const resetBasketManager = () => {
  // basketManager.initiate({
  //   basketDetails: {
  //     passengerCount: 0,
  //     vehicleCount: 0,
  //   },
  //   selectedTrips: [],
  //   savedPassengers: [],
  //   savedVehicles: [],
  // });
};
