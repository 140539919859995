import {
  Icon,
  IconProps,
  IconsId,
} from '@droplet-tech-code/core-elements/module/ui/Icon';
import { HStack } from '@droplet-tech-code/core-elements/module/ui/Stack';
import { View } from '@droplet-tech-code/core-elements/module/ui/View';
import { isNativeMobile } from '@droplet-tech-code/core-elements/module/utils/utils.helper';

import { LinkText } from '~/components/LinkText';

export const ClickableListItem = ({
  onPress,
  children,
  isLoading,
  disable,
  icon,
  ...iconProps
}: { onPress: () => void } & Omit<IconProps, 'icon'> & {
    isLoading?: boolean;
    disable?: boolean;
    children: string;
    icon?: IconsId;
  }) => {
  return (
    <HStack space="1" px="3" flex={isNativeMobile ? 1 : undefined}>
      <View flex={1} />
      <HStack>
        <LinkText
          isLoading={isLoading}
          onPress={() => {
            if (!disable) {
              onPress();
            }
          }}
        >
          {children}
        </LinkText>
        {icon && <Icon icon={icon} {...iconProps} size="small" />}
      </HStack>
    </HStack>
  );
};

export const onReceiveValue = (valueReceived?: number): number => {
  if (!valueReceived) {
    return 0;
  }
  return Number((valueReceived / 100).toFixed(2));
};

export const onReceiveValueWithUndefined = (
  valueReceived?: number,
): number | undefined => {
  if (valueReceived === undefined) {
    return undefined;
  }
  if (!valueReceived) {
    return 0;
  }
  return Number((valueReceived / 100).toFixed(2));
};

export const onSubmitValue = (valueToSubmit?: number): number => {
  if (!valueToSubmit) {
    return 0;
  }
  return Number((valueToSubmit * 100).toFixed(0));
};

export const onSubmitValueWithUndefined = (
  valueToSubmit?: number,
): number | undefined => {
  if (valueToSubmit === undefined) {
    return undefined;
  }
  return Number((valueToSubmit * 100).toFixed(0));
};

export const safeMultiply = (a: number, b: number): number => {
  const factor = 100;
  return Math.round(
    (Math.round(a * factor) * Math.round(b * factor)) / (factor * factor),
  );
};
