import { VStack } from '@droplet-tech-code/core-elements/module/ui/Stack';
import { GetCustomerResponse, RatingClient } from '@naus-code/naus-admin-types';
import { CustomerPassenger, CustomerVehicle } from '@naus-code/naus-client-types';

import { Field } from '~/components/Card/Field';
import { Pill } from '~/components/Pill/Pill';
import { RatingInfo } from '~/components/Ratings/RatingItem';
import { AppText } from '~/components/Text';
import { translate } from '~/utils/translation.utils';

export const VehicleInfo = ({ vehicle }: { vehicle: CustomerVehicle }) => {
  return (
    <VStack p="2.5">
      <Field
        label={translate('customer.id')}
        value={vehicle.id || '-'}
        valueType="small"
        isCopyable
        labelType="extraSmall"
      />
      <Field
        label={translate('customer.vehicleName')}
        value={vehicle.nickName || '-'}
        valueType="small"
        labelType="extraSmall"
      />
      <Field
        label={translate('customer.category')}
        value={vehicle.category || '-'}
        valueType="small"
        labelType="extraSmall"
      />
      <Field
        label={translate('customer.plateNumber')}
        value={vehicle.p_plateNumber || '-'}
        valueType="small"
        labelType="extraSmall"
      />
      <Field
        label={translate('customer.height')}
        value={vehicle.p_height || '-'}
        valueType="small"
        labelType="extraSmall"
      />
      <Field
        label={translate('customer.length')}
        value={vehicle.p_length || '-'}
        valueType="small"
        labelType="extraSmall"
      />
      {/* <Field
        label={translate('customer.brand')}
        value={vehicle.p_brand || '-'}
        valueType="small"
        labelType="extraSmall"
      /> */}
      <Field
        label={translate('customer.model')}
        value={vehicle.model_id || '-'}
        valueType="small"
        labelType="extraSmall"
      />
    </VStack>
  );
};

export const RatingCustomerInfo = ({ rating }: { rating: RatingClient }) => {
  return (
    <VStack p="2.5">
      <RatingInfo feedback={rating} />
    </VStack>
  );
};

export const PassengerInfo = ({ passenger }: { passenger: CustomerPassenger }) => {
  return (
    <VStack p="2.5">
      <Field
        label={translate('customer.id')}
        value={passenger.id || '-'}
        valueType="small"
        isCopyable
        labelType="extraSmall"
      />
      <Field
        label={translate('customer.passengerName')}
        value={passenger.p_name + ' ' + passenger.p_lastName || '-'}
        valueType="small"
        labelType="extraSmall"
      />
      <Field
        label={translate('customer.birthDate')}
        value={passenger.p_birthDate || '-'}
        valueType="small"
        labelType="extraSmall"
      />
      <Field
        label={translate('customer.isDefault')}
        value={passenger.isDefault ? 'Yes' : 'No'}
        valueType="small"
        labelType="extraSmall"
      />
      {passenger.p_loyalty && (
        <VStack>
          {Object.entries(passenger.p_loyalty).map(([key, value], index) => {
            return (
              <VStack key={index}>
                <Field
                  label={translate('customer.loyaltyName')}
                  value={key}
                  valueType="small"
                  labelType="extraSmall"
                />
                <Field
                  label={translate('customer.loyaltyNumber')}
                  value={value}
                  isCopyable
                  valueType="small"
                  labelType="extraSmall"
                />
              </VStack>
            );
          })}
        </VStack>
      )}
    </VStack>
  );
};

export const CustomerDeletedPill = ({
  customer,
}: {
  customer: GetCustomerResponse['customer'];
}) => {
  if (!customer.deleted) {
    return null;
  }

  return (
    <Pill color="error">
      <AppText.MiscTag color="error">
        {translate('supportRequests.deleted')}
      </AppText.MiscTag>
    </Pill>
  );
};
