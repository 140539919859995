import {
  PasSelectDiscountLoyaltyRequired,
  PasSelectLoyaltyRes,
  PasSelectTicketAdditionalInfoRequired,
  PasSelectTicketAdditionalInfoRequiredRes,
  PasSelectTicketPassengersRequired,
  PasSelectTicketPassengersRequiredRes,
} from '@naus-code/naus-basket-manager';

import { createGlobalActionModalState } from '../store/store';

export interface RequiredRequestId {
  itemId: string;
}

export const createTicketsPricingPassengerExtraInfoStore = () =>
  createGlobalActionModalState<
    PasSelectTicketAdditionalInfoRequired & RequiredRequestId,
    PasSelectTicketAdditionalInfoRequiredRes
  >();

export const createTicketsPricingPassengerJoinPassengersStore = () =>
  createGlobalActionModalState<
    PasSelectTicketPassengersRequired & RequiredRequestId,
    PasSelectTicketPassengersRequiredRes
  >();

export const createTicketsPricingPassengerLoyaltyStore = () =>
  createGlobalActionModalState<
    Partial<PasSelectDiscountLoyaltyRequired> & RequiredRequestId,
    PasSelectLoyaltyRes | undefined
  >();

export const createTicketsPricingPassengerDiscountStore = () =>
  createGlobalActionModalState<RequiredRequestId, string>();
