import { ButtonInteractionState } from '@droplet-tech-code/core-elements/module/ui/Button';
import { IconCircle, IconsId } from '@droplet-tech-code/core-elements/module/ui/Icon';
import { useModal } from '@droplet-tech-code/core-elements/module/ui/Modal';
import {
  PopoverContainer,
  popoverControl,
  PopoverList,
} from '@droplet-tech-code/core-elements/module/ui/Popover';
import {
  copyToClipboard,
  isWeb,
} from '@droplet-tech-code/core-elements/module/utils/utils.helper';
import { BookingClientWithPurchasesAndTickets } from '@naus-code/naus-admin-types';
import { TouchableOpacity } from 'react-native';

import { useImportPurchaseFromProvider } from '~/screens/Support/Purchases/PurchaseActions/ActionPanel/ImportPurchase/ActionPanel.Import';
import { translate } from '~/utils/translation.utils';

import { useBookingTransaction } from '../BookingTools/Modals/BookingTransactionsModal';
import { useCustomerModal } from '../Customer/Customer.Modal';
import { MenuActionItem } from '../MenuActionItem';
import { useSendBookingConfirmationModal } from './Modals/SendBookingConfirmationModal';
import { useSendInvoiceModal } from './Modals/SendInvoiceModal';

const BookingActionItem = ({ item }: { item: BookingMenuItem }) => {
  const { closeModal } = useModal();
  return (
    <TouchableOpacity
      onPress={() => {
        closeModal('openPopover');
        popoverControl.closePopover();
        item.onPress();
      }}
    >
      <MenuActionItem icon={item.icon} text={item.text} />
    </TouchableOpacity>
  );
};

export type BookingMenuItem = ReturnType<typeof getBookingMenuList>[0];

export const BookingMenu = ({
  booking,
  supportId,
}: {
  booking: BookingClientWithPurchasesAndTickets;
  supportId?: string;
}) => {
  return (
    <PopoverContainer
      width={250}
      maxHeight={320}
      Popover={
        <PopoverList
          list={[
            ...getBookingMenuList(booking, supportId).map((item) => {
              return {
                item: <BookingActionItem item={item} />,
                onPress: item.onPress,
              };
            }),
          ]}
        />
      }
    >
      {({ hovered }: ButtonInteractionState) => (
        <IconCircle icon="menu" hovered={hovered} />
      )}
    </PopoverContainer>
  );
};

const getBookingMenuList = (
  booking: BookingClientWithPurchasesAndTickets,
  supportId?: string,
) => {
  const { closeModal } = useModal();
  const { open: openCustomer } = useCustomerModal();
  const sendBooking = useSendBookingConfirmationModal({
    bookingId: booking._id,
    email: booking.p_email,
  });

  const sendInvoice = useSendInvoiceModal({
    booking: booking,
    email: booking.p_email,
  });

  const { open: openBookingTransactions } = useBookingTransaction();
  const importPurchase = useImportPurchaseFromProvider(
    booking.paymentSessions[0]?.paymentSummary.currency,
  );

  return [
    {
      id: 'bookingId' as const,
      icon: 'copy' as IconsId,
      text: booking._id,
      arg: [''],
      onPress: () => {
        closeModal('openPopover');
        popoverControl.closePopover();
        copyToClipboard(booking._id);
      },
    },
    {
      id: 'resendBooking' as const,
      icon: 'email' as IconsId,
      text: translate('bookings.resendBooking'),
      onPress: () => {
        closeModal('openPopover');
        popoverControl.closePopover();
        sendBooking.open();
      },
    },
    {
      id: 'sendInvoice' as const,
      icon: 'receipt-email' as IconsId,
      text: translate('bookings.sendInvoice'),
      onPress: () => {
        closeModal('openPopover');
        popoverControl.closePopover();
        sendInvoice.open();
      },
    },
    {
      id: 'openCustomer' as const,
      icon: 'passenger' as IconsId,
      text: translate('bookings.viewCustomer'),
      arg: [booking._customerId, booking.p_email],
      onPress: () => {
        closeModal('openPopover');
        popoverControl.closePopover();
        const [customerId, bookingEmail] = [booking._customerId, booking.p_email];
        openCustomer(customerId, bookingEmail);
      },
    },
    ...[
      ...(isWeb
        ? [
            {
              id: 'bookingTransactions' as const,
              icon: 'transactions' as IconsId,
              text: translate('bookingTransactions.bookingTransactions'),
              onPress: () => {
                closeModal('openPopover');
                popoverControl.closePopover();
                const bookingId = booking._id;
                openBookingTransactions(bookingId);
              },
            },
          ]
        : []),
    ],
    {
      id: 'importPurchase' as const,
      icon: 'download-file' as IconsId,
      // disabled: !supportId,
      text: translate('purchases.purchaseTool.importPurchaseToBooking'),
      onPress: () => {
        closeModal('openPopover');
        popoverControl.closePopover();
        importPurchase.open({
          bookingId: booking._id,
          supportId: supportId || '',
        });
      },
    },
  ];
};
