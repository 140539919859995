import {
  ConfigForData,
  FormGroupList,
  SelectedValueItem,
} from '@droplet-tech-code/core-common-types';
import { TicketVehicleWithHistory } from '@naus-code/naus-client-types';
import { ReplaceFirebaseTimestamp } from '@naus-code/naus-server-utils-common-types';

import { onReceiveValueWithUndefined } from '~/components/SupportRequest/utils/utils';

export function generateModifyVehicleTicketInfoManualForm(
  ticket: ReplaceFirebaseTimestamp<TicketVehicleWithHistory>,
  countries: SelectedValueItem[],
): ConfigForData {
  const {
    modelId,
    length,
    height,
    category,
    loyaltyNumber,
    engineCategory,
    p_nationality,
    roof,
    trailer,
    sideCar,
    parentTicketNumber,
    companyTicketNumber,
  } = ticket;

  const vehicleList: SelectedValueItem[] = [
    { value: 'car' },
    { value: 'bike' },
    { value: 'moto' },
    { value: 'trike' },
    { value: 'quad' },
    { value: 'camper' },
    { value: 'trailer' },
    { value: 'bus' },
  ];

  const engineCategoryList: SelectedValueItem[] = [
    { value: 'diesel' },
    { value: 'petrol' },
    { value: 'hybrid' },
    { value: 'plug-in' },
    { value: 'gas' },
  ];

  let roofConfig = [] as FormGroupList;
  let trailerConfig = [] as FormGroupList;
  let sideCarConfig = [] as FormGroupList;

  if (roof) {
    roofConfig = [
      {
        id: 'roofLength',
        type: 'number',
        label: 'Roof Length',
        optional: !roof?.length,
        initialValue: onReceiveValueWithUndefined(roof?.length),
      },
      {
        id: 'roofHeight',
        type: 'number',
        label: 'Roof Height',
        optional: !roof?.height,
        initialValue: onReceiveValueWithUndefined(roof?.height),
      },
    ];
  }
  if (trailer) {
    trailerConfig = [
      {
        id: 'trailerLength',
        type: 'number',
        label: 'Roof Length',
        optional: !trailer?.length,
        initialValue: onReceiveValueWithUndefined(trailer?.length),
      },
      {
        id: 'trailerHeight',
        type: 'number',
        label: 'Roof Height',
        optional: !trailer?.height,
        initialValue: onReceiveValueWithUndefined(trailer?.height),
      },
    ];
  }
  if (sideCar) {
    sideCarConfig = [
      {
        id: 'sideCar',
        type: 'toggle',
        label: 'Has Sidecar',
        initialValue: sideCar?.enabled,
      },
    ];
  }

  return [
    {
      items: [
        {
          id: 'parentTicketNumber',
          type: 'text',
          label: 'Parent Ticket Number',
          optional: !parentTicketNumber,
          initialValue: parentTicketNumber || '',
        },
        {
          id: 'companyTicketNumber',
          type: 'text',
          label: 'Company Ticket Number',
          optional: !companyTicketNumber,
          initialValue: companyTicketNumber,
        },
        {
          id: 'p_nationality',
          type: 'single-select',
          label: 'Driver Nationality',
          list: countries,
          optional: !p_nationality,
          initialValue: p_nationality,
        },
        {
          id: 'loyaltyNumber',
          type: 'text',
          label: 'Loyalty',
          optional: !loyaltyNumber,
          initialValue: loyaltyNumber,
        },
      ],
    },
    {
      items: [
        {
          id: 'category',
          type: 'single-select',
          label: 'Category',
          list: vehicleList,
          optional: !category,
          initialValue: category,
        },
        {
          id: 'engineCategory',
          type: 'single-select',
          label: 'Engine category',
          list: engineCategoryList,
          optional: !engineCategory,
          initialValue: engineCategory,
        },
        {
          id: 'modelId',
          type: 'text',
          label: 'Model',
          optional: !modelId,
          initialValue: modelId,
        },
      ],
    },
    {
      items: [
        {
          id: 'length',
          type: 'number',
          label: 'Length',
          optional: !length,
          initialValue: onReceiveValueWithUndefined(length),
        },
        {
          id: 'height',
          type: 'number',
          label: 'Height',
          optional: !height,
          initialValue: onReceiveValueWithUndefined(height),
        },
        ...roofConfig,
      ],
    },
    {
      items: [...trailerConfig, ...sideCarConfig],
    },
  ];
}
