import { useAppTheme } from '@droplet-tech-code/core-elements/module/theme';
import { Divider } from '@droplet-tech-code/core-elements/module/ui/Divider/Divider';
import { VStack } from '@droplet-tech-code/core-elements/module/ui/Stack';
import { isNativeMobile } from '@droplet-tech-code/core-elements/module/utils/utils.helper';
import { MetaSearchJobClient } from '@naus-code/naus-admin-types';
import { RefreshControl } from 'react-native';

import { FlatList } from '~/components/FlatList/FlatList';
import { useItemSeparatorComponent } from '~/components/FlatList/FlatList.Common';
import { translate } from '~/utils/translation.utils';

import { systemApi } from '~/utils/system.api';
import { MetaSearchJobListHeader } from './MetaSearchJob.listHeader';
import { MetaSearchJobListItemComponent } from './MetaSearchJob.listItem';
import { LoadingIndicator } from '@droplet-tech-code/core-elements/module/ui/Loading';
import { View } from '@droplet-tech-code/core-elements/module/ui/View';

// export const PortLocationListNative = () => {
//   const navigate = useAppNavigation();
//   return (
//     <MetaSearchJobList
//       onPress={(item) => {
//         navigate.navigate('PortLocation-Item', {
//           id: item.id,
//         });
//       }}
//     />
//   );
// };

export const MetaSearchJobList = ({
  id,
  onPress,
}: {
  id?: string;
  onPress: (id: MetaSearchJobClient) => void;
}) => {
  const { data, isLoading, isFetching, refetch } = systemApi.useGetMetaSearchJobsQuery(
    undefined,
    { pollingInterval: 10000 },
  );

  const currentJobId = id || '';
  const { spacing } = useAppTheme();
  const ItemSeparatorComponent = useItemSeparatorComponent();

  const renderItem = (metaSearchJob: MetaSearchJobClient) => {
    return (
      <MetaSearchJobListItemComponent
        key={metaSearchJob.id}
        metaSearchJob={metaSearchJob}
        selected={currentJobId === metaSearchJob.id}
        onPress={() => onPress(metaSearchJob)}
      />
    );
  };

  if (!data) {
    return (
      <View flex={1} align="center" justify="center">
        <LoadingIndicator />
      </View>
    );
  }

  return (
    <VStack flex={1}>
      <MetaSearchJobListHeader refetch={refetch} />
      <Divider />
      <FlatList
        refreshControl={
          <RefreshControl refreshing={isFetching} onRefresh={() => refetch()} />
        }
        contentContainerStyle={[
          {
            paddingTop: spacing[2.5],
          },

          isNativeMobile && { paddingHorizontal: spacing[2] },
        ]}
        feedback={translate('portLocations.emptyPortLocations')}
        isLoading={isLoading}
        data={data.list}
        simple
        renderItem={renderItem}
        onPressItem={(v) => {
          onPress(v);
        }}
        ItemSeparatorComponent={ItemSeparatorComponent}
      />
    </VStack>
  );
};
