import {
  FormBuilder,
  FormHandler,
} from '@droplet-tech-code/core-elements/module/ui/Inputs/Form';
import { Modal } from '@droplet-tech-code/core-elements/module/ui/Modal';
import { VStack } from '@droplet-tech-code/core-elements/module/ui/Stack';
import { View } from '@droplet-tech-code/core-elements/module/ui/View';
import { handleResponse } from '@droplet-tech-code/core-elements/module/utils/error';
import { PasSelectLoyaltyRes } from '@naus-code/naus-basket-manager';
import { useRef, useState } from 'react';

import { useBasketManager } from '~/components/BasketManager/BasketManager.Provider';
import { StateModal } from '~/components/Modals/Modal.utils';
import { translate } from '~/utils/translation.utils';

import { useTicketsPricingPassengerLoyaltyStore } from '../../ticketPricing/TicketPricing.Provider';
import { TicketsPricingPassengerItem } from '../../ticketPricing/TicketsPricing.TripItem';

export const TicketsPricingPassengerLoyaltyModal = (
  props: TicketsPricingPassengerItem,
) => {
  const modalState = useTicketsPricingPassengerLoyaltyStore()((s) => s);
  const form = useRef(new FormHandler());
  const basketManager = useBasketManager();
  const isApplyingRef = useRef(false);

  const [loyaltyBusy, setLoyaltyBusy] = useState(false);
  const passengerRequiredData = modalState.requestData;

  const applyLoyaltyNumber = async () => {
    if (isApplyingRef.current) {
      return;
    }
    const { value } = form.current.getValue<{ loyaltyCode: string }>();
    const loyaltyNumber = value['loyaltyCode'];
    isApplyingRef.current = true;
    setLoyaltyBusy(true);
    const verifyLoyalty = modalState.requestData?.verifyLoyalty;

    let response = {} as PasSelectLoyaltyRes | undefined;
    if (verifyLoyalty) {
      response = await verifyLoyalty(loyaltyNumber);
    } else {
      response = await basketManager.selectPassengerLoyalty({
        loyaltyNumber,
        passengerId: props.passengerId,
        tripKey: props.trip.key,
      });
    }

    handleResponse({
      response,
      onSuccess: () => {
        modalState.onDone?.({
          responseData: response,
          status: 'success',
        });
      },
      onError: () => {},
    });
    isApplyingRef.current = false;
    setLoyaltyBusy(false);
  };

  if (!passengerRequiredData || passengerRequiredData.itemId !== props.passengerId) {
    return null;
  }
  return (
    <StateModal isOpen={modalState.isOpen} closeModal={modalState.close}>
      <Modal.Header
        title={translate('basketManager.ticketSelection.loyalty.loyaltyCard')}
        subtitle={translate('basketManager.ticketSelection.loyalty.enterPlaceholder')}
        onPress={modalState.close}
      />
      <Modal.Body>
        <VStack space="2.5">
          <View>
            <FormBuilder
              data={[
                {
                  items: [
                    {
                      type: 'text',
                      label: translate(
                        'basketManager.ticketSelection.loyalty.enterLoyaltyNumber',
                      ),
                      id: 'loyaltyCode',
                    },
                  ],
                },
              ]}
              form={form.current}
            />
          </View>
        </VStack>
      </Modal.Body>
      <Modal.Footer
        isLoading={loyaltyBusy}
        onConfirm={applyLoyaltyNumber}
        onCancel={modalState.close}
      />
    </StateModal>
  );
};
