import {
  GetPurchaseResponse,
  PurchaseUpdate,
  SupportRequestBackendClient,
} from '@naus-code/naus-admin-types';
import { RouteTrip, RouteTripNoDetails } from '@naus-code/naus-client-types';
import { createGlobalState } from 'react-hooks-global-state';

export interface TicketSelectionPurchaseProps {
  supportRequest: SupportRequestBackendClient;
  purchase: GetPurchaseResponse['purchase'];
}
//TODO remove- hack because yarn not updating
export interface ProviderOptions {
  expectedChargeByCompany: number;
  modifyDate: string;
}

class PurchaseActionManager {
  state = this._assignState();
  private _assignState() {
    return createGlobalState<{
      selectedTickets: string[];
      openMode: 'visual' | 'provider-api' | 'provider-external';
      paymentSessionIds: string[];
      manuallySelectedRoute: RouteTrip | RouteTripNoDetails | undefined;
      purchaseRequest: PurchaseUpdate | undefined;
      providerOptions: ProviderOptions | undefined;
      selectedReservation: string;
      // routeTrips: RouteTrip[];
    }>({
      selectedTickets: [],
      openMode: 'provider-api',
      paymentSessionIds: [],
      manuallySelectedRoute: undefined,
      purchaseRequest: undefined,
      providerOptions: undefined,
      selectedReservation: '',
      // routeTrips: [],
    });
  }
  reset() {
    this.state.setGlobalState('selectedTickets', []);
    this.state.setGlobalState('selectedReservation', '');
    this.state.setGlobalState('openMode', 'visual');
    this.state.setGlobalState('paymentSessionIds', []);
    this.state.setGlobalState('manuallySelectedRoute', undefined);
    this.state.setGlobalState('purchaseRequest', undefined);
    this.state.setGlobalState('providerOptions', undefined);
    // this.state.setGlobalState('routeTrips', []);
  }
}

export const purchaseActionManager = new PurchaseActionManager();
