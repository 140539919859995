import { CheckBox } from '@droplet-tech-code/core-elements/module/ui/Checkbox';
import { DelayRender } from '@droplet-tech-code/core-elements/module/ui/Delay';
import { FormHandler } from '@droplet-tech-code/core-elements/module/ui/Inputs/Form';
import { SelectInputBase } from '@droplet-tech-code/core-elements/module/ui/Inputs/SelectInput/SelectInput';
import { TextInput } from '@droplet-tech-code/core-elements/module/ui/Inputs/TextInput/TextInput';
import { Modal, useModal } from '@droplet-tech-code/core-elements/module/ui/Modal';
import { HStack, VStack } from '@droplet-tech-code/core-elements/module/ui/Stack';
import { Text } from '@droplet-tech-code/core-elements/module/ui/Text';
import { Toggle } from '@droplet-tech-code/core-elements/module/ui/Toggle/Toggle';
import { View } from '@droplet-tech-code/core-elements/module/ui/View';
import { handleResponse } from '@droplet-tech-code/core-elements/module/utils/error';
import { dayjs } from '@droplet-tech-code/core-elements/module/utils/utils.date';
import { isWeb } from '@droplet-tech-code/core-elements/module/utils/utils.helper';
import { PurchaseClientOpenProviderRequest } from '@naus-code/naus-admin-types';
import { MutableRefObject, useEffect, useRef, useState } from 'react';

import { BasketManagerProvider } from '~/components/BasketManager/BasketManager.Provider';
import { TicketsPricingProvider } from '~/components/BasketManager/utils/ticketPricing/TicketPricing.Provider';
import { ModalScrollView } from '~/components/Modals/Modal.utils';
import { PurchaseTicketsToSelect } from '~/screens/Support/Purchases/PurchaseActions/ActionPanel/Common/ActionPanel.TicketSelector';
import { purchaseActionManager } from '~/screens/Support/Purchases/PurchaseActions/ActionPanel/Common/ActionPanel.utils';
import {
  useGetOpenTicketData,
  useSelectOpenHook,
} from '~/screens/Support/Purchases/PurchaseActions/ActionPanel/Open/Open.utils';
import { DATE_TIME_SERVER_FORMAT } from '~/utils/date.utils';
import { TKeys, translate } from '~/utils/translation.utils';

import { ManualModificationProvider } from '../Modify/Manual/ManualProvider/Modify.ManualTrip.Provider';

interface OpenPanelProps {
  supportId: string;
  purchaseId: string;
}
const ModalKey = 'useOpenPanel';

const OpenPanel = (props: OpenPanelProps) => {
  const { closeModal } = useModal();

  const formRef = useRef(new FormHandler());

  useEffect(() => {
    purchaseActionManager.reset();
  }, []);

  return (
    <Modal.Container>
      <BasketManagerProvider>
        <TicketsPricingProvider>
          <ManualModificationProvider>
            <Modal.Header
              divider
              title="Open purchase"
              onPress={() => {
                closeModal(ModalKey);
              }}
            />

            <OpenPanelBody {...props} formRef={formRef} />

            <OpenPanelFooter {...props} formRef={formRef} />
          </ManualModificationProvider>
        </TicketsPricingProvider>
      </BasketManagerProvider>
    </Modal.Container>
  );
};

const OpenPanelBody = (
  props: OpenPanelProps & { formRef: MutableRefObject<FormHandler> },
) => {
  const [openMode, setOpenMode] = purchaseActionManager.state.useGlobalState('openMode');
  const {
    purchaseRes,
    isPurchaseLoading,
    supportRequestRes,
    isSupportLoading,
    isPurchaseCalcLoading,
    validUntil,
  } = useGetOpenTicketData(props);

  const [forceSelectAll, setForceSelect] = useState(false);
  const [isProvider, setIsProvider] = useState(false);

  return (
    <DelayRender
      isLoading={isPurchaseLoading || isSupportLoading || isPurchaseCalcLoading}
    >
      <ModalScrollView>
        {purchaseRes && supportRequestRes ? (
          <VStack space="3" p="2.5">
            <SelectInputBase
              feedback=""
              noFeedback
              label="Select open type:"
              values={[openMode]}
              type="single-select"
              options={[
                { label: 'Open visually', value: 'visual' },
                { label: 'Provider-side', value: 'provider-api' },
              ]}
              setValues={(newValues) => {
                if (newValues?.[0]) {
                  setIsProvider(newValues[0].includes('provider'));
                  setOpenMode(newValues[0] as any);
                }
              }}
            />
            {isProvider && (
              <SelectInputBase
                feedback=""
                noFeedback
                label="Select provider type:"
                values={[openMode]}
                type="single-select"
                options={[
                  { label: 'API', value: 'provider-api' },
                  { label: 'External', value: 'provider-external' },
                ]}
                setValues={(newValues) => {
                  if (newValues?.[0]) {
                    setOpenMode(newValues[0] as any);
                  }
                }}
              />
            )}

            <TextInput
              form={props.formRef.current}
              config={{
                id: 'validUntil',
                type: 'text',
                initialValue: validUntil
                  ? dayjs(validUntil).format(DATE_TIME_SERVER_FORMAT)
                  : '',
                label: 'Valid until',
                optional: true,
              }}
            />
            {openMode === 'provider-external' && (
              <TextInput
                form={props.formRef.current}
                config={{
                  id: 'dateSetOpen',
                  type: 'text',
                  initialValue: dayjs().format(DATE_TIME_SERVER_FORMAT),
                  label: 'Date set open',
                }}
              />
            )}

            <HStack space="1">
              <CheckBox
                checked={forceSelectAll}
                onPress={() => {
                  setForceSelect(!forceSelectAll);
                }}
              />
              <Text.Body2Medium
                color={forceSelectAll ? 'monochrome-dark' : 'monochrome-extraDark'}
              >
                {translate('purchases.purchaseTool.forceSelect')}
              </Text.Body2Medium>
            </HStack>

            <PurchaseTicketsToSelect
              title={translate('purchases.purchaseTool.selectTicketsToOpen')}
              purchase={purchaseRes.purchase}
              supportRequest={supportRequestRes.supportRequest}
              forceSelectAll={forceSelectAll}
            />
          </VStack>
        ) : null}
      </ModalScrollView>
    </DelayRender>
  );
};

const OpenPanelFooter = (
  props: OpenPanelProps & { formRef: MutableRefObject<FormHandler> },
) => {
  const { closeModal } = useModal();
  const [openMode] = purchaseActionManager.state.useGlobalState('openMode');
  const [openPurchase, { isLoading: isOpening }] = useSelectOpenHook(openMode)();
  const [silentOn, setSilentOn] = useState(false);

  const { isSupportLoading, isPurchaseCalcLoading, ticketIds } =
    useGetOpenTicketData(props);

  const confirmText: TKeys =
    openMode === 'visual'
      ? 'purchases.purchaseTool.openVisually'
      : 'purchases.purchaseTool.openProvider';

  return (
    <Modal.Footer
      divider
      confirm={translate(silentOn ? 'purchases.purchaseTool.silentlyOpen' : confirmText)}
      isLoading={isSupportLoading || isOpening || isPurchaseCalcLoading}
      onConfirm={async () => {
        let response;
        const validUntil = props.formRef.current.getSingleValue({
          id: 'validUntil',
          validate: true,
          showFeedback: true,
        });
        if (openMode === 'visual') {
          response = await openPurchase({
            openOptions: {
              silentUpdate: silentOn,
              purchaseId: props.purchaseId,
              ticketIds,
              openExpirationDate: validUntil
                ? dayjs(validUntil).format(DATE_TIME_SERVER_FORMAT)
                : undefined,
            },
            supportId: props.supportId,
          } as PurchaseClientOpenProviderRequest);
        } else {
          const dateSetOpen = props.formRef.current.getSingleValue({
            id: 'dateSetOpen',
            validate: true,
          });
          response = await openPurchase({
            openOptions: {
              silentUpdate: silentOn,
              purchaseId: props.purchaseId,
              ticketIds,
              openExpirationDate: validUntil
                ? dayjs(validUntil).format(DATE_TIME_SERVER_FORMAT)
                : undefined,
            },
            supportId: props.supportId,
            providerOptions: {
              type: openMode === 'provider-api' ? 'api' : 'external',
              openDate: openMode === 'provider-api' ? undefined : dateSetOpen,
            },
          });
        }

        handleResponse({
          response,
          onSuccess: () => {
            closeModal(ModalKey);
          },
        });
      }}
    >
      <HStack flex={isWeb ? 1 : undefined} space="1">
        <HStack space="1">
          <Toggle color="primary-mid" value={silentOn} onChange={(v) => setSilentOn(v)} />
          <Text.Body2Regular color="monochrome-extraDark">
            {translate('purchases.purchaseTool.purchaseCompare.silentUpdate')}
          </Text.Body2Regular>
          <View flex={1} />
        </HStack>
      </HStack>
    </Modal.Footer>
  );
};

export const useOpenPanel = () => {
  const { openModal, closeModal } = useModal();

  return {
    open: (props: OpenPanelProps) => {
      openModal(<OpenPanel {...props} />, {
        key: ModalKey,
        modalStyle: isWeb
          ? {
              minWidth: '',
              maxWidth: '',
            }
          : undefined,
        type: 'action-sheet-full',
      });
    },
    close: () => {
      closeModal(ModalKey);
    },
  };
};
