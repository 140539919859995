import { isNativeMobile } from '@droplet-tech-code/core-elements/module/utils/utils.helper';

import { PageHeader } from '~/components/Page/Page.Header';
import { translate } from '~/utils/translation.utils';
import { useCreateMetaSearchJob } from './modals/MetaSearchJob.create';
import { IconButton } from '@droplet-tech-code/core-elements/module/ui/Button';

export const MetaSearchJobListHeader = ({ refetch }: { refetch: () => void }) => {
  const { open: openCreateModal } = useCreateMetaSearchJob();

  return (
    <PageHeader
      title={translate('dataManagement.metaSearchJobs.searchJobListHeader')}
      withBack={isNativeMobile}
      iconRight={{
        color: 'monochrome',
        icon: 'plus',
        onPress: () => openCreateModal(),
      }}
      childRight={<IconButton color="monochrome" icon="refresh" onPress={refetch} />}
    />
  );
};
