import { IconButton } from '@droplet-tech-code/core-elements/module/ui/Button';
import { HStack } from '@droplet-tech-code/core-elements/module/ui/Stack';
import { MetaSearchJobClient } from '@naus-code/naus-admin-types';
import { PageHeader } from '~/components/Page/Page.Header';
import { systemApi } from '~/utils/system.api';
import { translate } from '~/utils/translation.utils';

export const MetaSearchJobItemHeader = ({
  metaSearchJob,
  onPress,
}: {
  metaSearchJob: MetaSearchJobClient;
  onPress: () => void;
}) => {
  const [cancelJob, { isLoading: isCancelling }] =
    systemApi.useCancelMetaSearchJobMutation();
  return (
    <PageHeader
      title={translate('dataManagement.metaSearchJobs.searchJobItemHeader')}
      childRight={
        <HStack>
          {!metaSearchJob.endTime && (
            <IconButton
              color="error"
              icon="bin"
              onPress={() => cancelJob(metaSearchJob.id)}
              isLoading={isCancelling}
            />
          )}
          <IconButton color="monochrome" icon="close" onPress={onPress} />
        </HStack>
      }
    />
  );
};
