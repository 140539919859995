import {
  Divider,
  DottedLine,
} from '@droplet-tech-code/core-elements/module/ui/Divider/Divider';
import { HStack, VStack } from '@droplet-tech-code/core-elements/module/ui/Stack';
import { Text } from '@droplet-tech-code/core-elements/module/ui/Text';
import { View } from '@droplet-tech-code/core-elements/module/ui/View';
import { notEmpty } from '@droplet-tech-code/core-elements/module/utils/utils.helper';
import { SelectTicketsTrip } from '@naus-code/naus-basket-manager';
import { RouteTrip } from '@naus-code/naus-client-types';

import {
  usePassengerEdit,
  useVehicleEdit,
} from '~/components/BasketManager/BasketManager.hooks';
import { BasketLegPricing } from '~/components/BasketManager/BasketManager.Pricing';
import { getPriceWithSymbol } from '~/screens/Support/Purchases/purchase.utils';
import { translate } from '~/utils/translation.utils';

import { useBasketManagerStore } from './BasketManager.Provider';
import {
  PassengerDiscount,
  SeatingTickets,
  VehicleDiscounts,
} from './components/BasketManager.TicketPricing.TicketOptions';
import {
  extractPassengerDetails,
  PassengerInfo,
  VehicleInfo,
} from './components/BasketManager.TicketsPricing.TicketInfo';

export const BasketTickets = ({ routeTrip }: { routeTrip: RouteTrip }) => {
  const useBmStore = useBasketManagerStore();
  const trips = useBmStore((s) => s.basket.pricing.trips);

  return (
    <VStack space="3">
      {trips.map((trip) => {
        return (
          <VStack key={trip.tripKey} space="3">
            <VStack space="3">
              <PassengerTickets trip={trip} routeTrip={routeTrip} />
              <Divider />
              <VehicleTickets trip={trip} routeTrip={routeTrip} />
            </VStack>
          </VStack>
        );
      })}
    </VStack>
  );
};

const PassengerTickets = ({
  routeTrip,
  trip,
}: {
  routeTrip: RouteTrip;
  trip: SelectTicketsTrip;
}) => {
  return trip.passengers.map((passenger, index) => {
    const ticket = passenger.selectedTicket;
    const loyalty = [passenger?.loyalty?.loyaltyName, passenger?.loyalty?.loyaltyNumber]
      .filter(notEmpty)
      .join(' ');

    const ccy = routeTrip.ccySymbol || 'EUR';

    const fullValue =
      (passenger.value ?? 0) +
      passenger.selectedExtras.reduce((memo, next) => memo + (next.value ?? 0), 0);

    const priceDiff = getPriceWithSymbol(
      passenger.value !== undefined ? fullValue : undefined,
      ccy,
    );

    const passengerDetails = usePassengerEdit(passenger.passengerId);

    const { name, nationality } = extractPassengerDetails(passengerDetails);

    return (
      <VStack space="3" key={passenger.passengerId}>
        {index !== 0 && <DottedLine />}
        <HStack space="1.5">
          <Text.Body2Regular>
            {`Passenger ${passenger.index + 1} - ${name ?? 'Guest'}`}
          </Text.Body2Regular>
          {nationality && <Text.Body2Regular>{`${nationality}`}</Text.Body2Regular>}
        </HStack>
        {loyalty.length ? (
          <Text.Small>{`${translate('basketManager.loyalty')}: ${loyalty}`}</Text.Small>
        ) : null}

        {passenger?.selectedDiscount ? (
          <Text.Small>
            {`${translate('basketManager.discount')}: ${passenger.selectedDiscount.code}`}
          </Text.Small>
        ) : null}
        <PassengerInfo passengerId={passenger.passengerId} />
        <HStack space="3" flex={1} pr="2.5">
          <View style={{ width: '50%' }}>
            {ticket ? (
              <SeatingTickets passenger={passenger} tripKey={trip.tripKey} ccy={ccy} />
            ) : null}
          </View>
          <View style={{ width: '50%' }}>
            <PassengerDiscount passengerTicket={passenger} trip={routeTrip} />
          </View>
        </HStack>
        <BasketLegPricing tripKey={trip.tripKey} modify value={priceDiff} />
      </VStack>
    );
  });
};

const VehicleTickets = ({
  routeTrip,
  trip,
}: {
  routeTrip: RouteTrip;
  trip: SelectTicketsTrip;
}) => {
  return trip.vehicles.map((vehicle, index) => {
    const loyalty = [vehicle?.loyalty?.loyaltyName, vehicle?.loyalty?.loyaltyNumber]
      .filter(notEmpty)
      .join(' ');

    const ccy = routeTrip.ccySymbol || 'EUR';
    const vehicleDetails = useVehicleEdit(vehicle?.vehicleId || '');

    if (!vehicle) {
      return null;
    }

    const fullValue =
      (vehicle.value ?? 0) +
      vehicle.selectedExtras.reduce((memo, next) => memo + (next.value ?? 0), 0);

    const priceDiff = getPriceWithSymbol(
      vehicle.value !== undefined ? fullValue : undefined,
      ccy,
    );
    return (
      <VStack space="3" key={vehicle.vehicleId}>
        {index !== 0 && <DottedLine />}
        <Text.Body2Regular>
          {`${translate('basketManager.vehicle')} ${vehicle.index + 1} - ${
            vehicleDetails?.plateNumber?.value ?? translate('basketManager.guest')
          }`}
        </Text.Body2Regular>
        {loyalty.length ? (
          <Text.Small>{`${translate('basketManager.loyalty')}: ${loyalty}`}</Text.Small>
        ) : null}

        {vehicle?.selectedDiscount ? (
          <Text.Small>
            {`${translate('basketManager.discount')}: ${vehicle.selectedDiscount.code}`}
          </Text.Small>
        ) : null}
        <VehicleInfo vehicleId={vehicle.vehicleId} />
        <VehicleDiscounts vehicleTicket={vehicle} trip={routeTrip} />
        <BasketLegPricing tripKey={trip.tripKey} value={priceDiff} modify />
      </VStack>
    );
  });
};
