import { ConfigForData } from '@droplet-tech-code/core-common-types';
import {
  SupportConfigCreateTemplateAnswerCategoryRequest,
  SupportConfigGetTemplateAnswerConfigResponse,
  SupportConfigUpdateTemplateAnswerRequest,
} from '@naus-code/naus-admin-types';
import { useCallback } from 'react';

import { useFormModal } from '~/components/Modals/FormModal';
import { supportConfigApi } from '~/screens/Configs/Support/supportConfig.api';
import { translate } from '~/utils/translation.utils';

export const useCreateTemplateAnswerCategoryModal = ({ check }: { check?: string }) => {
  const { open: openForm, close: closeForm } = useFormModal();

  const onConfirm = async ({
    action,
    value,
    check,
  }: {
    action: (
      req: SupportConfigCreateTemplateAnswerCategoryRequest,
    ) => SupportConfigGetTemplateAnswerConfigResponse;
    value: { templateCategory: string };
    check?: string;
  }) => {
    return await action({
      category: value.templateCategory,
      check: check || '',
    });
  };

  return {
    open: () => {
      const config: ConfigForData = [
        {
          items: [
            {
              type: 'text',
              label: translate('supportConfig.name'),
              id: 'templateCategory',
            },
          ],
        },
      ];
      openForm({
        modalTitle: translate('supportConfig.createTemplateCategory'),
        useFormHookHandler: () => {
          return { data: { config, check }, isLoading: false };
        },
        onConfirm,
        actionHook: supportConfigApi.useCreateTemplateCategoryMutation,
      });
    },
    close: () => {
      closeForm();
    },
  };
};

export const useUpdateTemplateAnswerModal = ({
  templateAnswerId,
}: {
  templateAnswerId: string;
}) => {
  const { open: openForm, close: closeForm } = useFormModal();

  const useFormHookHandler = useCallback(() => {
    const { data, isLoading } =
      supportConfigApi.useGetUpdateAnswerTemplateFormConfigQuery(templateAnswerId);
    return { data, isLoading };
  }, [templateAnswerId]);

  const onConfirm = async ({
    action,
    value,
    check,
  }: {
    action: (
      req: SupportConfigUpdateTemplateAnswerRequest,
    ) => SupportConfigGetTemplateAnswerConfigResponse;
    value: SupportConfigUpdateTemplateAnswerRequest['templateAnswer'];
    check?: string;
  }) => {
    return await action({
      templateAnswer: value,
      check: check || '',
    });
  };

  return {
    open: () => {
      openForm({
        modalTitle: translate('supportConfig.updateTemplateAnswer'),
        useFormHookHandler,
        onConfirm,
        actionHook: supportConfigApi.useUpdateTemplateAnswerMutation,
      });
    },
    close: () => {
      closeForm();
    },
  };
};
