// useStore.js
import { create } from 'zustand';

export const useRetrieveFromProviderStore = create<{
  fetchDatetime: string | undefined;
  setFetchDatetime: (dateTime: string | undefined) => void;
}>((set) => ({
  // Initial state
  fetchDatetime: undefined,

  // Actions to modify state
  setFetchDatetime: (fetchDatetime) => set({ fetchDatetime }),
}));
