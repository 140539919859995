import { HStack } from '@droplet-tech-code/core-elements/module/ui/Stack';
import { showToast } from '@droplet-tech-code/core-elements/module/ui/Toast';
import { ClientStatePassengerTripDetail } from '@naus-code/naus-basket-manager';

import { Field } from '~/components/Card/Field';
import { translate } from '~/utils/translation.utils';

import { usePassengerEdit, useVehicleEdit } from '../BasketManager.hooks';

export const PassengerInfo = ({ passengerId }: { passengerId: string }) => {
  const passenger = usePassengerEdit(passengerId);

  if (!passenger) {
    showToast(`${translate('basketManager.noPassenger')} ${passengerId}`, {
      type: 'error',
    });
    return null;
  }

  return (
    <HStack space="3">
      <Field
        label={translate('basketManager.sex')}
        value={(passenger.sex.value as string | undefined) || 'NA'}
      />

      <Field
        label={translate('basketManager.ageGroup')}
        value={(passenger.birthDate?.value as string | undefined) || 'NA'}
      />
    </HStack>
  );
};

export const VehicleInfo = ({ vehicleId }: { vehicleId: string }) => {
  const vehicle = useVehicleEdit(vehicleId);

  if (!vehicle) {
    // console.error('Error: No vehicle found, ', vehicleId);
    showToast(`${translate('basketManager.noVehicle')} ${vehicleId}`, {
      type: 'error',
    });
    return null;
  }

  return (
    <Field
      label={translate('basketManager.lengthOfVehicle')}
      value={(vehicle.length?.value as string | undefined) || 'NA'}
    />
  );
};

interface PassengerDetails {
  name: string | undefined;
  birthDate: string | undefined;
  sex: string | undefined;
  nationality: string | undefined;
}

export function extractPassengerDetails(
  passengerDetails: ClientStatePassengerTripDetail | undefined,
) {
  const detailsExtracted = {
    name: undefined,
    birthDate: undefined,
    sex: undefined,
    nationality: undefined,
  } as PassengerDetails;
  if (!passengerDetails) {
    return detailsExtracted;
  }
  if (passengerDetails.name.value || passengerDetails.lastName.value) {
    detailsExtracted['name'] = `${passengerDetails.name.value || ''} ${
      passengerDetails.lastName.value || ''
    }`;
  }

  detailsExtracted['birthDate'] = passengerDetails.birthDate?.value as string | undefined;
  detailsExtracted['sex'] = passengerDetails.sex.value as string | undefined;
  detailsExtracted['nationality'] = passengerDetails.nationality?.value as
    | string
    | undefined;

  return detailsExtracted;
}
