import { ConfigForData } from '@droplet-tech-code/core-common-types';
import {
  FormBuilder,
  FormHandler,
} from '@droplet-tech-code/core-elements/module/ui/Inputs/Form';
import { TicketClient } from '@naus-code/naus-admin-types';
import {
  TicketPassengerWithHistory,
  TicketPetWithHistory,
  TicketVehicleWithHistory,
} from '@naus-code/naus-client-types';
import { ReplaceFirebaseTimestamp } from '@naus-code/naus-server-utils-common-types';
import { useEffect, useRef } from 'react';

import { useManualStore } from '../ManualProvider/Modify.ManualTrip.Provider';

function generateModifyPassengerTicketHeaderManualForm(
  ticket: ReplaceFirebaseTimestamp<TicketPassengerWithHistory>,
): ConfigForData {
  const { p_firstName, p_lastName, p_sex, p_birthDate } = ticket;
  return [
    {
      items: [
        {
          id: 'p_firstName',
          type: 'text',
          label: 'Name',
          initialValue: p_firstName,
        },
        {
          id: 'p_lastName',
          type: 'text',
          label: 'Surname',
          initialValue: p_lastName,
        },
        {
          id: 'p_sex',
          type: 'text',
          label: 'Sex',
          initialValue: p_sex,
        },
        {
          id: 'p_birthDate',
          type: 'text',
          label: 'Birth date',
          initialValue: p_birthDate,
        },
      ],
    },
  ];
}

function generateModifyPassengerVehicleHeaderManualForm(
  ticket: ReplaceFirebaseTimestamp<TicketVehicleWithHistory>,
): ConfigForData {
  const { p_plate } = ticket;
  return [
    {
      items: [
        {
          id: 'p_plate',
          type: 'text',
          label: 'Plate number',
          initialValue: p_plate,
        },
      ],
    },
  ];
}

function generateModifyPassengerPetHeaderManualForm(
  ticket: ReplaceFirebaseTimestamp<TicketPetWithHistory>,
): ConfigForData {
  const { name } = ticket;
  return [
    {
      items: [
        {
          id: 'name',
          type: 'text',
          label: 'Pet',
          initialValue: name,
        },
      ],
    },
  ];
}
export const ModifyTicketHeaderManualForm = ({ ticket }: { ticket: TicketClient }) => {
  const config = getTicketHeaderConfig(ticket);

  const formRef = useRef(new FormHandler());

  const manualModStore = useManualStore();
  const addForm = manualModStore((s) => s.addForm);

  useEffect(() => {
    addForm({
      form: formRef.current,
      purchaseId: ticket._purchaseId,
      ticketId: ticket._id,
    });
  }, []);

  if (!config) {
    return null;
  }
  return <FormBuilder data={config} form={formRef.current} />;
};

function getTicketHeaderConfig(ticket: TicketClient) {
  switch (ticket.ticketType) {
    case 'Passenger':
      return generateModifyPassengerTicketHeaderManualForm(ticket);
    case 'Vehicle':
      return generateModifyPassengerVehicleHeaderManualForm(ticket);
    case 'Pet':
      return generateModifyPassengerPetHeaderManualForm(ticket);
    default:
      break;
  }
}
