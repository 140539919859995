import { DelayRender } from '@droplet-tech-code/core-elements/module/ui/Delay';
import { SelectInputBase } from '@droplet-tech-code/core-elements/module/ui/Inputs/SelectInput/SelectInput';
import { dayjs } from '@droplet-tech-code/core-elements/module/utils/utils.date';
import { GetBookingResponse } from '@naus-code/naus-admin-types';
import { useEffect } from 'react';

import { bookingApi } from '~/screens/Support/Bookings/Bookings.api';
import { getPriceWithSymbol } from '~/screens/Support/Purchases/purchase.utils';
import { purchaseActionManager } from '~/screens/Support/Purchases/PurchaseActions/ActionPanel/Common/ActionPanel.utils';
import { ModifyPanelProps } from '~/screens/Support/Purchases/PurchaseActions/ActionPanel/Modify/Modify.utils';

import { ModificationType } from '../Modify/Modify.Panel';

export const PaymentSessionSelector = (
  props: ModifyPanelProps & {
    option: ModificationType;
    setOption: (o: ModificationType) => void;
  },
) => {
  const { data: bookingRes, isLoading: isLoadingBookingData } =
    bookingApi.useGetBookingQuery(props.bookingId ?? '');

  return (
    <DelayRender isLoading={isLoadingBookingData}>
      {bookingRes ? (
        <SessionIdSelector option={props.option} bookingRes={bookingRes} />
      ) : null}
    </DelayRender>
  );
};

const SessionIdSelector = ({
  bookingRes,
  option,
}: {
  bookingRes: GetBookingResponse;
  option: ModificationType;
}) => {
  const [paymentSessionIds, updatePaymentSessionIds] =
    purchaseActionManager.state.useGlobalState('paymentSessionIds');

  useEffect(() => {
    const paymentSessions = [...bookingRes.booking.paymentSessions].reverse();
    const lastItem = paymentSessions.find(
      (paymentSession) => paymentSession.type !== 'booking' || option !== 'Manual',
    );

    if (lastItem) {
      updatePaymentSessionIds([lastItem.id]);
    } else {
      updatePaymentSessionIds([]);
    }
  }, [option]);

  return (
    <SelectInputBase
      noFeedback
      feedback=""
      setValues={(newValues) => {
        updatePaymentSessionIds(newValues ?? []);
      }}
      type="multi-select"
      options={bookingRes.booking.paymentSessions
        .filter(
          (paymentSession) => paymentSession.type !== 'booking' || option !== 'Manual',
        )
        .map((session) => {
          return {
            label: `${session.type.toLocaleUpperCase()}, ${getPriceWithSymbol(
              session.paymentSummary.total +
                (session.paymentSummary.voucher?.redeemValue ?? 0),
              session.paymentSummary.currency,
            )} on ${dayjs(session.lastUpdated).format('YY-MMM-DD HH:mm')}`,
            value: session.id,
          };
        })}
      values={paymentSessionIds ?? []}
    />
  );
};
