import { ColorKeys } from '@droplet-tech-code/core-elements/module/theme';
import { ButtonInteractionState } from '@droplet-tech-code/core-elements/module/ui/Button';
import { DottedLine } from '@droplet-tech-code/core-elements/module/ui/Divider/Divider';
import { Icon } from '@droplet-tech-code/core-elements/module/ui/Icon';
import { HStack, VStack } from '@droplet-tech-code/core-elements/module/ui/Stack';
import { Text } from '@droplet-tech-code/core-elements/module/ui/Text';
import { View } from '@droplet-tech-code/core-elements/module/ui/View';
import { MetaSearchJobClient } from '@naus-code/naus-admin-types';
import { Pressable } from 'react-native';
import { LiveDateText } from '~/components/DateText';
import { AppText } from '~/components/Text';
import { getAppTheme } from '~/utils/theme.utils';
import { translate } from '~/utils/translation.utils';

export const MetaSearchJobListItemComponent = ({
  metaSearchJob,
  selected,
  onPress,
}: {
  metaSearchJob: MetaSearchJobClient;
  selected: boolean;
  onPress: () => void;
}) => {
  const { shading } = getAppTheme();
  if (!metaSearchJob) {
    return null;
  }

  return (
    <Pressable onPress={onPress}>
      {({ hovered }: ButtonInteractionState) => {
        return (
          <View
            br={10}
            bg={hovered ? 'primary-base' : 'white'}
            bc={selected ? 'primary-mid' : hovered ? 'primary-extraLight' : 'white'}
            b={2}
            style={[{ overflow: 'hidden' }, shading.light]}
          >
            <MetaSearchJobListItemInternalComponent metaSearchJob={metaSearchJob} />
          </View>
        );
      }}
    </Pressable>
  );
};

export const MetaSearchJobListItemInternalComponent = ({
  metaSearchJob,
}: {
  metaSearchJob: MetaSearchJobClient;
}) => {
  const { startDate, endDate, companyIds, routes, cleanFetch, cleanCmsFetch } =
    metaSearchJob.details;
  const { status, outcomes, startTime, endTime, percentage } = metaSearchJob;
  const requiresAttention = !!outcomes.find((outcome) => outcome.error);
  const cleanFetchText = getCleanFetchText(cleanFetch, cleanCmsFetch);
  const routesProcessed = routes?.map(
    (route) => `${route.origin} - ${route.destination}`,
  );
  return (
    <VStack flex={1}>
      <HStack flex={1}>
        <View
          bg={getStatusColor(status, requiresAttention)}
          style={{ width: 15, height: '100%' }}
        />
        <VStack px="2.5" py="1.5" space="1" flex={1}>
          <HStack flex={1} style={{ flexWrap: 'wrap' }}>
            <VStack flex={1}>
              <HStack>
                <Text.Body2Medium color="monochrome-dark">
                  {endTime
                    ? translate('dataManagement.metaSearchJobs.endTime')
                    : translate('dataManagement.metaSearchJobs.startTime')}{' '}
                </Text.Body2Medium>
                <LiveDateText
                  dateTime={endTime ?? startTime}
                  formatOption="lifetimeDependent"
                  type="body2Medium"
                  color="monochrome-extraDark"
                />
              </HStack>
            </VStack>
          </HStack>
        </VStack>
        <HStack
          justify="center"
          bg={cleanFetch ? 'primary-extraLight' : 'secondary-extraLight'}
          align="center"
          p="2.5"
        >
          <VStack justify="center" align="center">
            <HStack space="1" p="2.5">
              <AppText.MiscTag color="black">{cleanFetchText}</AppText.MiscTag>
              {requiresAttention && <Icon icon="warning" color="error" size="large" />}
            </HStack>
          </VStack>
        </HStack>
      </HStack>
      <VStack p="2.5" space="2.5">
        <HStack space="2.5">
          <Text.Small color="black">
            {translate('dataManagement.metaSearchJobs.dateRange')}
          </Text.Small>
          <Text.Small align="right" color="monochrome-dark">
            {`${startDate} - ${endDate}`}
          </Text.Small>
        </HStack>
        {!!companyIds && (
          <HStack space="2.5">
            <Text.Small color="black">
              {translate('dataManagement.metaSearchJobs.companies')}
            </Text.Small>
            <Text.Small
              numberOfLines={1}
              color="monochrome-extraDark"
            >{`${companyIds?.join(', ')}`}</Text.Small>
          </HStack>
        )}
        {!!routesProcessed?.length && (
          <HStack space="2.5">
            <Text.Small color="black">
              {translate('dataManagement.metaSearchJobs.routes')}
            </Text.Small>
            <Text.Small
              numberOfLines={1}
              color="monochrome-extraDark"
            >{`${routesProcessed?.join(', ')}`}</Text.Small>
          </HStack>
        )}
        <ProgressBar percentage={percentage} />
      </VStack>
    </VStack>
  );
};

function getStatusColor(
  status: MetaSearchJobClient['status'],
  error: boolean,
): ColorKeys {
  if (error) {
    return 'error-light';
  }
  switch (status) {
    case 'completed':
      return 'primary-light';
    case 'in-progress':
      return 'success-light';
    case 'shutting-down':
      return 'monochrome-light';
  }
}

const ProgressBar = ({ percentage }: { percentage: number }) => {
  return (
    <VStack space="1.5">
      <DottedLine />
      <View
        bg={'primary'}
        br={15}
        b={0}
        style={{ height: 6, width: `${percentage * 100}%` }}
      />
    </VStack>
  );
};

function getCleanFetchText(cleanFetch?: boolean, cleanCmsFetch?: boolean) {
  if (cleanCmsFetch) {
    return 'clean cms fetch';
  }
  if (cleanFetch) {
    return 'clean fetch';
  }
  return 'not clean fetch';
}
