import { ConfigForData, SelectedValueItem } from '@droplet-tech-code/core-common-types';
import { TicketPetWithHistory } from '@naus-code/naus-client-types';
import { ReplaceFirebaseTimestamp } from '@naus-code/naus-server-utils-common-types';

export function generateModifyPetTicketInfoManualForm(
  ticket: ReplaceFirebaseTimestamp<TicketPetWithHistory>,
): ConfigForData {
  const { petCategory, companyTicketNumber, parentTicketNumber } = ticket;

  const petCatigoryList: SelectedValueItem[] = [
    { value: 'dog' },
    { value: 'cat' },
    { value: 'guide-dog' },
  ];

  return [
    {
      items: [
        {
          id: 'petCategory',
          type: 'single-select',
          label: 'Pet',
          list: petCatigoryList,
          optional: !petCategory,
          initialValue: petCategory,
        },
        {
          id: 'parentTicketNumber',
          type: 'text',
          label: 'Parent Ticket Number',
          optional: !parentTicketNumber,
          initialValue: parentTicketNumber || '',
        },
        {
          id: 'companyTicketNumber',
          type: 'text',
          label: 'Company Ticket Number',
          optional: !companyTicketNumber,
          initialValue: companyTicketNumber,
        },
      ],
    },
  ];
}
