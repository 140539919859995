import {
  SystemClientCreateSearchJobRequest,
  SystemClientGetSearchJobResponse,
} from '@naus-code/naus-admin-types';
import { useCallback } from 'react';

import { useFormModal } from '~/components/Modals/FormModal';
import { systemApi } from '~/utils/system.api';
import { translate } from '~/utils/translation.utils';

export const useCreateMetaSearchJob = () => {
  const { open: openForm, close: closeForm } = useFormModal();

  const useFormHookHandler = useCallback(() => {
    const { data: config, isLoading } = systemApi.useCreateMetaSearchJobFormConfigQuery();
    return { data: { config }, isLoading };
  }, []);

  const onConfirm = async ({
    action,
    value,
  }: {
    action: (req: SystemClientCreateSearchJobRequest) => SystemClientGetSearchJobResponse;
    value: SystemClientCreateSearchJobRequest['job'];
  }) => {
    return await action({ job: value });
  };

  return {
    open: () => {
      openForm<SystemClientCreateSearchJobRequest, SystemClientGetSearchJobResponse>({
        modalTitle: translate('dataManagement.metaSearchJobs.createSearchMetaJob'),
        useFormHookHandler,
        onConfirm,
        actionHook: systemApi.useCreateMetaSearchJobMutation,
        keepOpenOnError: true,
      });
    },
    close: () => {
      closeForm();
    },
  };
};
