import { BasketManager, ClientBasketState } from '@naus-code/naus-basket-manager';
import {
  DATE_FORMAT_CLIENT,
  DATE_FORMAT_SERVER,
} from '@naus-code/naus-client-common/src/Forms/Forms.date';
import {
  createContext,
  MutableRefObject,
  PropsWithChildren,
  useContext,
  useState,
} from 'react';
import { create, StoreApi, UseBoundStore } from 'zustand';
import { immer } from 'zustand/middleware/immer';

type BmStoreImmer = UseBoundStore<
  StoreApi<{
    basket: ClientBasketState;
    setBasket: () => void;
  }>
>;

const BasketManagerContext = createContext<{
  basketManager: BasketManager;
  basketManagerStore: BmStoreImmer;
}>({} as any);
const storeRef = { current: null } as MutableRefObject<BmStoreImmer | null>;

export const BasketManagerProvider = (props: PropsWithChildren) => {
  const [bmState] = useState(() => {
    const basketManager = new BasketManager({
      dispatchBasketAction: () => {
        if (!storeRef.current) {
          return;
        }

        storeRef.current.getState().setBasket();
      },
      config: {
        DATE_FORMAT_CLIENT: DATE_FORMAT_CLIENT,
        DATE_FORMAT_SERVER: DATE_FORMAT_SERVER,
      },
      devOptions: {
        disableAutoPriceFetch: undefined,
        disablePriceFetch: undefined,
        loadTestTranslations: true,
        mockPriceFetch: undefined,
      },
      dispatchManager: () => {},
    });

    const basketManagerStore = create<{
      basket: ClientBasketState;
      setBasket: () => void;
    }>()(
      immer((set) => ({
        basket: basketManager.getInitialState(),
        setBasket: () => {
          set((state) => {
            basketManager.setBasketStateListener(state.basket);
          });
        },
      })),
    );

    storeRef.current = basketManagerStore;
    return {
      basketManager,
      basketManagerStore,
    };
  });
  return (
    <BasketManagerContext.Provider value={bmState}>
      {props.children}
    </BasketManagerContext.Provider>
  );
};

export const useBasketManager = () => {
  return useContext(BasketManagerContext).basketManager;
};

export const useBasketManagerStore = () => {
  return useContext(BasketManagerContext).basketManagerStore;
};
