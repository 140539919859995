import { TicketsPricingPassengerDiscountModal } from '../modals/TicketsPricingPassengerModal/TicketsPricingPassengerModal.Discount';
import { TicketsPricingPassengerExtraInfoModal } from '../modals/TicketsPricingPassengerModal/TicketsPricingPassengerModal.ExtraInfoRequired';
import { TicketsPricingPassengerLoyaltyModal } from '../modals/TicketsPricingPassengerModal/TicketsPricingPassengerModal.Loyalty';
import { TicketsPricingPassengerItem } from './TicketsPricing.TripItem';

export const TicketsPricingPassengerForm = (props: TicketsPricingPassengerItem) => {
  return (
    <>
      <TicketsPricingPassengerExtraInfoModal {...props} />
      <TicketsPricingPassengerLoyaltyModal {...props} />
      <TicketsPricingPassengerDiscountModal {...props} />
    </>
  );
};
