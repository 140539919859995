import {
  FormBuilder,
  FormHandler,
} from '@droplet-tech-code/core-elements/module/ui/Inputs/Form';
import { Modal } from '@droplet-tech-code/core-elements/module/ui/Modal';
import { VStack } from '@droplet-tech-code/core-elements/module/ui/Stack';
import { Text } from '@droplet-tech-code/core-elements/module/ui/Text';
import { useRef } from 'react';

import { StateModal } from '~/components/Modals/Modal.utils';
import { translate } from '~/utils/translation.utils';

import { useTicketsPricingPassengerDiscountStore } from '../../ticketPricing/TicketPricing.Provider';
import { TicketsPricingPassengerItem } from '../../ticketPricing/TicketsPricing.TripItem';

export const TicketsPricingPassengerDiscountModal = (
  props: TicketsPricingPassengerItem,
) => {
  const modalState = useTicketsPricingPassengerDiscountStore()((s) => s);
  const form = useRef(new FormHandler());
  const passengerRequiredData = modalState.requestData;
  if (!passengerRequiredData || passengerRequiredData.itemId !== props.passengerId) {
    return null;
  }

  const applyDiscountNumber = (discountCode: string) => {
    if (discountCode && modalState.onDone) {
      modalState.onDone({
        responseData: discountCode,
        status: 'success',
      });
    }
  };

  return (
    <StateModal
      isOpen={modalState.isOpen}
      closeModal={modalState.close}
      style={{
        height: 'auto',
        width: 370,
      }}
    >
      <Modal.Body>
        <VStack>
          <Text.Body1Medium color="black">
            {translate('basketManager.ticketSelection.discount.discountCode')}
          </Text.Body1Medium>
          <FormBuilder
            data={[
              {
                items: [
                  {
                    type: 'text',
                    label: translate(
                      'basketManager.ticketSelection.discount.enterYourDiscount',
                    ),
                    id: 'discountCode',
                  },
                ],
              },
            ]}
            form={form.current}
          />
        </VStack>
      </Modal.Body>
      <Modal.Footer
        divider
        onConfirm={async () => {
          const { valid, value } = form.current.getValue<{ discountCode: string }>();
          if (valid) {
            applyDiscountNumber(value.discountCode);
          }
        }}
        onCancel={() => {
          modalState.close();
        }}
      />
    </StateModal>
  );
};
