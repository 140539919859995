import { FormHandler } from '@droplet-tech-code/core-elements/module/ui/Inputs/Form';
import { TicketClient } from '@naus-code/naus-admin-types';
import {
  TicketPassengerWithHistory,
  TicketPetCategory,
  TicketPetWithHistory,
  TicketVehicleCategory,
  TicketVehicleEngineCategory,
  TicketVehicleWithHistory,
} from '@naus-code/naus-client-types';
import { ReplaceFirebaseTimestamp } from '@naus-code/naus-server-utils-common-types';

import { onSubmitValueWithUndefined } from '~/components/SupportRequest/utils/utils';

export function getPassengerTicketsToUpdate(
  ticket: TicketClient,
  ticketForms: FormHandler[],
) {
  let validFlag = true;
  let ticketFormValues = {} as PassengerTicketFormData;
  for (const ticketForm of ticketForms) {
    const { value, valid } = ticketForm.getValue<PassengerTicketFormData>();
    if (valid) {
      ticketFormValues = { ...ticketFormValues, ...value };
    } else {
      validFlag = false;
    }
  }
  const baseTicketInfo: BaseTicketFormData = getBaseTicketInfo(ticketFormValues);
  if (validFlag) {
    return {
      ...ticket,
      ...baseTicketInfo,
      companyTicketNumber: ticketFormValues.companyTicketNumber,
      companyTicketType: ticketFormValues.companyTicketType,
      p_firstName: ticketFormValues.p_firstName.toUpperCase(),
      p_lastName: ticketFormValues.p_lastName.toUpperCase(),
      p_sex: ticketFormValues.p_sex.toUpperCase(),
      seatNumber: ticketFormValues.seatNumber,
      p_nationality: ticketFormValues.p_nationality,
      residentId: ticketFormValues.residentId,
      p_birthPlace: ticketFormValues.p_birthPlace,
      p_birthDate: ticketFormValues.p_birthDate,
      p_documentType: ticketFormValues.p_documentType,
      p_documentNumber: ticketFormValues.p_documentNumber,
      p_documentExpirationDate: ticketFormValues.p_documentExpirationDate,
      cabinDetails: {
        id: ticketFormValues.cabinId,
        capacity: ticketFormValues.cabinCapacity,
        wholeUse: ticketFormValues.cabinWholeUse,
        count: ticketFormValues.cabinCount,
      },
    } as ReplaceFirebaseTimestamp<TicketPassengerWithHistory>;
  }
}

export function getVehicleTicketsToUpdate(
  ticket: TicketClient,
  ticketForms: FormHandler[],
) {
  let validFlag = true;
  let ticketFormValues = {} as VehicleTicketFormData;
  for (const ticketForm of ticketForms) {
    const { value, valid } = ticketForm.getValue<VehicleTicketFormData>();
    if (valid) {
      ticketFormValues = { ...ticketFormValues, ...value };
    } else {
      validFlag = false;
    }
  }
  const baseTicketInfo: BaseTicketFormData = getBaseTicketInfo(ticketFormValues);
  if (validFlag) {
    return {
      ...ticket,
      ...baseTicketInfo,
      parentTicketNumber: ticketFormValues.parentTicketNumber,
      companyTicketNumber: ticketFormValues.companyTicketNumber,
      category: ticketFormValues.category,
      p_plate: ticketFormValues.p_plate,
      engineCategory: ticketFormValues.engineCategory,
      loyaltyNumber: ticketFormValues.loyaltyNumber,
      p_nationality: ticketFormValues.p_nationality,
      length: ticketFormValues.length,
      height: ticketFormValues.height,
      roof: {
        length: ticketFormValues.roofHeight,
        height: ticketFormValues.roofLength,
      },
      trailer: {
        length: ticketFormValues.trailerHeight,
        height: ticketFormValues.trailerLength,
      },
      sideCar: { enabled: ticketFormValues.sideCar },
    } as ReplaceFirebaseTimestamp<TicketVehicleWithHistory>;
  }
}

export function getPetTicketsToUpdate(ticket: TicketClient, ticketForms: FormHandler[]) {
  let validFlag = true;
  let ticketFormValues = {} as PetTicketFormData;
  for (const ticketForm of ticketForms) {
    const { value, valid } = ticketForm.getValue<PetTicketFormData>();
    if (valid) {
      ticketFormValues = { ...ticketFormValues, ...value };
    } else {
      validFlag = false;
    }
  }
  const baseTicketInfo: BaseTicketFormData = getBaseTicketInfo(ticketFormValues);
  if (validFlag) {
    return {
      ...ticket,
      ...baseTicketInfo,
      petCategory: ticketFormValues.petCategory,
      companyTicketNumber: ticketFormValues.companyTicketNumber,
      parentTicketNumber: ticketFormValues.parentTicketNumber,
      parentTicketId: ticketFormValues.parentTicketId,
      name: ticketFormValues.name.toUpperCase(),
    } as ReplaceFirebaseTimestamp<TicketPetWithHistory>;
  }
}

export function getBaseTicketInfo(ticketFormValues: BaseTicketFormData) {
  return {
    value: onSubmitValueWithUndefined(ticketFormValues.value),
    valueNet: onSubmitValueWithUndefined(ticketFormValues.valueNet),
    valueVat: onSubmitValueWithUndefined(ticketFormValues.valueVat),
    valueTax: onSubmitValueWithUndefined(ticketFormValues.valueTax),
    valueCcy: onSubmitValueWithUndefined(ticketFormValues.valueCcy),
    ticketName: ticketFormValues.ticketName,
    providerTicketCode: ticketFormValues.providerTicketCode,
    companyTicketCode: ticketFormValues.companyTicketCode,
    companyTicketLetter: ticketFormValues.companyTicketLetter,
    companyTicketName: ticketFormValues.companyTicketName,
    companyDiscountCode: ticketFormValues.companyDiscountCode,
  };
}

export interface BaseTicketFormData {
  value?: number;
  valueNet?: number;
  valueVat?: number;
  valueTax?: number;
  valueCcy?: number;
  ticketName?: string;
  providerTicketCode: string;
  companyTicketCode: string;
  companyTicketLetter?: string;
  companyTicketName: string;
  companyDiscountCode?: string;
}

export interface PassengerTicketFormData extends BaseTicketFormData {
  companyTicketNumber: string;
  companyTicketType: string | undefined;
  p_firstName: string;
  p_lastName: string;
  p_sex: 'M' | 'F';
  seatNumber?: string;
  p_nationality?: string;
  residentId?: string;
  p_birthPlace?: string;
  p_birthDate?: string;
  p_documentType?: string;
  p_documentNumber?: string;
  p_documentExpirationDate?: string;
  cabinId: string;
  cabinCapacity: number;
  cabinWholeUse?: boolean;
  cabinCount: number;
}

export interface VehicleTicketFormData extends BaseTicketFormData {
  parentTicketNumber?: string | null;
  companyTicketNumber: string;
  category: TicketVehicleCategory;
  p_plate: string;
  engineCategory?: TicketVehicleEngineCategory;
  loyaltyNumber?: string;
  p_nationality?: string;
  length?: number;
  height?: number;
  roofLength?: number;
  roofHeight?: number;
  trailerLength?: number;
  trailerHeight?: number;
  sideCar?: boolean;
}

export interface PetTicketFormData extends BaseTicketFormData {
  petCategory: TicketPetCategory;
  companyTicketNumber?: string;
  parentTicketNumber?: string | null;
  parentTicketId?: string | null;
  name: string;
}
