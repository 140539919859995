import { useBasketManagerStore } from './BasketManager.Provider';

export const useGetSelectedTrip = (tripKey: string) => {
  const useBmStore = useBasketManagerStore();
  return useBmStore((s) => s.basket.pricing.trips.find((t) => t.tripKey === tripKey));
};

export const usePassengerEdit = (passengerId: string) => {
  const useBmStore = useBasketManagerStore();

  return useBmStore((s) =>
    s.basket.details.passengers.find((p) => p.passengerId === passengerId),
  );
};

export const useVehicleEdit = (vehicleId: string) => {
  const useBmStore = useBasketManagerStore();

  return useBmStore((s) =>
    s.basket.details.vehicles.find((v) => v.vehicleId === vehicleId),
  );
};

export const useGetSelectedPassenger = (tripKey: string, passengerId: string) => {
  const trip = useGetSelectedTrip(tripKey);
  return trip?.passengers.find((p) => p.passengerId === passengerId);
};
