import {
  CompanyConfigClientCreateFerryCompanyDiscountRequest,
  CompanyConfigClientCreateFerryCompanyExtraTicketRequest,
  CompanyConfigClientCreateFerryCompanyPassengerTicketRequest,
  CompanyConfigClientCreateFerryCompanyVehicleTicketRequest,
  CompanyConfigClientGetFerryConfigResponse,
} from '@naus-code/naus-admin-types';
import { useCallback } from 'react';

import { useFormModal } from '~/components/Modals/FormModal';
import { companyConfigApi } from '~/screens/Configs/Company/CompanyConfigs.api';
import { translate } from '~/utils/translation.utils';

export const useAddFerryCompanyDiscountModal = (companyId: string) => {
  const { open: openForm, close: closeForm } = useFormModal();

  const useFormHookHandler = useCallback(() => {
    const { data, isLoading } =
      companyConfigApi.useAddFerryCompanyDiscountConfigFormConfigQuery({
        companyId,
      });
    return { data, isLoading };
  }, [companyId]);

  const onConfirm = async ({
    action,
    value,
    check,
  }: {
    action: (
      req: CompanyConfigClientCreateFerryCompanyDiscountRequest,
    ) => CompanyConfigClientGetFerryConfigResponse;
    value: CompanyConfigClientCreateFerryCompanyDiscountRequest['config'];
    check?: string;
  }) => {
    return await action({
      config: value,
      companyId,
      check: check || '',
    });
  };

  return {
    open: () => {
      openForm({
        modalTitle: translate('companyConfig.addFerryCompanyDiscount'),
        useFormHookHandler,
        onConfirm,
        actionHook: companyConfigApi.useAddFerryCompanyExtraConfigMutation,
      });
    },
    close: () => {
      closeForm();
    },
  };
};

export const useAddFerryPassengerTicketModal = (companyId: string) => {
  const { open: openForm, close: closeForm } = useFormModal();

  const useFormHookHandler = useCallback(() => {
    const { data, isLoading } =
      companyConfigApi.useAddFerryCompanyPassengerConfigFormConfigQuery();
    return { data, isLoading };
  }, []);

  const onConfirm = async ({
    action,
    value,
    check,
  }: {
    action: (
      req: CompanyConfigClientCreateFerryCompanyPassengerTicketRequest,
    ) => CompanyConfigClientGetFerryConfigResponse;
    value: CompanyConfigClientCreateFerryCompanyPassengerTicketRequest['config'];
    check?: string;
  }) => {
    return await action({
      config: value,
      companyId,
      check: check || '',
    });
  };

  return {
    open: () => {
      openForm({
        modalTitle: translate('companyConfig.addPassengerTicket'),
        useFormHookHandler,
        onConfirm,
        actionHook: companyConfigApi.useAddFerryCompanyPassengerConfigMutation,
      });
    },
    close: () => {
      closeForm();
    },
  };
};

export const useAddFerryVehicleTicketModal = (companyId: string) => {
  const { open: openForm, close: closeForm } = useFormModal();

  const useFormHookHandler = useCallback(() => {
    const { data, isLoading } =
      companyConfigApi.useAddFerryCompanyVehicleConfigFormConfigQuery();
    return { data, isLoading };
  }, []);

  const onConfirm = async ({
    action,
    value,
    check,
  }: {
    action: (
      req: CompanyConfigClientCreateFerryCompanyVehicleTicketRequest,
    ) => CompanyConfigClientGetFerryConfigResponse;
    value: CompanyConfigClientCreateFerryCompanyVehicleTicketRequest['config'];
    check?: string;
  }) => {
    return await action({
      config: value,
      companyId,
      check: check || '',
    });
  };

  return {
    open: () => {
      openForm({
        modalTitle: translate('companyConfig.addVehicleTicket'),
        useFormHookHandler,
        onConfirm,
        actionHook: companyConfigApi.useAddFerryCompanyVehicleConfigMutation,
      });
    },
    close: () => {
      closeForm();
    },
  };
};

export const useAddFerryExtraTicketModal = (companyId: string) => {
  const { open: openForm, close: closeForm } = useFormModal();

  const useFormHookHandler = useCallback(() => {
    const { data, isLoading } =
      companyConfigApi.useAddFerryCompanyExtraConfigFormConfigQuery();
    return { data, isLoading };
  }, []);

  const onConfirm = async ({
    action,
    value,
    check,
  }: {
    action: (
      req: CompanyConfigClientCreateFerryCompanyExtraTicketRequest,
    ) => CompanyConfigClientGetFerryConfigResponse;
    value: CompanyConfigClientCreateFerryCompanyExtraTicketRequest['config'];
    check?: string;
  }) => {
    return await action({
      config: value,
      companyId,
      check: check || '',
    });
  };

  return {
    open: () => {
      openForm({
        modalTitle: translate('companyConfig.addExtraTicket'),
        useFormHookHandler,
        onConfirm,
        actionHook: companyConfigApi.useAddFerryCompanyExtraConfigMutation,
      });
    },
    close: () => {
      closeForm();
    },
  };
};
