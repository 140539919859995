import { VStack } from '@droplet-tech-code/core-elements/module/ui/Stack';
import { Text } from '@droplet-tech-code/core-elements/module/ui/Text';
import { Image } from 'react-native';

import ErrorSvg from '../../assets/error.svg';
import { Button } from '@droplet-tech-code/core-elements/module/ui/Button';
import { translate } from '~/utils/translation.utils';
import { useEffect, useState } from 'react';
import { useRetrieveFromProviderStore } from '~/screens/Support/Requests/utils/supportRequest.store';
import { ErrorObject } from '@droplet-tech-code/core-elements/module/utils/error';
import { dayjs } from '@droplet-tech-code/core-elements/module/utils/utils.date';

export const ErrorFeedback = ({
  error,
  retry,
  isLoading,
  timerCountdown,
}: {
  error?: ErrorObject;
  retry?: () => void;
  isLoading?: boolean;
  timerCountdown?: number;
}) => {
  const [isDisabled, setIsDisabled] = useState(
    timerCountdown != undefined ? timerCountdown > 0 : false,
  );
  const code = error?.code;
  const message = error?.message;

  const [timeLeft, setTimeLeft] = useState(timerCountdown || 0);
  const fetchDatetime = useRetrieveFromProviderStore((state) => state.fetchDatetime);
  const setFetchDatetime = useRetrieveFromProviderStore(
    (state) => state.setFetchDatetime,
  );

  useEffect(() => {
    if (!isLoading) {
      if (timerCountdown) {
        if (!fetchDatetime) {
          setFetchDatetime(dayjs().add(timerCountdown, 's').toString());
        }
      }
    }
    // If timerCountdown is 0 or less, enable button immediately
    if (timeLeft <= 0) {
      setIsDisabled(false);
      setFetchDatetime(undefined);
      return;
    }
    if (timeLeft !== 0) {
      // Start the countdown
      const timer = setTimeout(() => {
        setTimeLeft(timeLeft - 1);
      }, 1000);
      // Cleanup the timer if the component unmounts
      return () => clearTimeout(timer);
    }
  }, [timeLeft, isLoading]);
  return (
    <VStack bg="white" p="12" align="center" justify="center" space="3">
      <Image
        source={{ uri: ErrorSvg as any }}
        style={{ width: 150, height: 150 }}
        resizeMode="contain"
      />
      {!!code && <Text.Body1Medium>{code}</Text.Body1Medium>}
      <Text.Body1Regular>{message || 'Error occurred'}</Text.Body1Regular>
      <Button
        variant="outline"
        onPress={retry}
        disabled={isDisabled}
        isLoading={isLoading}
      >
        <Text.Small color={timeLeft === 0 ? 'primary-dark' : 'monochrome'}>{`${translate(
          'common.retry',
        )} ${timeLeft === 0 ? '' : timeLeft}`}</Text.Small>
      </Button>
    </VStack>
  );
};
