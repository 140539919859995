import {
  ConfigForData,
  FormGroup,
  SelectedValueItem,
} from '@droplet-tech-code/core-common-types';
import { FieldInput, FieldInputList } from '@naus-code/naus-basket-manager';

import { DATE_SERVER_FORMAT } from '~/utils/date.utils';

export function fieldInputsToFormBuilder(fieldInputs: FieldInput[]): ConfigForData {
  const allItems = [] as FormGroup[];
  for (let i = 0; i < fieldInputs.length; i++) {
    const fieldInput = fieldInputs[i];

    const label = fieldInput.label;
    const id = fieldInput.fieldId;
    const type = fieldInputTypeToFormGroupType(fieldInput.type);

    let formGroup = {} as FormGroup;

    switch (type) {
      case 'toggle': {
        const initialValue = fieldInput.value as boolean;
        formGroup = {
          id,
          type,
          label,
          initialValue,
        };
        break;
      }
      case 'number': {
        const initialValue = fieldInput.value as number;
        formGroup = {
          id,
          type,
          label,
          initialValue,
        };
        break;
      }
      case 'date': {
        const initialValue = fieldInput.value as string;
        formGroup = {
          id,
          type,
          label,
          format: DATE_SERVER_FORMAT,
          initialValue,
        };
        break;
      }
      case 'single-select': {
        const list: SelectedValueItem[] = (fieldInput as FieldInputList).listSelect.map(
          (item) => {
            return { label: item.text, value: item.id };
          },
        );
        const initialValue = fieldInput.value as string;
        formGroup = {
          id,
          type,
          label,
          list,
          initialValue,
        };
        break;
      }
      default: {
        const initialValue = fieldInput.value as string;
        formGroup = {
          id,
          type,
          label,
          initialValue,
        };
        break;
      }
    }
    allItems.push(formGroup);
  }

  return [
    {
      type: 'form',
      items: allItems,
    },
  ];
}

function fieldInputTypeToFormGroupType(fieldInputType: FieldInput['type']) {
  switch (fieldInputType) {
    case 'text-latin':
      return 'text';
    case 'list':
      return 'single-select';
    default:
      return fieldInputType;
  }
}
