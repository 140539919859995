import { FormHandler } from '@droplet-tech-code/core-elements/module/ui/Inputs/Form';
import { create } from 'zustand';

interface ManualFormItem {
  form: FormHandler;
  ticketId: string;
  purchaseId: string;
}

export interface ManualProviderStore {
  modificationForms: { [key: string]: ManualFormItem['form'][] };
  addForm: (_: ManualFormItem) => void;
}

export const createManualModificationStore = () =>
  create<ManualProviderStore>((set) => ({
    modificationForms: {},
    addForm: (newForm) => {
      set((state) => {
        const formKey = newForm.purchaseId + newForm.ticketId;
        const existingForms = state.modificationForms[formKey] || ([] as FormHandler[]);
        const updatedForm: FormHandler[] = [...existingForms, newForm.form];

        return {
          modificationForms: {
            ...state.modificationForms,
            [formKey]: updatedForm,
          },
        };
      });
    },
  }));
