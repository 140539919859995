import {
  CreateAdminUserRequest,
  CreateAdminUserResponse,
} from '@naus-code/naus-admin-types';
import { useCallback } from 'react';

import { useFormModal } from '~/components/Modals/FormModal';
import { usersApi } from '~/screens/Users/Users.api';
import { translate } from '~/utils/translation.utils';

export const useCreateAdminUserModal = () => {
  const { open: openForm, close: closeForm } = useFormModal();

  const useFormHookHandler = useCallback(() => {
    const { data: config, isLoading } = usersApi.useGetUserConfigCreateQuery();
    return { data: { config }, isLoading };
  }, []);

  const onConfirm = async ({
    action,
    value,
  }: {
    action: (req: CreateAdminUserRequest) => CreateAdminUserResponse;
    value: CreateAdminUserRequest['adminUser'];
  }) => {
    return await action({
      adminUser: {
        ...value,
        rules: {
          userRules: {},
        },
      },
    });
  };

  return {
    open: () => {
      openForm({
        modalTitle: translate('users.createUserInfo'),
        useFormHookHandler,
        onConfirm,
        actionHook: usersApi.useCreateUserMutation,
      });
    },
    close: () => {
      closeForm();
    },
  };
};
