import { SelectedValueItem } from '@droplet-tech-code/core-common-types';
import { FormHandler } from '@droplet-tech-code/core-elements/module/ui/Inputs/Form';
import { NumberInput } from '@droplet-tech-code/core-elements/module/ui/Inputs/NumberInput/NumberInput';
import { SelectInputBase } from '@droplet-tech-code/core-elements/module/ui/Inputs/SelectInput/SelectInput';
import { VStack } from '@droplet-tech-code/core-elements/module/ui/Stack';
import { Text } from '@droplet-tech-code/core-elements/module/ui/Text';
import { MutableRefObject, useRef } from 'react';
import { translate } from '~/utils/translation.utils';

export interface ConversionProps {
  label?: string;
  currency?: string;
  selected?: string;
  currencyList: SelectedValueItem[];
  setCurrency: (currency: string) => void;
}

export const SelectCurrency = ({
  label,
  currency,
  selected,
  currencyList,
  setCurrency,
}: ConversionProps) => {
  return (
    <SelectInputBase
      feedback=""
      noFeedback
      label={label}
      type="single-select"
      options={currencyList}
      readonly={!!currency}
      values={[selected ?? '']}
      setValues={(selected) => {
        if (!selected) {
          return;
        }
        setCurrency(selected[0]);
      }}
    />
  );
};

export const FromConversionComponent = (
  props: ConversionProps & {
    valueRef: MutableRefObject<FormHandler>;
    value?: number;
  },
) => {
  return (
    <VStack>
      <SelectCurrency {...props} />
      <Text.Small color="monochrome">
        {translate('conversionTool.companyValue')}
      </Text.Small>
      <ValueItem value={props.value} valueRef={props.valueRef} />
    </VStack>
  );
};

export const ToConversionComponent = (
  props: ConversionProps & {
    value?: number;
    rate?: number;
  },
) => {
  const tempRef = useRef(new FormHandler());
  return (
    <VStack>
      <SelectCurrency {...props} />
      <VStack>
        <Text.Small color="monochrome">
          {translate('conversionTool.rate') + ': ' + String(props.rate || '-')}
        </Text.Small>
        <NumberInput
          form={tempRef.current}
          readonly
          config={{ id: 'value', type: 'number', initialValue: props.value }}
          value={String(props.value || '-')}
        />
      </VStack>
    </VStack>
  );
};

const ValueItem = ({
  valueRef,
  value,
}: {
  valueRef: MutableRefObject<FormHandler>;
  value?: number;
}) => {
  return (
    <NumberInput
      form={valueRef.current}
      config={{ id: 'value', type: 'number', initialValue: value }}
      value={String(valueRef.current)}
    />
  );
};
