import {
  SystemConfigGetOperationConfigResponse,
  SystemConfigUpdateAppVersionRequest,
} from '@naus-code/naus-admin-types';
import { useCallback } from 'react';

import { useFormModal } from '~/components/Modals/FormModal';
import { systemConfigApi } from '~/screens/Configs/System/systemConfigs.api';
import { translate } from '~/utils/translation.utils';

export const useUpdateSystemAppVersionModal = () => {
  const { open: openForm } = useFormModal();

  const useFormHookHandler = useCallback(() => {
    const { data, isLoading } =
      systemConfigApi.useUpdateSystemAppVersionFormConfigQuery();
    return { data, isLoading };
  }, []);

  const onConfirm = async ({
    action,
    value,
    check,
  }: {
    action: (
      req: SystemConfigUpdateAppVersionRequest,
    ) => SystemConfigGetOperationConfigResponse;
    value: SystemConfigUpdateAppVersionRequest['appVersionConfig'];
    check?: string;
  }) => {
    return await action({
      appVersionConfig: value,
      check: check ?? '',
    });
  };

  return {
    open: () => {
      openForm({
        modalTitle: translate('systemConfig.updateAppVersion'),
        useFormHookHandler,
        onConfirm,
        actionHook: systemConfigApi.useUpdateSystemAppVersionConfigMutation,
      });
    },
  };
};
