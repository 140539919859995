import { ConfigForData } from '@droplet-tech-code/core-common-types';
import {
  SystemConfigAddAdminUserSubGroupRequest,
  SystemConfigAddLanguage,
  SystemConfigAddTaskCategoryRequest,
  SystemConfigGetOperationConfigResponse,
} from '@naus-code/naus-admin-types';
import { useCallback } from 'react';

import { useFormModal } from '~/components/Modals/FormModal';
import { systemConfigApi } from '~/screens/Configs/System/systemConfigs.api';
import { translate } from '~/utils/translation.utils';

export const useAddTaskCategoryModal = ({ check }: { check?: string }) => {
  const { open: openForm, close: closeForm } = useFormModal();

  const onConfirm = async ({
    action,
    value,
    check,
  }: {
    action: (
      req: SystemConfigAddTaskCategoryRequest,
    ) => SystemConfigGetOperationConfigResponse;
    value: { taskCategory: string };
    check?: string;
  }) => {
    return await action({
      taskCategory: value.taskCategory,
      check: check || '',
    });
  };

  return {
    open: () => {
      const config: ConfigForData = [
        {
          items: [
            {
              type: 'text',
              label: translate('systemConfig.name'),
              id: 'taskCategory',
            },
          ],
        },
      ];
      openForm({
        modalTitle: translate('systemConfig.addTermCategory'),
        useFormHookHandler: () => {
          return { data: { config, check }, isLoading: false };
        },
        onConfirm,
        actionHook: systemConfigApi.useAddAdminUserSubGroupMutation,
      });
    },
    close: () => {
      closeForm();
    },
  };
};

export const useAddAdminUserGroupModal = ({ check }: { check?: string }) => {
  const { open: openForm, close: closeForm } = useFormModal();

  const onConfirm = async ({
    action,
    value,
    check,
  }: {
    action: (
      req: SystemConfigAddAdminUserSubGroupRequest,
    ) => SystemConfigGetOperationConfigResponse;
    value: { adminUserGroup: string };
    check: string;
  }) => {
    return await action({
      subGroup: value.adminUserGroup,
      check: check || '',
    });
  };

  return {
    open: () => {
      const config: ConfigForData = [
        {
          items: [
            {
              type: 'text',
              label: translate('systemConfig.name'),
              id: 'adminUserGroup',
            },
          ],
        },
      ];
      openForm({
        modalTitle: translate('systemConfig.addAdminUserSubGroup'),
        useFormHookHandler: () => {
          return { data: { config, check }, isLoading: false };
        },
        onConfirm,
        actionHook: systemConfigApi.useAddAdminUserSubGroupMutation,
      });
    },
    close: () => {
      closeForm();
    },
  };
};

export const useAddSystemLanguageModal = () => {
  const { open: openForm, close: closeForm } = useFormModal();

  const useFormHookHandler = useCallback(() => {
    const { data, isLoading } = systemConfigApi.useAddSystemLanguageFormConfigQuery();
    return { data, isLoading };
  }, []);

  const onConfirm = async ({
    action,
    value,
    check,
  }: {
    action: (req: SystemConfigAddLanguage) => SystemConfigGetOperationConfigResponse;
    value: SystemConfigAddLanguage;
    check?: string;
  }) => {
    return await action({
      ...value,
      check: check || '',
    });
  };

  return {
    open: () => {
      openForm({
        modalTitle: translate('systemConfig.addSystemLanguage'),
        useFormHookHandler,
        onConfirm,
        actionHook: systemConfigApi.useAddSystemLanguageMutation,
      });
    },
    close: () => {
      closeForm();
    },
  };
};
