export const STORE_TAGS = [
  '*',
  /**
   * Events
   */
  'Event_List',
  'Event_Item',
  'User_Support_List',
  'User_Item',
  'User_Create_Config',
  'User_Update_Config',
  'User_List',
  'getVesselList',
  'getVessel',
  'createVesselFormConfig',
  'createVessel',
  'getPortLocation',
  'getPortLocationList',
  'getPortLocationFormConfig',
  'createPortLocation',
  'getPortLocationForm',
  'createLocationImageUpload',
  'getCreatePortLocationFormConfig',
  'getAreaLocationList',
  'getAreaLocationFormConfig',
  'createAreaLocation',
  'getAreaLocation',
  'getCreateAreaLocationFormConfig',
  'getNotice',
  'getActiveNoticesList',
  'getPendingNoticesList',
  'createNoticeFormConfig',
  'getSupportRequestClosed',
  'getSupportRequestPaused',
  'getSupportRequestPending',

  /**
   * Purchase
   */
  'getPurchaseListByEmail',
  'getPurchaseListByCompanyResCode',
  'getPurchaseListByProviderResCode',
  'getPurchaseListByCompanyTicketNumber',
  'getPurchasesByCompanyTicketNumberConfig',
  'getPurchaseListByDateFormConfig',
  'getPurchaseListByDate',
  'getPurchaseListByCustomer',
  'getPurchase',
  'getPurchase2',
  'getPurchaseRetrieveFromProvider',
  'getPurchaseRetrieveFromProviderWithBookingId',
  'getPurchaseRetrieveFromProviderRes',
  /**
   * Purchase Action
   */
  'getCancelPurchasePolicies',
  'getPurchaseRetrieveFromProviderFormConfig',
  'getPurchaseRetrieveFromProviderRes',
  'getPurchaseCompanyContactDetails',
  'getBookingListByEmail',
  'getRecentBookingList',
  'getBookingListByCustomer',

  'getSupportRequest',
  'getBookingFromRef',
  'getBooking',
  'getSystemConfig',
  'updateFerryBookingConfigFormConfig',
  'updateFerryBookingConfig',
  'updateFerryNotifierConfigFormConfig',
  'updateFerryNotifierConfig',
  'updateFerryServiceCostConfigFormConfig',
  'updateFerryServiceCostConfig',
  'getSupportConfig',
  'updateCompanyTermsFormConfig',
  'updatePrivacyTermsConfigFormConfig',
  'addSystemLanguageFormConfig',
  'getOperationConfig',
  'getUpdateAnswerTemplateFormConfig',
  'addSupportTypeFormConfig',
  'updateSupportTypeFormConfig',
  'updateFerryCompanyConfigFormConfig',
  'updateFerryActiveConfigFormConfig',
  'getUpdateFerryOperationsFormConfig',
  'updateFerryCompanyOperatorConfigFormConfig',
  'updateFerryOperatorContactConfigFormConfig',
  'updateFerryOperatorETicketConfigFormConfig',
  'updateFerryOperatorTermsConfigFormConfig',
  'updateFerryOperatorCustomerOperationFormConfig',
  'updateFerryOperationsFormConfig',
  'updateFerryCompanyDiscountConfigFormConfig',
  'addFerryCompanyDiscountConfigFormConfig',
  'updateFerryCompanyPassengerConfigFormConfig',
  'addFerryCompanyPassengerConfigFormConfig',
  'addFerryCompanyVehicleConfigFormConfig',
  'updateFerryCompanyVehicleConfigFormConfig',
  'addFerryCompanyExtraConfigFormConfig',
  'updateFerryCompanyExtraConfigFormConfig',
  'updateFerryCompanyPassengerOperationConfigFormConfig',
  'updateSystemAppVersionFormConfig',

  'createOffice',
  'getCreateOfficeFormConfig',
  'getUpdateOfficeFormConfig',
  'updateOffice',
  'setDefaultOffice',
  'createAnswerTemplate',
  'getCreateAnswerTemplateFormConfig',
  'getCompanyConfigList',
  'getFerryCompanyConfig',
  'getMissingVessels',
  'getMissingVesselsForm',
  'addMissingVessels',
  'getMissingPassengers',
  'getMissingPassengersForm',
  'addMissingPassengers',
  'addMissingVehicles',
  'getMissingVehicles',
  'getMissingVehiclesForm',
  'addMissingExtras',
  'getMissingExtras',
  'getMissingExtrasForm',
  'addMissingLocations',
  'getMissingLocations',
  'getMissingLocationsForm',
  'addMissingDiscounts',
  'getMissingDiscounts',
  'getMissingDiscountsForm',
  'assignAdminUser',
  'assignAdminUserFormConfig',
  'sendCustomerReminder',
  'createPaymentChargeFormConfig',
  'createPaymentCharge',
  'cancelPendingPayment',
  'addSupportNote',
  'changeSupportStatus',
  'getTemplateAnswers',
  'editReplyFormConfig',
  'fillDynamicTemplateAnswer',
  // 'sendReply',
  'deleteReply',
  'editReply',
  'createSupportRequest',
  'createPaymentRefundFormConfig',
  'getSupportRequestPending',
  'getSupportRequestListByPurchaseId',
  'getSupportRequestListByCustomerId',
  'getSupportRequestListByEmail',
  'createSupportRequestFormConfig',
  'createFerryCompanyConfigFormConfig',
  'addFerryCompanyConfig',
  'createFerryCompanyOperatorConfigFormConfig',
  'addFerryCompanyOperatorConfig',
  'createAreaLocationImageUpload',
  'sendReplyFormConfig',
  'addFerryCompanyOperatorConfigImageToken',
  'importPurchaseFromProviderMissingInfoForm',

  'getCustomer',
  'getRecentPayments',
  'findCustomerByEmail',
  'updateCustomerFormConfig',
  'createCustomerVoucherFormConfig',
  'createCustomerVoucher',
  'getVoucherListByCustomerId',
  'getVoucher',
  'getVoucherByTinyId',
  'createVoucherFormConfig',
  'getVoucherListByAdminReference',
  'getVoucherListByAdminReference',
  'getVoucherListByEmail',

  'getPurchasePdf',

  'getScheduledList',
  'getScheduledChange',
  'verifyScheduledChange',
  'createScheduledChangeFormConfig',
  'verifyFormScheduledChange',
  'getPurchasesUpcoming',

  'getPurchaseCompanyTerms',
  'getPurchaseAgencies',
  'getPurchaseCapabilities',
  'getModifyPurchasePolicies',
  'getOpenPurchasePolicies',

  'getTransactionByBooking',
  'getTransaction',
  'getPurchaseCompanyCredit',

  'getAllOperatorFinancialCreditLine',
  'getOperatorFinancialCreditLine',

  'searchLocations',
  'searchRouteFull',
  'changeSupportTypeFormConfig',

  'getUpdateVesselFormConfig',

  'PurchaseClientGetProviderReservationResponse',
  'PurchaseListFullResponse',
  'PurchaseListResponse',

  'verifyPricePurchaseGenerateRoutePrice',
  'PurchaseClientGenerateRoutePriceResponse',

  'searchRouteModify',
  'getTripDetails',
  'openPurchaseCalculation',

  'getTransactionsPeriod',

  //System

  'getCountries',
  'getCurrencies',
  'getConversions',
  'getMetaSearchJobs',
  'createMetaSearchJobFormConfig',
  'sendMissingVesselsEmail',
] as const;
