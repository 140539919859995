import {
  PurchaseClientAutoImportRequest,
  PurchaseClientWithMetaWithTickets,
  TicketClient,
} from '@naus-code/naus-admin-types';

import { purchaseActionApi } from '../../PurchaseActions.api';
import { Modal, useModal } from '@droplet-tech-code/core-elements/module/ui/Modal';
import { ModalImportPurchaseFromProvider } from './ActionPanel.Import';
import {
  ManualModificationProvider,
  useManualStore,
} from '../Modify/Manual/ManualProvider/Modify.ManualTrip.Provider';
import { showToast } from '@droplet-tech-code/core-elements/module/ui/Toast';
import { handleResponse } from '@droplet-tech-code/core-elements/module/utils/error';
import { ManualModifyPurchaseInfo } from '../Modify/Manual/Modify.ManualTripManager';
import { createAutoImportPurchases, mapTicketToClient } from './ActionPanel.Import.utils';
import {
  BookingRetrieve,
  BookingRetrievePurchase,
} from '@naus-code/naus-server-common-types';
import { DATE_SERVER_FORMAT } from '~/utils/date.utils';
import { dayjs } from '@droplet-tech-code/core-elements/module/utils/utils.date';
import { ConfigForData } from '@droplet-tech-code/core-common-types';
import { purchaseActionManager } from '../Common/ActionPanel.utils';
import { View } from '@droplet-tech-code/core-elements/module/ui/View';
import { useEffect } from 'react';

const ImportPurchaseEditModalKey = 'ImportPurchaseEditModal-Key';

export const useImportPurchaseEditModal = () => {
  const { openModal, closeModal } = useModal();

  return {
    open: (
      body: PurchaseClientAutoImportRequest,
      retrievedBooking: BookingRetrieve,
      _config?: ConfigForData,
    ) => {
      openModal(
        <ImportPurchaseEditContainer body={body} retrievedBooking={retrievedBooking} />,
        {
          key: ImportPurchaseEditModalKey,
          type: 'action-sheet',
        },
      );
    },
    close: () => closeModal(ImportPurchaseEditModalKey),
  };
};

const ImportPurchaseEditContainer = ({
  body,
  retrievedBooking,
}: {
  body: PurchaseClientAutoImportRequest;
  retrievedBooking: BookingRetrieve;
}) => {
  return (
    <ManualModificationProvider>
      <View p="2.5">
        <ImportPurchaseEditModal
          body={body}
          retrievedBooking={retrievedBooking}
          purchase={body.purchase}
        />
      </View>
    </ManualModificationProvider>
  );
};

const ImportPurchaseEditModal = ({
  body,
  purchase,
  retrievedBooking,
}: {
  body: PurchaseClientAutoImportRequest;
  purchase: BookingRetrievePurchase;
  retrievedBooking: BookingRetrieve;
}) => {
  const [selectedPurchase, setSelectedReservation] =
    purchaseActionManager.state.useGlobalState('selectedReservation');

  const [_selectedTickets, setSelectedTitckets] =
    purchaseActionManager.state.useGlobalState('selectedTickets');

  const mappedTickets = purchase.tickets
    .map((ticket, index) => mapTicketToClient(ticket, purchase, index + ''))
    .filter((ticket) => !!ticket) as TicketClient[];
  const ticketIds = mappedTickets.map((ticket) => ticket?._id) as string[];

  useEffect(() => {
    setSelectedReservation(purchase.companyReservationCode);
    setSelectedTitckets(ticketIds);
  }, [selectedPurchase]);

  const { closeModal } = useModal();
  const manualModStore = useManualStore();
  const modificationForms = manualModStore((s) => s.modificationForms);

  const [addInfo, { isLoading: isAddingPurchase }] =
    purchaseActionApi.useImportPurchaseFromProviderMutation();
  const purchaseProcessed = mapPurchaseRetrieveToClient(purchase, mappedTickets);

  const onConfirm = async () => {
    const purchasesToUpdateData = createAutoImportPurchases(modificationForms, purchase);

    if (!purchasesToUpdateData) {
      showToast('Cannot get purchase form data', { type: 'error' });
      return;
    }

    const { autoUpdatePurchase } = purchasesToUpdateData;

    const response = await addInfo({
      ...body,
      purchase: {
        ...autoUpdatePurchase,
        dateCreated: retrievedBooking.dateCreated,
      },
    });

    handleResponse({
      response,
      onError: () => {
        showToast('Importing failed!', { type: 'error' });
      },
      onSuccess: () => {
        closeModal(ModalImportPurchaseFromProvider);
        closeModal(ImportPurchaseEditModalKey);
      },
    });
    setSelectedReservation('');
    return response;
  };

  return (
    <Modal.Container style={{ maxHeight: 800 }}>
      <Modal.Header
        title="Confirm all details are correct and accurate"
        onPress={() => closeModal(ImportPurchaseEditModalKey)}
      />
      <Modal.Body scroll>
        <ManualModifyPurchaseInfo purchase={purchaseProcessed} defaultShowAllVessels />
      </Modal.Body>
      <Modal.Footer onConfirm={() => onConfirm()} isLoading={isAddingPurchase} />
    </Modal.Container>
  );
};

function mapPurchaseRetrieveToClient(
  purchase: BookingRetrievePurchase,
  mappedTickets: TicketClient[],
): PurchaseClientWithMetaWithTickets {
  const ticketIds = mappedTickets.map((ticket) => ticket?._id);

  const purchaseClient: PurchaseClientWithMetaWithTickets = {
    ...purchase,
    _bookingId: '',
    bookingId: '',
    _customerId: '',
    bookingRef: '',
    _id: '0',
    lang: 'en',
    valueCompanyCcy: purchase.value,
    companyCcy: purchase.companyCcy,
    value: purchase.valueCcy,
    ccy: purchase.ccy,
    valueOriginalCcy: purchase.valueCcy,
    valueOriginalCompanyCcy: purchase.value,
    valueCompanyCommission: 0,
    dateBookingCreated: '',
    dateDocumentCreated: '',
    dateLastUpdatedFromProvider: '',
    lastAutoUpdateFromProvider:
      {} as PurchaseClientWithMetaWithTickets['lastAutoUpdateFromProvider'],
    requiresAutoUpdate: false,
    agentId: '',
    supportCount: 0,
    subDistributionProvider: '',
    arrivalDateTime: purchase.arrivalDateTime || '',
    departureTime: dayjs(purchase.departureDateTime).format('HH:mm'),
    operatorImgUrl: '',
    arrivalTime: dayjs(purchase.arrivalDateTime).format('HH:mm'),
    duration: '',
    departureDate: dayjs(purchase.departureDateTime).format(DATE_SERVER_FORMAT),
    departureTimestamp: purchase.departureDateTime,
    endTimestamp: '',
    originDisplay: purchase.origin,
    originTimezone: '',
    destinationDisplay: purchase.destination,
    destinationTimezone: '',
    ticketCount: purchase.tickets.length,
    dateCreated: '',
    companyId: purchase.companyId,
    operator: '',
    vesselName: '',
    vesselId: purchase.vesselId || '',
    historyCount: 0,
    ccySymbol: '',
    p_email: '',
    startTimestamp_original: purchase.startTimestamp,
    sortTimestamp: purchase.startTimestamp,
    _supportRequestIds: [],
    tickets: mappedTickets,
    _ticketIds: ticketIds,
    history: [] as any,
  };
  return purchaseClient;
}
