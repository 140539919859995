import {
  AgencyListResponse,
  GetPurchaseResponse,
  PurchaseCancelResponse,
  PurchaseCancelVisualRequest,
  PurchaseClientAutoImportRequest,
  PurchaseClientAutoUpdateRequest,
  PurchaseClientAutoUpdateResponse,
  PurchaseClientCancelCustomerRequest,
  PurchaseClientCancelProviderAndCustomerRequest,
  PurchaseClientCancelProviderRequest,
  PurchaseClientModifyProviderRequestV2,
  PurchaseClientModifyProviderResponse,
  PurchaseClientOpenProviderRequest,
  PurchaseClientOpenVisualRequest,
  PurchaseCompanyTermsResponse,
  PurchaseGetCancelCalculationProviderResponse,
  PurchaseGetCancelCalculationRequest,
  PurchaseGetCancelCalculationResponse,
  PurchaseGetCancelPoliciesResponse,
  PurchaseGetCapabilitiesResponse,
  PurchaseGetModifyPoliciesResponse,
  PurchaseGetOpenCalculationRequest,
  PurchaseGetOpenCalculationResponse,
  PurchaseGetOpenPoliciesResponse,
  PurchaseListClientModifyManualRequest,
  PurchaseListClientModifyManualResponse,
  PurchaseSendETicketRequest,
  PurchaseSplitRequest,
  PurchaseSplitResponse,
  SystemClientGetCreditResponse,
} from '@naus-code/naus-admin-types';

import { rootApi } from '~/store/redux.utils';

const base_url = 'purchase/actions';
export const purchaseActionApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    // @Post('/split-purchase/', [applyRoleValidation(['ADMIN', 'SYSTEM'])])
    // splitPurchase(@Body() body: PurchaseSplitRequest): Promise<PurchaseSplitResponse> {
    //     return this._purchaseService.splitPurchase(body);
    // }

    splitPurchase: builder.mutation<PurchaseSplitResponse, PurchaseSplitRequest>({
      query: (data) => ({
        url: `${base_url}/split-purchase`,
        method: 'POST',
        data,
      }),
      // TODO invalidate list
      invalidatesTags: ['getBooking', 'getPurchase'],
    }),

    // //--------------CANCEL--------------//

    // @Get('/get-cancel-purchase-policies/:id', [applyRoleValidation(['ADMIN', 'SYSTEM'])])
    // cancelPurchasePolicies(@Params('id') id: string): Promise<PurchaseGetCancelPoliciesResponse> {
    //     return this._purchaseService.cancelPurchasePolicies(id);
    // }

    getCancelPurchasePolicies: builder.query<PurchaseGetCancelPoliciesResponse, string>({
      query: (id) => ({
        url: `${base_url}/get-cancel-purchase-policies/${id}`,
        method: 'GET',
      }),
      providesTags: ['*', 'getCancelPurchasePolicies'],
    }),

    // @Post('/get-cancel-purchase-calculation/', [applyRoleValidation(['ADMIN', 'SYSTEM'])])
    // cancelPurchaseCalculation(
    //     @Body() body: PurchaseGetCancelCalculationRequest,
    // ): Promise<PurchaseGetCancelCalculationResponse> {
    //     return this._purchaseService.cancelPurchaseCalculation(body);
    // }

    getCancelPurchaseCalculation: builder.query<
      PurchaseGetCancelCalculationResponse,
      PurchaseGetCancelCalculationRequest
    >({
      query: (data) => ({
        url: `${base_url}/get-cancel-purchase-calculation`,
        method: 'POST',
        data,
      }),
    }),

    // @Post('/get-cancel-purchase-calculation-from-provider/', [
    //     applyRoleValidation(['ADMIN', 'SYSTEM']),
    // ])
    // cancelPurchaseCalculationFromProvider(
    //     @Body() body: PurchaseGetCancelCalculationRequest,
    // ): Promise<PurchaseGetCancelCalculationProviderResponse> {
    //     return this._purchaseService.cancelPurchaseCalculationFromProvider(body);
    // }

    getCancelPurchaseCalculationFromProvider: builder.query<
      PurchaseGetCancelCalculationProviderResponse,
      PurchaseGetCancelCalculationRequest
    >({
      query: (data) => ({
        url: `${base_url}/get-cancel-purchase-calculation-from-provider`,
        method: 'POST',
        data,
      }),
    }),

    // @Post('/cancel-purchase-visual/', [applyRoleValidation(['ADMIN', 'SYSTEM'])])
    // cancelPurchaseVisual(@Body() body: PurchaseCancelRequest): Promise<GetPurchaseResponse> {
    //     return this._purchaseService.cancelPurchaseVisual(body);
    // }

    cancelPurchaseVisual: builder.mutation<
      GetPurchaseResponse,
      PurchaseCancelVisualRequest
    >({
      query: (data) => ({
        url: `${base_url}/cancel-purchase-visual`,
        method: 'POST',
        data,
      }),
      // TODO invalidate list
      invalidatesTags: [
        'getPurchase',
        'getBooking',
        'getSupportRequest',
        'getPurchaseListByCompanyResCode',
        'getPurchaseListByCompanyTicketNumber',
        'getPurchaseListByProviderResCode',
        'getPurchaseListByCustomer',
        'getPurchaseListByEmail',
      ],
    }),

    // @Post('/cancel-purchase-customer/', [applyRoleValidation(['ADMIN', 'SYSTEM'])])
    // cancelPurchaseCustomer(
    //     @Body() body: PurchaseClientCancelCustomerRequest,
    // ): Promise<PurchaseCancelResponse> {
    //     return this._purchaseService.cancelPurchaseCustomer(body);
    // }
    cancelPurchaseCustomer: builder.mutation<
      PurchaseCancelResponse,
      PurchaseClientCancelCustomerRequest
    >({
      query: (data) => ({
        url: `${base_url}/cancel-purchase-customer`,
        method: 'POST',
        data,
      }),
      invalidatesTags: [
        'getPurchase',
        'getBooking',
        'getSupportRequest',
        'getPurchaseListByCompanyResCode',
        'getPurchaseListByCompanyTicketNumber',
        'getPurchaseListByProviderResCode',
        'getPurchaseListByCustomer',
        'getPurchaseListByEmail',
      ],
    }),

    // @Post('/cancel-purchase-provider/', [applyRoleValidation(['ADMIN', 'SYSTEM'])])
    // cancelPurchaseProvider(
    //     @Body() body: PurchaseClientCancelProviderRequest,
    // ): Promise<PurchaseCancelResponse> {
    //     return this._purchaseService.cancelPurchaseProvider(body);
    // }

    cancelPurchaseProvider: builder.mutation<
      PurchaseCancelResponse,
      PurchaseClientCancelProviderRequest
    >({
      query: (data) => ({
        url: `${base_url}/cancel-purchase-provider`,
        method: 'POST',
        data,
      }),
      invalidatesTags: [
        'getPurchase',
        'getBooking',
        'getSupportRequest',
        'getPurchaseListByCompanyResCode',
        'getPurchaseListByCompanyTicketNumber',
        'getPurchaseListByProviderResCode',
        'getPurchaseListByCustomer',
        'getPurchaseListByEmail',
      ],
    }),

    // @Post('/cancel-purchase-provider-and-customer/', [applyRoleValidation(['ADMIN', 'SYSTEM'])])
    // cancelPurchaseProviderAndCustomer(
    //     @Body() body: PurchaseClientCancelProviderAndCustomerRequest,
    // ): Promise<PurchaseCancelResponse> {
    //     return this._purchaseService.cancelPurchaseProviderAndCustomer(body);
    // }

    cancelPurchaseProviderAndCustomer: builder.mutation<
      PurchaseCancelResponse,
      PurchaseClientCancelProviderAndCustomerRequest
    >({
      query: (data) => ({
        url: `${base_url}/cancel-purchase-provider-and-customer`,
        method: 'POST',
        data,
      }),
      invalidatesTags: [
        'getPurchase',
        'getBooking',
        'getSupportRequest',
        'getPurchaseListByCompanyResCode',
        'getPurchaseListByCompanyTicketNumber',
        'getPurchaseListByProviderResCode',
        'getPurchaseListByCustomer',
        'getPurchaseListByEmail',
      ],
    }),

    // @Post('/open-purchase-provider/', [applyRoleValidation(['ADMIN', 'SYSTEM'])])
    // openPurchaseApi(@Body() body: PurchaseOpenRequest): Promise<GetPurchaseResponse> {
    //     return this._purchaseService.openPurchaseProvider(body);
    // }
    openPurchaseApi: builder.mutation<
      GetPurchaseResponse,
      PurchaseClientOpenProviderRequest
    >({
      query: (data) => ({
        url: `${base_url}/open-purchase-provider`,
        method: 'POST',
        data,
      }),
      invalidatesTags: [
        'getPurchase',
        'getBooking',
        'getSupportRequest',
        'getPurchaseListByCompanyResCode',
        'getPurchaseListByCompanyTicketNumber',
        'getPurchaseListByProviderResCode',
        'getPurchaseListByCustomer',
        'getPurchaseListByEmail',
      ],
    }),

    // //--------------OPEN--------------//

    openPurchaseCalculation: builder.query<
      PurchaseGetOpenCalculationResponse,
      PurchaseGetOpenCalculationRequest
    >({
      query: (data) => ({
        url: `${base_url}/get-open-purchase-calculation`,
        method: 'POST',
        data,
      }),
      providesTags: ['*', 'openPurchaseCalculation'],
    }),

    // @Post('/open-purchase-visual/', [applyRoleValidation(['ADMIN', 'SYSTEM'])])
    // openPurchaseVisual(@Body() body: PurchaseOpenRequest): Promise<GetPurchaseResponse> {
    //     return this._purchaseService.openPurchaseVisual(body);
    // }

    openPurchaseVisual: builder.mutation<
      GetPurchaseResponse,
      PurchaseClientOpenVisualRequest
    >({
      query: (data) => ({
        url: `${base_url}/open-purchase-visual`,
        method: 'POST',
        data,
      }),
      invalidatesTags: [
        'getPurchase',
        'getBooking',
        'getSupportRequest',
        'getPurchaseListByCompanyResCode',
        'getPurchaseListByCompanyTicketNumber',
        'getPurchaseListByProviderResCode',
        'getPurchaseListByCustomer',
        'getPurchaseListByEmail',
      ],
    }),

    // @Post('/open-purchase-api/', [applyRoleValidation(['ADMIN', 'SYSTEM'])])
    // openPurchaseApi(@Body() body: PlaceHolder): Promise<PlaceHolder> {
    //     return this._purchaseService.placeHolder(body);
    // }

    // @Post('/open-purchase-api-partial/', [applyRoleValidation(['ADMIN', 'SYSTEM'])])
    // openPurchaseApiPartial(@Body() body: PlaceHolder): Promise<PlaceHolder> {
    //     return this._purchaseService.placeHolder(body);
    // }

    // //--------------MODIFY--------------//

    // @Get('/get-modify-purchase-policies/:id', [applyRoleValidation(['ADMIN', 'SYSTEM'])])
    // modifyPurchasePolicies(@Params('id') id: string): Promise<PurchaseGetModifyPoliciesResponse> {
    //     return this._purchaseService.modifyPurchasePolicies(id);
    // }

    // @Post('/get-modify-purchase-calculation/', [applyRoleValidation(['ADMIN', 'SYSTEM'])])
    // modifyPurchaseCalculation(
    //     @Body() body: PurchaseGetModifyCalculationRequest,
    // ): Promise<PurchaseGetModifyCalculationResponse> {
    //     return this._purchaseService.modifyPurchaseCalculation(body);
    // }

    // @Post('/modify-purchase-from-provider/', [applyRoleValidation(['ADMIN', 'SYSTEM'])])
    // updatePurchaseProvider(@Body() body: PurchaseClientAutoUpdateRequest): Promise<void> {
    //     return this._purchaseService.updatePurchaseProvider(body);
    // }
    modifyPurchaseFromProvider: builder.mutation<
      PurchaseClientModifyProviderResponse,
      PurchaseClientModifyProviderRequestV2
    >({
      query: (data) => ({
        url: `${base_url}/modify-purchase-provider/v2/`,
        method: 'POST',
        data,
      }),
      invalidatesTags: [
        'getPurchase',
        'getBooking',
        'getSupportRequest',
        'getPurchaseListByCompanyResCode',
        'getPurchaseListByCompanyTicketNumber',
        'getPurchaseListByProviderResCode',
        'getPurchaseListByCustomer',
        'getPurchaseListByEmail',
      ],
    }),

    updatePurchaseProvider: builder.mutation<
      PurchaseClientAutoUpdateResponse,
      PurchaseClientAutoUpdateRequest
    >({
      query: (data) => ({
        url: `${base_url}/update-purchase-provider/`,
        method: 'POST',
        data,
      }),
      invalidatesTags: [
        'getPurchase',
        'getBooking',
        'getSupportRequest',
        'getPurchaseListByCompanyResCode',
        'getPurchaseListByCompanyTicketNumber',
        'getPurchaseListByProviderResCode',
        'getPurchaseListByCustomer',
        'getPurchaseListByEmail',
      ],
    }),

    updatePurchaseManual: builder.mutation<
      PurchaseListClientModifyManualResponse,
      PurchaseListClientModifyManualRequest
    >({
      query: (data) => ({
        url: `${base_url}/modify-purchase-manual/`,
        method: 'POST',
        data,
      }),
      invalidatesTags: [
        'getPurchase',
        'getBooking',
        'getSupportRequest',
        'getPurchaseListByCompanyResCode',
        'getPurchaseListByCompanyTicketNumber',
        'getPurchaseListByProviderResCode',
        'getPurchaseListByCustomer',
        'getPurchaseListByEmail',
      ],
    }),

    getPurchaseCompanyTerms: builder.query<PurchaseCompanyTermsResponse, string>({
      query: (id: string) => ({
        url: `${base_url}/get-purchase-company-terms/${id}`,
        method: 'GET',
      }),
      providesTags: ['*', 'getPurchaseCompanyTerms'],
    }),

    getPurchaseAgencies: builder.query<AgencyListResponse, string>({
      query: (id: string) => ({
        url: `${base_url}/get-purchase-agencies/${id}`,
        method: 'GET',
      }),
      providesTags: ['*', 'getPurchaseAgencies'],
    }),

    getModifyPurchasePolicies: builder.query<PurchaseGetModifyPoliciesResponse, string>({
      query: (id: string) => ({
        url: `${base_url}/get-modify-purchase-policies/${id}`,
        method: 'GET',
      }),
      providesTags: ['*', 'getModifyPurchasePolicies'],
    }),

    getOpenPurchasePolicies: builder.query<PurchaseGetOpenPoliciesResponse, string>({
      query: (id: string) => ({
        url: `${base_url}/get-open-purchase-policies/${id}`,
        method: 'GET',
      }),
      providesTags: ['*', 'getOpenPurchasePolicies'],
    }),

    getPurchaseCapabilities: builder.query<PurchaseGetCapabilitiesResponse, string>({
      query: (id: string) => ({
        url: `${base_url}/get-purchase-capabilities/${id}`,
        method: 'GET',
      }),
      providesTags: ['*', 'getPurchaseCapabilities'],
    }),

    getPurchaseCompanyCredit: builder.query<SystemClientGetCreditResponse, string>({
      query: (id: string) => ({
        url: `${base_url}/get-company-credit/${id}`,
        method: 'GET',
      }),
      providesTags: ['*', 'getPurchaseCompanyCredit'],
    }),

    importPurchaseFromProvider: builder.mutation<
      PurchaseClientAutoUpdateResponse,
      PurchaseClientAutoImportRequest
    >({
      query: (data) => ({
        url: `${base_url}/import-purchase-provider/`,
        method: 'POST',
        data,
      }),
      invalidatesTags: [
        'getPurchaseRetrieveFromProviderWithBookingId',
        'getBookingFromRef',
        'getBooking',
      ],
    }),

    toggleHold: builder.mutation<void, string>({
      query: (purchaseId) => ({
        url: `${base_url}/toggle-hold/${purchaseId}`,
        method: 'GET',
      }),
      invalidatesTags: ['*', 'getPurchase', 'getBooking'],
    }),

    togglePrinted: builder.mutation<void, string>({
      query: (purchaseId) => ({
        url: `${base_url}/toggle-printed/${purchaseId}`,
        method: 'GET',
      }),
      invalidatesTags: ['*', 'getPurchase', 'getBooking'],
    }),

    sendETicket: builder.mutation<void, PurchaseSendETicketRequest>({
      query: (data) => ({
        url: `${base_url}/send-e-ticket`,
        method: 'POST',
        data,
      }),
    }),
  }),
});
