import { DelayRender } from '@droplet-tech-code/core-elements/module/ui/Delay/Delay';
import { Text } from '@droplet-tech-code/core-elements/module/ui/Text';
import { View } from '@droplet-tech-code/core-elements/module/ui/View';
import JSONTree from 'react-native-json-tree';

import { PageContainer } from '~/components/Page/Page.Container';
import type { ScreenPropReservationFromProvider } from '~/navigation/Navigation.types';

import { purchaseApi } from '../Purchases.api';
import { ErrorFeedback } from '~/components/Feedback/Error.Feedback';
import { getErrorResponse } from '@naus-code/naus-client-common/src/Common/error';
import { useEffect } from 'react';
import { dayjs } from '@droplet-tech-code/core-elements/module/utils/utils.date';
import { useRetrieveFromProviderStore } from '../../Requests/utils/supportRequest.store';

export const ReservationFromProviderScreen = (
  props: ScreenPropReservationFromProvider,
) => {
  return (
    <PageContainer>
      <ReservationFromProviderLayout {...props} />
    </PageContainer>
  );
};

export const ReservationFromProviderLayout = (
  props: ScreenPropReservationFromProvider,
) => {
  const resCode = props.route.params.providerReservationCode;
  const provider = props.route.params.provider;
  const ccy = props.route.params.ccy;

  return <ReservationFromProvider resCode={resCode} provider={provider} ccy={ccy} />;
};

export const ReservationFromProvider = ({
  resCode,
  provider,
  ccy,
}: {
  resCode: string;
  provider: string;
  ccy: string;
}) => {
  const {
    data: purchaseData,
    isLoading,
    isError,
    error,
    refetch,
  } = purchaseApi.useGetPurchaseRetrieveFromProviderResQuery({
    resCode,
    provider,
    ccy,
  });

  const fetchDatetime = useRetrieveFromProviderStore((state) => state.fetchDatetime);
  const setFetchDatetime = useRetrieveFromProviderStore(
    (state) => state.setFetchDatetime,
  );

  useEffect(() => {
    if (!isLoading) {
      if (!isError) {
        setFetchDatetime(undefined);
      }
    }
  }, [isLoading]);

  if (isError) {
    if (getErrorResponse(error).code === '1967') {
      const timerCountdown = fetchDatetime ? -1 * dayjs().diff(fetchDatetime, 's') : 30;
      return (
        <ErrorFeedback
          key={timerCountdown}
          error={getErrorResponse(error)}
          timerCountdown={timerCountdown}
          retry={refetch}
          isLoading={isLoading}
        />
      );
    }
    return (
      <ErrorFeedback
        error={getErrorResponse(error)}
        retry={refetch}
        isLoading={isLoading}
      />
    );
  }

  return (
    <DelayRender isLoading={isLoading}>
      {purchaseData ? (
        <JSONTree
          data={purchaseData as any}
          hideRoot
          theme={{
            scheme: 'google',
            author: 'seth wright (http://sethawright.com)',
            base00: '#1d1f21',
            base01: '#282a2e',
            base02: '#373b41',
            base03: '#969896',
            base04: '#b4b7b4',
            base05: '#c5c8c6',
            base06: '#e0e0e0',
            base07: '#ffffff',
            base08: '#CC342B',
            base09: '#F96A38',
            base0A: '#FBA922',
            base0B: '#198844',
            base0C: '#3971ED',
            base0D: '#3971ED',
            base0E: '#A36AC7',
            base0F: '#3971ED',
          }}
          valueRenderer={(v) => {
            return (
              <View>
                <Text.Small bold color="secondary-mid">
                  {v as any}
                </Text.Small>
              </View>
            );
          }}
          shouldExpandNode={() => true}
        />
      ) : null}
    </DelayRender>
  );
};
