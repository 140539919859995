import { SelectedValueItem } from '@droplet-tech-code/core-common-types';
import { SelectInputBase } from '@droplet-tech-code/core-elements/module/ui/Inputs/SelectInput/SelectInput';
import { isErrorResponse } from '@droplet-tech-code/core-elements/module/utils/error';
import { notEmpty } from '@droplet-tech-code/core-elements/module/utils/utils.helper';
import {
  SelectTicketEditPassengerAcc,
  SelectTicketEditTariffItem,
  SelectTicketPassengerItem,
  SelectTicketVehicleItem,
} from '@naus-code/naus-basket-manager';
import { RouteTrip } from '@naus-code/naus-client-types';

import { getPriceWithSymbol } from '~/screens/Support/Purchases/purchase.utils';
import { translate } from '~/utils/translation.utils';

import { useBasketManager } from '../BasketManager.Provider';
import {
  useTicketsPricingPassengerDiscountStore,
  useTicketsPricingPassengerExtraInfoStore,
  useTicketsPricingPassengerJoinPassengersStore,
  useTicketsPricingPassengerLoyaltyStore,
} from '../utils/ticketPricing/TicketPricing.Provider';

export const VehicleDiscounts = ({
  trip,
  vehicleTicket,
}: {
  trip: RouteTrip;
  vehicleTicket: SelectTicketVehicleItem;
}) => {
  const basketManager = useBasketManager();
  const { key: tripKey } = trip;

  const triggerActionDiscountModal = useTicketsPricingPassengerDiscountStore()(
    (s) => s.triggerActionModal,
  );
  const triggerActionLoyaltyModal = useTicketsPricingPassengerLoyaltyStore()(
    (s) => s.triggerActionModal,
  );

  const discountTickets: SelectTicketEditTariffItem[] = (
    [...(vehicleTicket.availableDiscounts || [])] as any[]
  ).filter((item) => item.type !== 'break');

  const options = discountTickets.map((d: SelectTicketEditTariffItem) => ({
    label: d.label,
    value: d.code,
  }));

  const value = vehicleTicket.selectedDiscount;
  return (
    <SelectInputBase
      feedback=""
      noFeedback
      label={translate('basketManager.discounts')}
      options={options}
      type="single-select"
      values={[value?.code ?? '']}
      setValues={(newValue) => {
        if (!newValue) {
          return;
        }

        if (newValue[0]) {
          basketManager.selectVehicleDiscount({
            vehicleId: vehicleTicket.vehicleId,
            tripKey,
            discountCode: newValue[0],
            callbacks: {
              onDiscountDataRequired: async () => {
                const response = await triggerActionDiscountModal({
                  itemId: vehicleTicket.vehicleId,
                });
                return response.responseData;
              },
              onLoyaltyDataRequired: async (options) => {
                const response = await triggerActionLoyaltyModal({
                  ...options,
                  itemId: vehicleTicket.vehicleId,
                });
                if (isErrorResponse(response) || !!response.responseData?.error) {
                  return;
                } else {
                  return response.responseData;
                }
              },
            },
          });
        }
      }}
    />
  );
};

export const PassengerDiscount = ({
  trip,
  passengerTicket,
}: {
  trip: RouteTrip;
  passengerTicket: SelectTicketPassengerItem;
}) => {
  const basketManager = useBasketManager();
  const { key: tripKey } = trip;

  const triggerActionDiscountModal = useTicketsPricingPassengerDiscountStore()(
    (s) => s.triggerActionModal,
  );
  const triggerActionLoyaltyModal = useTicketsPricingPassengerLoyaltyStore()(
    (s) => s.triggerActionModal,
  );

  const discountTickets: SelectTicketEditTariffItem[] = (
    [...(passengerTicket.availableDiscounts || [])] as any[]
  ).filter((item) => item.type !== 'break');

  const options = discountTickets.map((d: SelectTicketEditTariffItem) => ({
    label: d.label,
    value: d.code,
  }));

  const value = passengerTicket.selectedDiscount;

  return (
    <SelectInputBase
      feedback=""
      noFeedback
      label={translate('basketManager.discounts')}
      type="single-select"
      options={options}
      values={[value?.code ?? '']}
      setValues={(newValue) => {
        if (!newValue) {
          return;
        }

        if (newValue[0]) {
          basketManager.selectPassengerDiscount({
            passengerId: passengerTicket.passengerId,
            tripKey,
            discountCode: newValue[0],
            callbacks: {
              onDiscountDataRequired: async () => {
                const response = await triggerActionDiscountModal({
                  itemId: passengerTicket.passengerId,
                });
                return response.responseData;
              },
              onLoyaltyDataRequired: async (options) => {
                const response = await triggerActionLoyaltyModal({
                  ...options,
                  itemId: passengerTicket.passengerId,
                });
                if (isErrorResponse(response) || !!response.responseData?.error) {
                  return;
                } else {
                  return response.responseData;
                }
              },
            },
          });
        }
      }}
    />
  );
};

export const SeatingTickets = ({
  tripKey,
  passenger,
  ccy,
}: {
  ccy: string;
  passenger: SelectTicketPassengerItem;
  tripKey: string;
}) => {
  const basketManager = useBasketManager();
  const triggerActionExtraInfoModal = useTicketsPricingPassengerExtraInfoStore()(
    (s) => s.triggerActionModal,
  );
  const triggerActionJoinPassengerModal = useTicketsPricingPassengerJoinPassengersStore()(
    (s) => s.triggerActionModal,
  );

  const options: SelectedValueItem[] = passenger.availableTickets
    .map((option) => {
      switch (option.type) {
        case 'break': {
          return undefined;
        }
        case 'cabin':
        case 'seat': {
          if (option.limitedAvailability === 0) {
            return undefined;
          }
          const limitedAvailability =
            option.limitedAvailability !== undefined
              ? `[${translate('basketManager.ticketsLeft')}: ${
                  option.limitedAvailability
                }] -`
              : '';
          const price = getPriceWithSymbol(option.value, ccy);

          return {
            value: option.code,
            label: `${limitedAvailability} ${option.label} - ${price} `,
          };
        }

        default:
          return undefined;
      }
    })
    .filter(notEmpty);

  const value = passenger.availableTickets.find((ticket) => {
    if (ticket.type !== 'break') {
      return ticket.code === passenger.selectedTicket.code;
    }
    return false;
  }) as SelectTicketEditPassengerAcc | undefined;
  return (
    <SelectInputBase
      feedback=""
      noFeedback
      label={translate('basketManager.seating')}
      type="single-select"
      options={options}
      values={[value?.code || options[0].value]}
      setValues={(newValue) => {
        if (!newValue) {
          return;
        }

        basketManager.selectPassengerTicket({
          passengerId: passenger.passengerId,
          tripKey,
          ticketCode: newValue[0],
          callBacks: {
            onPassengersRequired: async (passengerRequiredProps) => {
              const response = await triggerActionJoinPassengerModal({
                ...passengerRequiredProps,
                itemId: passenger.passengerId,
              });
              return response.responseData;
            },
            onAdditionalInfoRequired: async (infoRequiredProps) => {
              const response = await triggerActionExtraInfoModal({
                ...infoRequiredProps,
                itemId: passenger.passengerId,
              });
              return response.responseData;
            },
          },
        });
      }}
    />
  );
};
