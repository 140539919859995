import { createContext, useContext, useState } from 'react';

import { createLoyaltyToggleStore, LoyaltyToggleStore } from '../store/store.toggle';
import {
  createTicketsPricingPassengerDiscountStore,
  createTicketsPricingPassengerExtraInfoStore,
  createTicketsPricingPassengerJoinPassengersStore,
  createTicketsPricingPassengerLoyaltyStore,
} from './TicketPricing.Passenger';

export interface TicketPricingStore {
  loyaltyStore: LoyaltyToggleStore;
  modalExtraInfo: ReturnType<typeof createTicketsPricingPassengerExtraInfoStore>;
  modalJoinPassenger: ReturnType<typeof createTicketsPricingPassengerJoinPassengersStore>;
  modalLoyalty: ReturnType<typeof createTicketsPricingPassengerLoyaltyStore>;
  modalDiscount: ReturnType<typeof createTicketsPricingPassengerDiscountStore>;
}

const TicketPricingContext = createContext<TicketPricingStore>({
  loyaltyStore: {} as any,
  modalExtraInfo: {} as any,
  modalJoinPassenger: {} as any,
  modalLoyalty: {} as any,
  modalDiscount: {} as any,
});

export const TicketsPricingProvider = ({ children }: { children: React.ReactNode }) => {
  const [loyaltyStore] = useState(() => createLoyaltyToggleStore());
  const [modalExtraInfo] = useState(() => createTicketsPricingPassengerExtraInfoStore());
  const [modalJoinPassenger] = useState(() =>
    createTicketsPricingPassengerJoinPassengersStore(),
  );
  const [modalLoyalty] = useState(() => createTicketsPricingPassengerLoyaltyStore());
  const [modalDiscount] = useState(() => createTicketsPricingPassengerDiscountStore());
  return (
    <TicketPricingContext.Provider
      value={{
        loyaltyStore,
        modalExtraInfo,
        modalJoinPassenger,
        modalLoyalty,
        modalDiscount,
      }}
    >
      {children}
    </TicketPricingContext.Provider>
  );
};

export const useTicketsPricingPassengerLoyaltyStore = () => {
  return useContext(TicketPricingContext).modalLoyalty;
};

export const useTicketsPricingPassengerDiscountStore = () => {
  return useContext(TicketPricingContext).modalDiscount;
};

export const useTicketsPricingPassengerJoinPassengersStore = () => {
  return useContext(TicketPricingContext).modalJoinPassenger;
};

export const useTicketsPricingPassengerExtraInfoStore = () => {
  return useContext(TicketPricingContext).modalExtraInfo;
};

export const useLoyaltyToggleStore = () => {
  return useContext(TicketPricingContext).loyaltyStore;
};
