import { Icon, IconsId } from '@droplet-tech-code/core-elements/module/ui/Icon';
import { LoadingIndicator } from '@droplet-tech-code/core-elements/module/ui/Loading';
import { HStack, VStack } from '@droplet-tech-code/core-elements/module/ui/Stack';
import { Text } from '@droplet-tech-code/core-elements/module/ui/Text';
import { View } from '@droplet-tech-code/core-elements/module/ui/View';
import {
  BookingClientWithPurchasesAndTickets,
  PurchaseClientWithMetaWithTickets,
  TicketClient,
} from '@naus-code/naus-admin-types';

import { ExpandableCard } from '~/components/Card/ExpandableCard';
import { CompanyImage } from '~/screens/Configs/Company/CompanyConfig.ListItem';
import { bookingApi } from '~/screens/Support/Bookings/Bookings.api';

import { purchaseActionManager } from '../../Common/ActionPanel.utils';
import { ModifyPanelProps } from '../Modify.utils';
import {
  BookingModificationOffsetCharge,
  ModifyPurchaseExpandedManualForm,
  ModifyPurchaseManualForm,
} from './PurchaseForms/Modify.ManualTrip.purchaseEditForm';
import { ModifyTicketHeaderManualForm } from './TicketForms/Modify.ManualTrip.ticketHeaderEditForm';
import { ModifyTicketInfoManualForm } from './TicketForms/Modify.ManualTrip.ticketInfoEditForm';

export const ManualTripManager = (props: ModifyPanelProps) => {
  const { data, isLoading } = bookingApi.useGetBookingQuery(props.bookingId);

  if (isLoading) {
    return <LoadingIndicator />;
  }

  if (!data) {
    return null;
  }
  const { booking } = data;
  return (
    <VStack style={{ height: '100%' }}>
      <BookingInfo booking={booking} />
      <BookingModificationOffsetCharge booking={booking} />
    </VStack>
  );
};

const BookingInfo = ({ booking }: { booking: BookingClientWithPurchasesAndTickets }) => {
  const { purchases } = booking;

  return (
    <VStack space="2.5">
      {purchases.map((purchase) => {
        return (
          <View key={purchase._id}>
            <ManualModifyPurchaseInfo purchase={purchase} />
          </View>
        );
      })}
    </VStack>
  );
};

export const ManualModifyPurchaseInfo = ({
  purchase,
  defaultShowAllVessels,
}: {
  purchase: PurchaseClientWithMetaWithTickets;
  defaultShowAllVessels?: boolean;
}) => {
  const [selectedReservation] =
    purchaseActionManager.state.useGlobalState('selectedReservation');

  const canRenderPurchase = selectedReservation === purchase.companyReservationCode;
  if (!canRenderPurchase) {
    return null;
  }

  return (
    <ExpandableCard>
      <PurchaseHeader purchase={purchase} defaultShowAllVessels={defaultShowAllVessels} />
      <VStack>
        <ModifyPurchaseExpandedManualForm purchase={purchase} />
        <Tickets tickets={purchase.tickets} />
      </VStack>
    </ExpandableCard>
  );
};

const PurchaseHeader = ({
  purchase,
  defaultShowAllVessels,
}: {
  purchase: PurchaseClientWithMetaWithTickets;
  defaultShowAllVessels?: boolean;
}) => {
  const { operatorImgUrl, operator, companyId, vesselName } = purchase;

  return (
    <HStack p="1" space="2.5">
      <CompanyImage imageUrl={operatorImgUrl} />
      <HStack space="2.5" flex={1}>
        <VStack>
          <Text.ExtraSmall numberOfLines={1} color="monochrome-dark">
            {vesselName}
          </Text.ExtraSmall>
          <Text.ExtraSmall
            numberOfLines={1}
            color="monochrome-mid"
            style={{ overflow: 'hidden' }}
          >{`${operator} (${companyId})`}</Text.ExtraSmall>
        </VStack>
        <ModifyPurchaseManualForm
          purchase={purchase}
          defaultShowAllVessels={defaultShowAllVessels}
        />
      </HStack>
    </HStack>
  );
};

const Tickets = ({ tickets }: { tickets: TicketClient[] }) => {
  const [selectedTickets] = purchaseActionManager.state.useGlobalState('selectedTickets');

  return (
    <VStack p="2.5" space="2.5">
      {tickets.map((ticket) => {
        if (selectedTickets.find((selected) => selected === ticket._id)) {
          return <TicketInfo key={ticket._id} ticket={ticket} />;
        }
      })}
    </VStack>
  );
};

const TicketInfo = ({ ticket }: { ticket: TicketClient }) => {
  return (
    <ExpandableCard b={0} bg="monochrome-base">
      <TicketInfoHeader ticket={ticket} />
      <TicketInfoDetails ticket={ticket} />
    </ExpandableCard>
  );
};

const TicketInfoHeader = ({ ticket }: { ticket: TicketClient }) => {
  const { icon, ticketType } = getIconTicketType(ticket);

  return (
    <VStack>
      <HStack p="2.5">
        <Icon icon={icon} color="monochrome" />
        <Text.Body1Regular>{ticketType}</Text.Body1Regular>
      </HStack>
      <ModifyTicketHeaderManualForm ticket={ticket} />
    </VStack>
  );
};

const TicketInfoDetails = ({ ticket }: { ticket: TicketClient }) => {
  return <ModifyTicketInfoManualForm ticket={ticket} />;
};

function getIconTicketType(ticket: TicketClient): { icon: IconsId; ticketType: string } {
  const { ticketType } = ticket;
  switch (ticketType) {
    case 'Passenger':
      return {
        icon: 'passenger',
        ticketType,
      };
    case 'Vehicle':
      return {
        icon: 'vehicle',
        ticketType,
      };
    case 'Extra':
      return {
        icon: 'extra-plus',
        ticketType,
      };
    case 'Pet':
      return {
        icon: 'pet-paw',
        ticketType,
      };
  }
}
