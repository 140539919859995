import { Button, IconButton } from '@droplet-tech-code/core-elements/module/ui/Button';
import { DelayRender } from '@droplet-tech-code/core-elements/module/ui/Delay';
import { HStack, VStack } from '@droplet-tech-code/core-elements/module/ui/Stack';
import { Text } from '@droplet-tech-code/core-elements/module/ui/Text';
import { View } from '@droplet-tech-code/core-elements/module/ui/View';
import {
  RouteTrip,
  RouteTripNoDetails,
  SearchRouteMetaTrip,
} from '@naus-code/naus-client-types';
import React, { MutableRefObject } from 'react';

import { searchApi } from '~/components/Search/Search.api';
import { SearchTicketItem } from '~/components/Search/Search.List';
import { getTripInnerNotice } from '~/components/Search/Search.utils';
import { purchaseActionManager } from '~/screens/Support/Purchases/PurchaseActions/ActionPanel/Common/ActionPanel.utils';
import { SearchTripInfo } from '~/screens/Support/Purchases/PurchaseActions/ActionPanel/Modify/Modify.Search';
import {
  ModifyPanelProps,
  resetBasketManager,
  useModifyData,
} from '~/screens/Support/Purchases/PurchaseActions/ActionPanel/Modify/Modify.utils';
import { translate } from '~/utils/translation.utils';

export const TripInfo = ({
  route,
}: {
  route: RouteTripNoDetails | RouteTrip | SearchRouteMetaTrip;
}) => {
  const notice = getTripInnerNotice(route);
  const disabled = !!notice;
  return (
    <VStack
      style={[disabled && { opacity: 0.6 }]}
      p="3"
      bc="monochrome-light"
      b={1}
      br={12}
      space="2.5"
    >
      <SearchTicketItem trip={route} notice={notice} />
    </VStack>
  );
};

export const OriginalRequestedTrip = (
  props: ModifyPanelProps & { onEdit: (rt: SearchTripInfo) => void },
) => {
  const { initialRoutePrice, supportRequest, purchase } = useModifyData(props);
  const [manuallySelectedRoute] = purchaseActionManager.state.useGlobalState(
    'manuallySelectedRoute',
  );
  const tripKeys = initialRoutePrice?.trips.map((t) => t.key) ?? [];
  const ccy = initialRoutePrice?.ccy ?? 'EUR';
  const { data: tripDetails, isLoading } = searchApi.useGetTripDetailsQuery(
    {
      tripKeys,
      ccy,
    },
    { skip: !initialRoutePrice },
  );

  const originalRequestedTrip = tripDetails?.trips[0];

  const noModifiactionOptions = !hasValidModificationOptions(
    supportRequest?.requestOptions?.type,
  );

  if (noModifiactionOptions) {
    if (manuallySelectedRoute) {
      return null;
    }

    return (
      <VStack space="3">
        <Text.Small>
          {translate('supportRequests.requestOptions.noCustomerRequestOptions')}
        </Text.Small>
        <Button
          disabled={!purchase}
          variant="outline"
          onPress={() => {
            if (!purchase) {
              return;
            }

            props.onEdit({
              depDateTime: purchase.departureDateTime,
              destDisplay: purchase.destinationDisplay,
              destId: purchase.destination,
              originDisplay: purchase.originDisplay,
              originId: purchase.origin,
            });
          }}
        >
          {translate('supportRequests.requestOptions.manuallyChangeTrip')}
        </Button>
      </VStack>
    );
  }
  const IconAction = manuallySelectedRoute ? null : (
    <HStack justify="center" pl="2.5">
      <IconButton
        icon="edit"
        variant="plain"
        onPress={() => {
          props.onEdit({
            depDateTime: originalRequestedTrip?.depDateTime || '' || '',
            destDisplay: originalRequestedTrip?.destDisplay || '',
            destId: originalRequestedTrip?.dest || '',
            originDisplay: originalRequestedTrip?.originDisplay || '',
            originId: originalRequestedTrip?.origin || '',
          });
        }}
      />
    </HStack>
  );

  return (
    <DelayRender isLoading={isLoading}>
      {originalRequestedTrip ? (
        <VStack space="1">
          <Text.Body1Medium>
            {translate('supportRequests.requestOptions.requestOptions')}
          </Text.Body1Medium>
          <HStack space="1.5" align="center">
            <VStack
              space="1"
              flex={1}
              style={[!!manuallySelectedRoute && { opacity: 0.5 }]}
            >
              <Text.Small color="monochrome-dark">
                {translate('supportRequests.requestOptions.customerRequested')}
              </Text.Small>
              <HStack flex={1}>
                <View flex={1} />
                {IconAction}
              </HStack>
              <TripInfo route={originalRequestedTrip} />
            </VStack>
          </HStack>
        </VStack>
      ) : null}
    </DelayRender>
  );
};
export const ManualRequestedTrip = (
  props: ModifyPanelProps & {
    onEdit: (rt: SearchTripInfo) => void;
    selectRouteRef: MutableRefObject<RouteTripNoDetails | undefined>;
  },
) => {
  const [manuallySelectedRoute, setManualRoute] =
    purchaseActionManager.state.useGlobalState('manuallySelectedRoute');

  if (!manuallySelectedRoute) {
    return null;
  }

  return (
    <HStack space="1.5" align="center">
      <VStack space="1" flex={1}>
        <Text.Small color="monochrome-dark">
          {translate('purchases.purchaseTool.youHaveManuallySelected')}
        </Text.Small>
        <HStack space="1">
          <View flex={1} />
          <IconButton
            icon="close"
            color="error"
            onPress={() => {
              setManualRoute(undefined);
              resetBasketManager();
              props.selectRouteRef.current = undefined;
            }}
          />
          <IconButton
            icon="edit"
            onPress={() => {
              props.onEdit({
                depDateTime: manuallySelectedRoute.depDateTime,
                destDisplay: manuallySelectedRoute.destDisplay,
                destId: manuallySelectedRoute.dest,
                originDisplay: manuallySelectedRoute.originDisplay,
                originId: manuallySelectedRoute.origin,
              });
            }}
          />
        </HStack>
        <TripInfo route={manuallySelectedRoute} />
      </VStack>
    </HStack>
  );
};

function hasValidModificationOptions(modificationOptionsType: undefined | string) {
  if (
    modificationOptionsType === 'modify_v2' ||
    modificationOptionsType === 'modify_v3'
  ) {
    return true;
  }
  return false;
}
