import {
  BookingClientSendConfirmationRequest,
  BookingClientSendInvoiceRequest,
  BookingListByCustomerIdRequest,
  BookingListByEmailRequest,
  BookingListResponse,
  BookingRefundPaymentSessionRequest,
  GetBookingResponse,
} from '@naus-code/naus-admin-types';
import { PaginatedListRequest } from '@naus-code/naus-client-types';
import isEqual from 'lodash/isEqual';

import { rootApi } from '~/store/redux.utils';
import { mergeAndRemoveDuplicates } from '~/utils/utils.helper';

const base_url = 'booking';
export const bookingApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    getBookingListByEmail: builder.query<BookingListResponse, BookingListByEmailRequest>({
      query: (data) => ({
        url: `${base_url}/get-list-by-email/`,
        method: 'POST',
        data,
      }),
      serializeQueryArgs: ({ endpointName }) => {
        return endpointName;
      },
      merge: (currentCache, newItems) => {
        currentCache.isFinal = newItems.isFinal;
        currentCache.list = mergeAndRemoveDuplicates(currentCache.list, newItems.list);
      },
      // Refetch when the page arg changes
      forceRefetch({ currentArg, previousArg }) {
        return !isEqual(currentArg, previousArg);
      },
      providesTags: ['*', 'getBookingListByEmail'],
    }),

    invalidateBookingListByEmail: builder.mutation<
      BookingListResponse,
      BookingListByEmailRequest
    >({
      query: (data) => ({
        url: `${base_url}/get-list-by-email/`,
        method: 'POST',
        data,
      }),
      async onQueryStarted(req, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(
            bookingApi.util.updateQueryData('getBookingListByEmail', req, (draft) => {
              draft.isFinal = data.isFinal;
              draft.list = data.list;
            }),
          );
        } catch (err) {
          console.error(err);
        }
      },
    }),

    getBookingListByCustomer: builder.query<
      BookingListResponse,
      BookingListByCustomerIdRequest
    >({
      query: (data) => ({
        url: `${base_url}/get-list-by-customer-id/`,
        method: 'POST',
        data,
      }),
      serializeQueryArgs: ({ endpointName }) => {
        return endpointName;
      },
      merge: (currentCache, newItems) => {
        currentCache.isFinal = newItems.isFinal;
        currentCache.list = mergeAndRemoveDuplicates(currentCache.list, newItems.list);
      },
      // Refetch when the page arg changes
      forceRefetch({ currentArg, previousArg }) {
        return !isEqual(currentArg, previousArg);
      },
      providesTags: ['*', 'getBookingListByCustomer'],
    }),

    invalidateBookingListById: builder.mutation<
      BookingListResponse,
      BookingListByCustomerIdRequest
    >({
      query: (data) => ({
        url: `${base_url}/get-list-by-customer-id/`,
        method: 'POST',
        data,
      }),
      async onQueryStarted(req, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(
            bookingApi.util.updateQueryData('getBookingListByCustomer', req, (draft) => {
              draft.isFinal = data.isFinal;
              draft.list = data.list;
            }),
          );
        } catch (err) {
          console.error(err);
        }
      },
    }),

    getBooking: builder.query<GetBookingResponse, string>({
      query: (bookingId: string) => ({
        url: `${base_url}/get-booking/${bookingId}`,
        method: 'GET',
      }),
      providesTags: ['*', 'getBooking'],
    }),

    getBookingFromRef: builder.query<GetBookingResponse, string>({
      query: (bookingRef: string) => ({
        url: `${base_url}/get-booking-by-ref/${bookingRef}`,
        method: 'GET',
      }),
      providesTags: ['*', 'getBookingFromRef'],
    }),

    getRecentBookingList: builder.query<BookingListResponse, PaginatedListRequest>({
      query: (data) => ({
        url: `${base_url}/get-recent/`,
        method: 'POST',
        data,
      }),
      serializeQueryArgs: ({ endpointName }) => {
        return endpointName;
      },
      merge: (currentCache, newItems) => {
        currentCache.isFinal = newItems.isFinal;
        currentCache.list = mergeAndRemoveDuplicates(currentCache.list, newItems.list);
      },
      // Refetch when the page arg changes
      forceRefetch({ currentArg, previousArg }) {
        return !isEqual(currentArg, previousArg);
      },
      providesTags: ['*', 'getRecentBookingList'],
    }),

    invalidateGetRecentBookingList: builder.mutation<
      BookingListResponse,
      PaginatedListRequest
    >({
      query: (data) => ({
        url: `${base_url}/get-recent/`,
        method: 'POST',
        data,
      }),
      async onQueryStarted(req, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(
            bookingApi.util.updateQueryData('getRecentBookingList', req, (draft) => {
              draft.isFinal = data.isFinal;
              draft.list = data.list;
            }),
          );
        } catch (err) {
          console.error(err);
        }
      },
    }),
    // @Post('/send-confirmation/', [applyRoleValidation(['ADMIN', 'SYSTEM'])])
    // sendConfirmation(@Body() body: BookingClientSendConfirmationRequest): Promise<Succesfullres> {
    //     return this._bookingService.getRecentBookingList(body);
    // }
    sendConfirmation: builder.mutation<void, BookingClientSendConfirmationRequest>({
      query: (data) => ({
        url: `${base_url}/send-confirmation`,
        method: 'POST',
        data,
      }),
    }),

    sendInvoice: builder.mutation<void, BookingClientSendInvoiceRequest>({
      query: (data) => ({
        url: `${base_url}/send-invoice`,
        method: 'POST',
        data,
      }),
      invalidatesTags: ['*', 'getBooking', 'getBookingFromRef'],
    }),

    // @Post('/refund-payment/', [applyRoleValidation(['CUSTOMER_SUPPORT'])])
    // refundServiceCharge(@Body() body: BookingRefundServiceChargeRequest): Promise<Booking> {
    //     return this._bookingService.refundServiceCharge(body);
    // }

    refundPaymentSession: builder.mutation<void, BookingRefundPaymentSessionRequest>({
      query: (data) => ({
        url: `${base_url}/refund-payment`,
        method: 'POST',
        data,
      }),
      invalidatesTags: ['*', 'getBooking', 'getBookingFromRef'],
    }),
  }),
});
// @Post('/booking/get-recent/', [applyRoleValidation(['ADMIN', 'SYSTEM'])])
// getRecentBookingList(@Body() body: PaginatedListRequest): Promise<BookingListResponse> {
//     return this._bookingService.getRecentBookingList(body);
// }
