import {
  Modal,
  ModalCreateOptions,
} from '@droplet-tech-code/core-elements/module/ui/Modal';
import { modalSelector } from '@droplet-tech-code/core-elements/module/ui/Modal/Modal.selector';
import {
  AppViewProps,
  ScrollView,
} from '@droplet-tech-code/core-elements/module/ui/View';
import { ScrollViewProps } from 'react-native';

export const ModalScrollView = (props: ScrollViewProps) => {
  return (
    <Modal.Body flex={1}>
      <ScrollView {...props} />
    </Modal.Body>
  );
};

export const StateModal = (
  props: AppViewProps & { isOpen: boolean; closeModal: () => void },
) => {
  const modal = props.children;
  const options = {
    key: 'StateModal',
    type: 'action-sheet-full',
    modalStyle: { overrideBackdrop: true },
  } as ModalCreateOptions;
  if (!props.isOpen) {
    return null;
  }

  return modalSelector.selectModalBasedOnOptions(
    modal,
    () => {
      props.closeModal();
    },
    options,
  );
};
