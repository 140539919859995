import { ConfigForData, SelectedValueItem } from '@droplet-tech-code/core-common-types';
import {
  FormBuilder,
  FormHandler,
} from '@droplet-tech-code/core-elements/module/ui/Inputs/Form';
import { TicketClient } from '@naus-code/naus-admin-types';
import { useEffect, useRef } from 'react';

import { systemApi } from '~/utils/system.api';

import { useManualStore } from '../ManualProvider/Modify.ManualTrip.Provider';
import { generateModifyBaseTicketInfoManualForm } from './Modify.ManualTrip.baseTicketInfoEditForm';
import { generateModifyPassengerTicketInfoManualForm } from './Modify.ManualTrip.passengerTicketInfoEditForm';
import { generateModifyPetTicketInfoManualForm } from './Modify.ManualTrip.petTicketInfoEditForm';
import { generateModifyVehicleTicketInfoManualForm } from './Modify.ManualTrip.vehicleTicketInfoEditForm';

export const ModifyTicketInfoManualForm = ({ ticket }: { ticket: TicketClient }) => {
  const { data: countriesData } = systemApi.useGetCountriesListQuery();

  const formRef = useRef(new FormHandler());

  const manualModStore = useManualStore();
  const addForm = manualModStore((s) => s.addForm);

  useEffect(() => {
    addForm({
      form: formRef.current,
      purchaseId: ticket._purchaseId,
      ticketId: ticket._id,
    });
  }, []);

  if (!countriesData) {
    return null;
  }

  const countries = countriesData.countries.map((country) => {
    return {
      label: country.name['en'],
      value: country.code,
    };
  });

  const config = getTicketInfoConfig(ticket, countries);
  if (!config) {
    return null;
  }

  return <FormBuilder data={config} form={formRef.current} />;
};

function getTicketInfoConfig(
  ticket: TicketClient,
  countries: SelectedValueItem[],
): ConfigForData {
  const baseConfig = generateModifyBaseTicketInfoManualForm(ticket);

  switch (ticket.ticketType) {
    case 'Passenger':
      return [
        ...generateModifyPassengerTicketInfoManualForm(ticket, countries),
        ...baseConfig,
      ];
    case 'Vehicle':
      return [
        ...generateModifyVehicleTicketInfoManualForm(ticket, countries),
        ...baseConfig,
      ];
    case 'Pet':
      return [...generateModifyPetTicketInfoManualForm(ticket), ...baseConfig];
    default:
      return baseConfig;
  }
}
