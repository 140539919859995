import { ConfigForData } from '@droplet-tech-code/core-common-types';
import {
  FormBuilder,
  FormHandler,
} from '@droplet-tech-code/core-elements/module/ui/Inputs/Form';
import { Modal, useModal } from '@droplet-tech-code/core-elements/module/ui/Modal';
import { Text } from '@droplet-tech-code/core-elements/module/ui/Text';
import { View } from '@droplet-tech-code/core-elements/module/ui/View';
import { handleResponse } from '@droplet-tech-code/core-elements/module/utils/error';
import { useRef } from 'react';

import { translate } from '~/utils/translation.utils';

import { FormContent } from '../Form/Form.Container';
import { ModalScrollView } from './Modal.utils';

export const FormModalKey = 'FormModal';
const FormModal = <T, R>({
  modalTitle,
  onConfirm,
  useFormHookHandler,
  actionHook,
  keepOpenOnError,
}: {
  modalTitle: string;
  onConfirm: ({ action, value, check }: OnConfirmType<T, R>) => Promise<R>;
  useFormHookHandler: () => {
    data?: { config?: ConfigForData; check?: string };
    isLoading: boolean;
  };
  actionHook?: () => [action: (req: T) => R, { isLoading: boolean }];
  keepOpenOnError?: boolean;
}) => {
  const { closeModal } = useModal();
  const { data, isLoading: loadingForm } = useFormHookHandler();
  const formRef = useRef(new FormHandler());

  const [action, { isLoading: loadingAction }] = actionHook
    ? actionHook()
    : [undefined, { isLoading: false }];

  return (
    <Modal.Container>
      <Modal.Header
        title={modalTitle}
        divider
        onPress={() => {
          closeModal(FormModalKey);
        }}
      />
      <ModalScrollView>
        <FormComponent
          form={formRef.current}
          configForm={data?.config}
          isLoading={loadingForm}
        />
      </ModalScrollView>
      <Modal.Footer
        divider
        isLoading={loadingAction}
        onConfirm={async () => {
          const { valid, value } = formRef.current.getValue();

          if (valid) {
            const response = await onConfirm({
              action: action,
              value,
              check: data?.check,
            });
            handleResponse({
              response,
              onSuccess: () => {
                closeModal(FormModalKey);
              },
              onError: () => {
                if (!keepOpenOnError) {
                  closeModal(FormModalKey);
                }
              },
            });
          }
        }}
        onCancel={() => {
          closeModal(FormModalKey);
        }}
      />
    </Modal.Container>
  );
};

export const useFormModal = () => {
  const { openModal, closeModal } = useModal();

  return {
    open: <T, R>({
      modalTitle,
      onConfirm,
      useFormHookHandler,
      actionHook,
      keepOpenOnError,
      key,
    }: {
      modalTitle: string;
      onConfirm: ({ action, value, check }: OnConfirmType<T, R>) => Promise<R>;
      useFormHookHandler: () => {
        data?: { config?: ConfigForData; check?: string };
        isLoading: boolean;
      };
      actionHook?: any;
      keepOpenOnError?: boolean;
      key?: string;
    }) => {
      openModal(
        <FormModal
          modalTitle={modalTitle}
          onConfirm={onConfirm}
          actionHook={actionHook}
          useFormHookHandler={useFormHookHandler}
          keepOpenOnError={keepOpenOnError}
        />,
        {
          key: key || FormModalKey,
          type: 'action-sheet-full',
        },
      );
    },
    close: () => {
      closeModal(FormModalKey);
    },
  };
};

const FormComponent = ({
  form,
  configForm,
  isLoading,
}: {
  form: FormHandler;
  isLoading: boolean;
  configForm?: ConfigForData;
}) => {
  return (
    <FormContent isLoading={isLoading} form={configForm} flex={1}>
      {configForm ? (
        <FormBuilder data={configForm} form={form} />
      ) : (
        <View h={10}>
          <Text.Body1Medium>{translate('feedback.emptyData')}</Text.Body1Medium>
        </View>
      )}
    </FormContent>
  );
};

interface OnConfirmType<T, R> {
  action?: (req: T) => R;
  value: any;
  check?: string;
}
