import { isErrorResponse } from '@droplet-tech-code/core-elements/module/utils/error';
import { FetchManager } from '@droplet-tech-code/core-elements/module/utils/network';
import {
  PriceClientVerifyLoyaltyRequest,
  PriceClientVerifyLoyaltyResponseV2,
  PriceClientVerifyRoutePriceModifyRequestV2,
  PriceClientVerifyRoutePriceResponseV2,
  RoutePriceV2,
} from '@naus-code/naus-client-types';

export const getVerifyLoyaltyApi = (fetchManager: FetchManager) => {
  return async (data: PriceClientVerifyLoyaltyRequest) => {
    const response = await fetchManager.request<PriceClientVerifyLoyaltyResponseV2>({
      endPoint: 'flow/price/verify-loyalty/v1',
      method: 'POST',
      data,
    });
    if (isErrorResponse(response)) {
      throw response;
    }
    return response;
  };
};

export const getVerifyPriceApi = (fetchManager: FetchManager) => {
  return async (
    routePrice: RoutePriceV2,
  ): Promise<PriceClientVerifyRoutePriceResponseV2> => {
    const response = await fetchManager.request<PriceClientVerifyRoutePriceResponseV2>({
      method: 'POST',
      endPoint: 'flow/price/verify/v3',
      data: {
        route: routePrice,
      },
    });
    if (isErrorResponse(response)) {
      throw response;
    }
    return response;
  };
};

export const getVerifyPriceModifyApi = (fetchManager: FetchManager) => {
  return async (
    data: PriceClientVerifyRoutePriceModifyRequestV2,
  ): Promise<PriceClientVerifyRoutePriceResponseV2> => {
    const response = await fetchManager.request<PriceClientVerifyRoutePriceResponseV2>({
      method: 'POST',
      endPoint: 'flow/price/verify-modify/v2/',
      data,
    });
    if (isErrorResponse(response)) {
      throw response;
    }
    return response;
  };
};
