import { IconButton } from '@droplet-tech-code/core-elements/module/ui/Button';
import {
  FormBuilder,
  FormHandler,
} from '@droplet-tech-code/core-elements/module/ui/Inputs/Form';
import { Modal, useModal } from '@droplet-tech-code/core-elements/module/ui/Modal';
import { HStack } from '@droplet-tech-code/core-elements/module/ui/Stack';
import { View } from '@droplet-tech-code/core-elements/module/ui/View';
import {
  BookingClientWithPurchasesAndTickets,
  SupportRequestBackendClient,
  SupportRequestCreateChargeRequest,
} from '@naus-code/naus-admin-types';
import { useRef } from 'react';
import { useConversionTool } from '~/components/CurrencyConversion/CurrencyConversionTool';

import { FormContent } from '~/components/Form/Form.Container';
import { supportRequestActionsApi } from '~/screens/Support/Requests/supportRequestActions.api';
import { handleRTKResponse } from '~/utils/network.utils';
import { translate } from '~/utils/translation.utils';

export const CreatePaymentChargeKey = 'AddModal-CreatePayment';
export const CreatePaymentChargeModal = ({
  supportRequestInfo,
  booking,
  check,
}: {
  supportRequestInfo: SupportRequestBackendClient;
  booking: BookingClientWithPurchasesAndTickets;
  check: string;
}) => {
  console.log('Env: ', process.env);
  const form = useRef(new FormHandler());
  const { closeModal } = useModal();

  const supportId = supportRequestInfo._id;
  const { open: openConversionTool } = useConversionTool();

  const selectedReservation = supportRequestInfo.purchaseId;

  const companyCcy = booking.purchases.find(
    (purchase) => purchase._id === selectedReservation,
  )?.companyCcy;

  const [createPendingPaymentCharge, { isLoading: isCreatingPaymentCharge }] =
    supportRequestActionsApi.useCreatePendingPaymentChargeMutation();

  return (
    <Modal.Container>
      <HStack>
        <Modal.Header
          title={translate('supportRequests.createPayment')}
          subtitle={translate('supportRequests.enterPaymentDetails')}
          onPress={() => {
            closeModal(CreatePaymentChargeKey);
          }}
        />
        <View flex={1} />
        <View p="2.5">
          <IconButton
            icon="transactions"
            color="monochrome"
            onPress={() => {
              openConversionTool({
                fromCurrency: companyCcy,
                toCurrency: booking.paymentSessions[0].paymentSummary.currency,
              });
            }}
          />
        </View>
      </HStack>
      <Modal.Body p="0">
        <CreatePaymentForm supportId={supportId} form={form.current} />
      </Modal.Body>
      <Modal.Footer
        divider
        isLoading={isCreatingPaymentCharge}
        onConfirm={async () => {
          const { valid, value } =
            form.current.getValue<SupportRequestCreateChargeRequest>();

          if (valid) {
            const response = await createPendingPaymentCharge({
              ...value,
              value: (value.value ?? 0) * 100,
              serviceFee: (value.serviceFee ?? 0) * 100,
              supportId,
              check,
            });
            handleRTKResponse({
              response,
              onSuccess: () => {
                closeModal(CreatePaymentChargeKey);
              },
            });
          }
        }}
        onCancel={() => {
          closeModal(CreatePaymentChargeKey);
        }}
      />
    </Modal.Container>
  );
};

export const useCreatePaymentCharge = () => {
  const { openModal } = useModal();

  return {
    openModal: ({
      supportRequestInfo,
      booking,
      check,
    }: {
      supportRequestInfo: SupportRequestBackendClient;
      booking: BookingClientWithPurchasesAndTickets;
      check: string;
    }) => {
      openModal(
        <CreatePaymentChargeModal
          booking={booking}
          supportRequestInfo={supportRequestInfo}
          check={check}
        />,
        {
          key: CreatePaymentChargeKey,
          type: 'action-sheet-full',
        },
      );
    },
  };
};

const CreatePaymentForm = ({
  supportId,
  form,
}: {
  supportId: string;
  form: FormHandler;
}) => {
  const { data: paymentCreateConfig, isLoading: isLoadingPaymentConfig } =
    supportRequestActionsApi.useCreatePaymentChargeFormConfigQuery(supportId);

  return (
    <FormContent isLoading={isLoadingPaymentConfig} form={paymentCreateConfig} flex={1}>
      {paymentCreateConfig ? (
        <FormBuilder data={paymentCreateConfig} form={form} />
      ) : null}
    </FormContent>
  );
};
