import { ConfigForData } from '@droplet-tech-code/core-common-types';
import {
  ConvertCcyRequest,
  ConvertCcyResponse,
  GetCountriesResponse,
  GetCurrenciesResponse,
  SystemClientCreateSearchJobRequest,
  SystemClientGetSearchJobResponse,
  SystemClientGetSearchJobsResponse,
} from '@naus-code/naus-admin-types';

import { rootApi } from '~/store/redux.utils';

const base_url = 'system';
export const systemApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    getCountriesList: builder.query<GetCountriesResponse, void>({
      query: () => ({
        url: `${base_url}/get-countries/`,
        method: 'GET',
      }),
      providesTags: ['*', 'getCountries'],
    }),

    getCurrencies: builder.query<GetCurrenciesResponse, void>({
      query: () => ({
        url: `${base_url}/get-currencies/`,
        method: 'GET',
      }),
      providesTags: ['*', 'getCurrencies'],
    }),

    getConversions: builder.query<GetCurrenciesResponse, string>({
      query: (baseCcy: string) => ({
        url: `${base_url}/get-conversions/${baseCcy}`,
        method: 'GET',
      }),
      providesTags: ['*', 'getConversions'],
    }),

    convertCcy: builder.mutation<ConvertCcyResponse, ConvertCcyRequest>({
      query: (data) => ({
        url: `${base_url}/convert-ccy/`,
        method: 'Post',
        data,
      }),
      invalidatesTags: [],
    }),

    getMetaSearchJobs: builder.query<SystemClientGetSearchJobsResponse, void>({
      query: () => ({
        url: `${base_url}/search-job-list/v1`,
        method: 'GET',
      }),
      providesTags: ['*', 'getMetaSearchJobs'],
    }),

    createMetaSearchJobFormConfig: builder.query<ConfigForData, void>({
      query: () => ({
        url: `${base_url}/form-config/search-jobs-create/v1`,
        method: 'GET',
      }),
      providesTags: ['*', 'createMetaSearchJobFormConfig'],
    }),

    createMetaSearchJob: builder.mutation<
      SystemClientGetSearchJobResponse,
      SystemClientCreateSearchJobRequest
    >({
      query: (data) => ({
        url: `${base_url}/search-jobs-create/v1`,
        method: 'POST',
        data,
      }),

      invalidatesTags: ['getMetaSearchJobs'],
    }),

    cancelMetaSearchJob: builder.mutation<SystemClientGetSearchJobResponse, string>({
      query: (id: string) => ({
        url: `${base_url}/search-jobs-cancel/v1/${id}`,
        method: 'Get',
      }),
      invalidatesTags: ['getMetaSearchJobs'],
    }),

    sendMissingVesselsEmail: builder.query<SuccessResponse, void>({
      query: () => ({
        url: `${base_url}/send-email-missing-vessels/v1`,
        method: 'GET',
      }),
      providesTags: ['*', 'sendMissingVesselsEmail'],
    }),
  }),
});

export interface SuccessResponse {
  success: true;
}
