import { RouteTrip, RouteTripNoDetails } from '@naus-code/naus-client-types';
import React from 'react';

import { usePassengerPricing } from '../../passenger/passenger.pricing.hooks';
import { TicketsPricingPassenger } from './TicketsPricingPassenger';

export interface TicketPricingTripItemProps {
  tripIndex: number;
  trip: RouteTrip | RouteTripNoDetails;
}
export interface TicketsPricingPassengerItem extends TicketPricingTripItemProps {
  passengerIndex: number;
  passengerId: string;
}

export const PassengerList = (props: TicketPricingTripItemProps) => {
  const passengerIndexList = usePassengerPricing.passengerListByIdAndIndex({
    tripIndex: props.tripIndex,
  });
  return passengerIndexList.map((passenger) => {
    return (
      <React.Fragment key={passenger.passengerId}>
        <TicketsPricingPassenger key={passenger.passengerId} {...props} {...passenger} />
      </React.Fragment>
    );
  });
};

// const VehicleList = (props: TicketPricingTripItemProps) => {
//   const vehicleIndexList = useVehiclePricing.vehicleListByIdAndIndex({
//     tripIndex: props.tripIndex,
//   });
//   return vehicleIndexList.map((vehicle) => {
//     return (
//       <React.Fragment key={vehicle.vehicleId}>
//         <ContentDivider />
//         <TicketsPricingVehicle key={vehicle.vehicleId} {...props} {...vehicle} />
//       </React.Fragment>
//     );
//   });
// };

// const PetList = (props: TicketPricingTripItemProps) => {
//   const petIndexList = usePetPricing.petListByIdAndIndex({
//     tripIndex: props.tripIndex,
//   });
//   return petIndexList.map((pet) => {
//     return (
//       <React.Fragment key={pet.petIndex}>
//         <ContentDivider />
//         <TicketsPricingPet key={pet.petIndex} {...props} {...pet} />
//       </React.Fragment>
//     );
//   });
// };
