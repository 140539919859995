import { ButtonInteractionState } from '@droplet-tech-code/core-elements/module/ui/Button';
import { Icon } from '@droplet-tech-code/core-elements/module/ui/Icon';
import { HStack, VStack } from '@droplet-tech-code/core-elements/module/ui/Stack';
import { AppViewProps, View } from '@droplet-tech-code/core-elements/module/ui/View';
import { isNativeMobile } from '@droplet-tech-code/core-elements/module/utils/utils.helper';
import { useState } from 'react';
import { Pressable, TouchableOpacity } from 'react-native';

export const ExpandableCard = (props: ExpandableCardProps) => {
  const [expanded, setExpanded] = useState(false);

  // useEffect(() => {
  //   //render all the components once
  //   setExpanded(false);
  // }, []);

  return (
    <>
      {isNativeMobile ? (
        <TouchableOpacity
          onPress={props.onPress}
          disabled={props.isDisabled || props.isLoading}
        >
          <View
            justify="center"
            br={5}
            b={1}
            bc="monochrome-light"
            style={[props.style]}
            {...props}
          >
            {props.children}
          </View>
        </TouchableOpacity>
      ) : (
        <HStack
          justify="center"
          bg="white"
          br={25}
          h={26}
          b={1}
          bc="monochrome-light"
          style={[props.style, { overflow: 'hidden' }]}
          flex={1}
          {...props}
        >
          <VStack flex={1} style={{ opacity: props.isLoading ? 0 : 1 }}>
            <VStack p="1" space="2.5">
              {props.children[0]}
              <View style={{ height: expanded ? '100%' : 0 }}>{props.children[1]}</View>
            </VStack>
            <Pressable
              onPress={() => {
                setExpanded(!expanded);
                if (props.onPress) {
                  props.onPress();
                }
              }}
              disabled={props.isDisabled || props.isLoading}
            >
              {({ hovered }: ButtonInteractionState) => {
                return (
                  <View bg={hovered ? 'primary-base' : props.bg || 'white'}>
                    <Icon icon={expanded ? 'chevron-up' : 'chevron-down'} />
                  </View>
                );
              }}
            </Pressable>
          </VStack>
        </HStack>
      )}
    </>
  );
};

interface ExpandableCardProps extends AppViewProps {
  onPress?: () => void;
  isLoading?: boolean;
  isDisabled?: boolean;
  children: [JSX.Element, JSX.Element];
}
