import { useState } from 'react';

import { PageContainer, ScreenTwoPageLayout } from '~/components/Page/Page.Container';
import { MetaSearchJobList } from './MetaSearchJob.list';
import { MetaSearchJobItem } from './MetaSearchJobs.item';
import { ScreenPropMetaSearchJobs } from '~/navigation/Navigation.types';
import { MetaSearchJobClient } from '@naus-code/naus-admin-types';

export const MetaSearchJobsScreen = (props: ScreenPropMetaSearchJobs) => {
  return (
    <PageContainer>
      <MetaSearchJobLayout {...props} />
    </PageContainer>
  );
};

export const MetaSearchJobLayout = (props: ScreenPropMetaSearchJobs) => {
  const [metaSearchJob, setMetaSearchJob] = useState<MetaSearchJobClient | undefined>(
    props.route.params?.metaSearchJob,
  );

  return (
    <ScreenTwoPageLayout>
      <MetaSearchJobList
        onPress={(metaSearchJob) => setMetaSearchJob(metaSearchJob)}
        id={metaSearchJob?.id}
      />
      {metaSearchJob ? (
        <MetaSearchJobItem
          metaSearchJob={metaSearchJob}
          onClose={() => setMetaSearchJob(undefined)}
        />
      ) : (
        <></>
      )}
    </ScreenTwoPageLayout>
  );
};
