import { FormHandler } from '@droplet-tech-code/core-elements/module/ui/Inputs/Form';
import {
  PurchaseClientWithClientTickets,
  PurchaseClientWithMetaWithTickets,
  TicketClient,
} from '@naus-code/naus-admin-types';

import { onSubmitValue } from '~/components/SupportRequest/utils/utils';

export function getBasePurchaseToUpdate(
  purchase: PurchaseClientWithMetaWithTickets,
  purchaseForms: FormHandler[],
) {
  let validFlag = true;
  let purchaseFormValues = {} as PurchaseFormData;
  for (const purchaseForm of purchaseForms) {
    const { value, valid } = purchaseForm.getValue<PurchaseFormData>();
    if (valid) {
      purchaseFormValues = { ...purchaseFormValues, ...value };
    } else {
      validFlag = false;
    }
  }
  if (validFlag) {
    const basePurchase = {
      ...purchase,
      tickets: [] as TicketClient[],
      origin: purchaseFormValues.origin,
      destination: purchaseFormValues.destination,
      vesselId: purchaseFormValues.vesselId,
      valueCcy: onSubmitValue(purchaseFormValues.valueCcy),
      valueCompanyCcy: onSubmitValue(purchaseFormValues.valueCompanyCcy),
      departureDateTime: purchaseFormValues.departureDateTime,
      arrivalDateTime: purchaseFormValues.arrivalDateTime,
      providerReservationCode: purchaseFormValues.providerReservationCode,
      companyReservationCode: purchaseFormValues.companyReservationCode,
      companyAccessCode: purchaseFormValues.companyAccessCode,
      history: [],
    };
    if (purchase.purchaseProductType === 'FERRY') {
      const ferryPurchase = purchase as PurchaseClientWithMetaWithTickets & {
        providerVesselName: string;
      };
      return {
        ...basePurchase,
        providerVesselName: ferryPurchase.providerVesselName,
      } as PurchaseClientWithClientTickets;
    }
    if (purchase.purchaseProductType === 'FLIGHT') {
      const flightPurchase = purchase as PurchaseClientWithMetaWithTickets & {
        providerAircraftName: string;
        bookingConfirmation: string;
        flightCode: string;
      };
      return {
        ...basePurchase,
        providerAircraftName: flightPurchase.providerAircraftName,
        bookingConfirmation: flightPurchase.bookingConfirmation,
        flightCode: flightPurchase.flightCode,
      } as PurchaseClientWithClientTickets;
    }
  }
}

export interface PurchaseFormData {
  origin: string;
  destination: string;
  vesselId: string;
  valueCcy: number;
  valueCompanyCcy: number;
  departureDateTime: string;
  arrivalDateTime: string;
  providerReservationCode: string;
  companyReservationCode: string;
  companyAccessCode: string;
}
