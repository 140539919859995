import {
  FormBuilder,
  FormHandler,
} from '@droplet-tech-code/core-elements/module/ui/Inputs/Form';
import { Modal, useModal } from '@droplet-tech-code/core-elements/module/ui/Modal';
import {
  PasDetailsUpdatePriceReq,
  PassengerFieldNames,
} from '@naus-code/naus-basket-manager';
import { useRef } from 'react';

import { useBasketManager } from '~/components/BasketManager/BasketManager.Provider';
import { fieldInputsToFormBuilder } from '~/components/BasketManager/BasketManager.utils';
import { usePassengerPricing } from '~/components/BasketManager/passenger/passenger.pricing.hooks';

import { useTicketsPricingPassengerExtraInfoStore } from '../../ticketPricing/TicketPricing.Provider';
import { TicketsPricingPassengerItem } from '../../ticketPricing/TicketsPricing.TripItem';

const ExtraInfoModalKey = 'ExtraInfoModalKey';

export const TicketsPricingPassengerExtraInfoModal = (
  props: TicketsPricingPassengerItem,
) => {
  const { closeModal } = useModal();
  const extraDataForm = useRef(new FormHandler());
  const extraDataRequired = usePassengerPricing.requiresExtraData({
    tripIndex: props.tripIndex,
    passengerIndex: props.passengerIndex,
  });
  const basketManager = useBasketManager();
  const modalState = useTicketsPricingPassengerExtraInfoStore()((s) => s);
  const extraInfoRequiredFields = modalState.requestData?.fields;

  if (
    !extraDataRequired ||
    !extraInfoRequiredFields ||
    modalState.requestData?.itemId !== props.passengerId
  ) {
    return null;
  }

  const formData = fieldInputsToFormBuilder(extraInfoRequiredFields);

  return (
    <Modal.Container>
      <Modal.Body>
        <FormBuilder data={formData} form={extraDataForm.current} />
      </Modal.Body>
      <Modal.Footer
        onCancel={() => closeModal(ExtraInfoModalKey)}
        onConfirm={() => {
          const data: PasDetailsUpdatePriceReq['data'] = extraInfoRequiredFields.map(
            (field) => {
              const { value: formValue } = extraDataForm.current.getValue<any>();
              const value = formValue[field.fieldId];
              return {
                fieldId: field.fieldId as PassengerFieldNames,
                value,
              };
            },
          );
          basketManager.updatePassengerDetailsForPrice({
            passengerId: props.passengerId,
            data,
          });

          closeModal(ExtraInfoModalKey);
        }}
      ></Modal.Footer>
    </Modal.Container>
  );
};

export const useExtraInfoModal = () => {
  const { openModal } = useModal();

  return {
    open: (props: TicketsPricingPassengerItem) => {
      openModal(<TicketsPricingPassengerExtraInfoModal {...props} />, {
        key: ExtraInfoModalKey,
        type: 'action-sheet-full',
      });
    },
  };
};
