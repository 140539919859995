import { create } from 'zustand';

export interface ModalStateProps {
  disabled?: boolean;
  defaultOpen?: boolean;
  interceptSetOpen?: (isOpen: boolean) => boolean;
}
export type ModalState = {
  readonly isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  open: () => void;
  close: () => void;
  toggle: () => void;
};

export interface ActionResponseData<X> {
  responseData?: X;
  status: 'cancelled' | 'success';
}

export type ActionModalState<T, X> = ModalState & {
  requestData?: T;
  onDone?: (responseData: ActionResponseData<X>) => void;
  triggerActionModal: (_: T) => Promise<ActionResponseData<X>>;
};

export const createGlobalActionModalState = <T, X>(requestData?: T) =>
  create<ActionModalState<T, X>>((set) => {
    const closeModal = (s: ActionModalState<T, X>) => {
      s.onDone?.({
        responseData: undefined,
        status: 'cancelled',
      });
      return {
        isOpen: false,
        requestData: undefined,
        onDone: undefined,
      };
    };

    return {
      requestData,
      isOpen: false,
      open() {
        set({
          isOpen: true,
        });
      },
      close() {
        set((s) => closeModal(s));
      },
      setIsOpen(isOpen) {
        set((s) => {
          return isOpen ? { isOpen } : closeModal(s);
        });
      },
      toggle() {
        set((s) => {
          return s.isOpen ? closeModal(s) : { isOpen: true };
        });
      },
      async triggerActionModal(requestData) {
        const response = await new Promise<ActionResponseData<X>>((resolve) => {
          set({
            isOpen: true,
            requestData,
            onDone: (res) => {
              resolve(res);
            },
          });
        });
        set({ isOpen: false, requestData: undefined, onDone: undefined });
        return response;
      },
    };
  });
