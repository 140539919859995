import { UpdateAdminUserRulesRequest } from '@naus-code/naus-admin-types';
import { useCallback } from 'react';

import { useFormModal } from '~/components/Modals/FormModal';
import { UpdateAdminUserRulesResponse, usersApi } from '~/screens/Users/Users.api';
import { translate } from '~/utils/translation.utils';

export const useUpdateAdminUserRulesModal = (adminUserId: string) => {
  const { open: openForm, close: closeForm } = useFormModal();

  const useFormHookHandler = useCallback(() => {
    const { data: config, isLoading } =
      usersApi.useGetUserRulesConfigUpdateQuery(adminUserId);
    return { data: { config }, isLoading };
  }, [adminUserId]);

  const onConfirm = async ({
    action,
    value,
  }: {
    action: (req: UpdateAdminUserRulesRequest) => UpdateAdminUserRulesResponse;
    value: UpdateAdminUserRulesResponse['adminUser']['rules']['userRules'];
  }) => {
    return await action({
      adminUser: { _id: adminUserId, rules: { userRules: { ...value } } },
    });
  };

  return {
    open: () => {
      openForm({
        modalTitle: translate('users.updateUserRules'),
        useFormHookHandler,
        onConfirm,
        actionHook: usersApi.useUpdateUserRulesMutation,
      });
    },
    close: () => {
      closeForm();
    },
  };
};
