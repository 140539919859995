import { SelectedValueItem } from '@droplet-tech-code/core-common-types';
import { Icon } from '@droplet-tech-code/core-elements/module/ui/Icon';
import { LoadingIndicator } from '@droplet-tech-code/core-elements/module/ui/Loading';
import { Modal, useModal } from '@droplet-tech-code/core-elements/module/ui/Modal';
import { HStack, VStack } from '@droplet-tech-code/core-elements/module/ui/Stack';
import { useRef, useState } from 'react';
import { systemApi } from '~/utils/system.api';
import { translate } from '~/utils/translation.utils';
import { FormHandler } from '@droplet-tech-code/core-elements/module/ui/Inputs/Form';
import { handleResponse } from '@droplet-tech-code/core-elements/module/utils/error';
import {
  FromConversionComponent,
  ToConversionComponent,
} from './CurrencyConversionTool.utils';
import { onSubmitValue } from '../SupportRequest/utils/utils';
import { ConvertCcyResponse } from '@naus-code/naus-admin-types';

interface ConversionToolProps {
  value?: number;
  fromCurrency?: string;
  toCurrency?: string;
}

const ConversionToolModalKey = 'Conversion-Tool-Modal';

export const ConversionTool = ({
  value,
  fromCurrency,
  toCurrency,
}: ConversionToolProps) => {
  const { closeModal } = useModal();

  const valueRef = useRef(new FormHandler());

  const [selectedFromCurrency, setSelectedFromCurrency] = useState<undefined | string>(
    fromCurrency,
  );
  const [selectedToCurrency, setSelectedToCurrency] = useState<undefined | string>(
    toCurrency,
  );
  const [conversion, setConversion] = useState<undefined | ConvertCcyResponse>();

  const [convertCcy, { isLoading: isConverting }] = systemApi.useConvertCcyMutation();

  const { data, isLoading: isLoadingCurrencies } = systemApi.useGetCurrenciesQuery(
    undefined,
    { skip: !!fromCurrency },
  );

  let conversions = [] as SelectedValueItem[];

  const { data: conversionsData } = systemApi.useGetConversionsQuery(
    selectedFromCurrency || '',
    {
      skip: !selectedFromCurrency,
    },
  );

  if (conversionsData) {
    conversions =
      conversionsData.currencies.map((ccy) => ({
        label: ccy.symbol + ccy.ccy,
        value: ccy.ccy,
      })) || ([] as SelectedValueItem[]);
  }

  if (isLoadingCurrencies) {
    return <LoadingIndicator />;
  }

  const acceptedCurrencies =
    data?.currencies.map((ccy) => ({ label: ccy.symbol + ccy.ccy, value: ccy.ccy })) ||
    ([] as SelectedValueItem[]);

  return (
    <VStack>
      <HStack space="2.5" justify="center" p="2.5" align="stretch">
        <FromConversionComponent
          label={translate('conversionTool.from')}
          currency={fromCurrency}
          selected={selectedFromCurrency}
          currencyList={acceptedCurrencies}
          setCurrency={setSelectedFromCurrency}
          value={value}
          valueRef={valueRef}
        />
        <Icon icon={'chevron-right'} color="monochrome" />
        <ToConversionComponent
          label={translate('conversionTool.to')}
          currency={toCurrency}
          selected={selectedToCurrency}
          currencyList={conversions}
          setCurrency={setSelectedToCurrency}
          value={conversion?.convertedValue}
          rate={conversion?.conversionRate}
        />
      </HStack>
      <Modal.Footer
        isLoading={isConverting}
        onConfirm={async () => {
          const { valid, value: formValue } = valueRef.current.getValue<{
            value: number;
          }>();
          if (valid && selectedFromCurrency && selectedToCurrency) {
            const value = onSubmitValue(formValue.value);
            const response = await convertCcy({
              value,
              fromCurrency: selectedFromCurrency,
              toCurrency: selectedToCurrency,
            });
            handleResponse({
              response,
              onError: () => {},
              onSuccess: ({ data }) => {
                setConversion(data);
              },
            });
          }
        }}
        onCancel={() => closeModal(ConversionToolModalKey)}
      />
    </VStack>
  );
};

const ConversionToolModal = ({
  value,
  fromCurrency,
  toCurrency,
}: ConversionToolProps) => {
  const { closeModal } = useModal();

  return (
    <Modal.Container>
      <Modal.Header
        title={translate('conversionTool.header')}
        onPress={() => {
          closeModal(ConversionToolModalKey);
        }}
      />
      <ConversionTool value={value} fromCurrency={fromCurrency} toCurrency={toCurrency} />
    </Modal.Container>
  );
};

export const useConversionTool = () => {
  const { openModal } = useModal();

  return {
    open: ({
      value,
      fromCurrency,
      toCurrency,
    }: {
      value?: number;
      fromCurrency?: string;
      toCurrency?: string;
    }) => {
      openModal(
        <ConversionToolModal
          value={value}
          fromCurrency={fromCurrency}
          toCurrency={toCurrency}
        />,
        {
          type: 'action-sheet-full',
          key: ConversionToolModalKey,
        },
      );
    },
  };
};
