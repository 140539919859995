import { ColorKeys, Typography } from '@droplet-tech-code/core-elements/module/theme';
import { HStack } from '@droplet-tech-code/core-elements/module/ui/Stack';
import { AppTextProps, Text } from '@droplet-tech-code/core-elements/module/ui/Text';
import { isWeb } from '@droplet-tech-code/core-elements/module/utils/utils.helper';

import { LiveDateText } from '../DateText';
import { CopyableText } from '../Text/CopyableText';

export const Field = ({
  label,
  value,
  isDate,
  isCopyable,
  labelColor,
  color,
  onPress,
  labelType,
  valueType,
  vertical,
}: FieldProps) => {
  return (
    <HStack
      flex={isWeb ? 1 : undefined}
      style={{ flexDirection: vertical ? 'column' : 'row' }}
      space={vertical ? '1' : '2.5'}
      align="flex-start"
    >
      {label && (
        <Text.Small
          flex={vertical ? undefined : 1}
          type={labelType ?? 'small'}
          color={labelColor ?? 'monochrome-dark'}
          align="left"
        >
          {label}
        </Text.Small>
      )}
      <FieldValue
        value={value}
        isCopyable={isCopyable}
        isDate={isDate}
        vertical={vertical}
        color={color}
        valueType={valueType}
        onPress={onPress}
      />
    </HStack>
  );
};

const FieldValue = ({
  value,
  isCopyable,
  isDate,
  vertical,
  color,
  valueType,
  onPress,
}: FieldProps) => {
  const align = vertical ? 'left' : 'right';
  if (isDate) {
    return (
      <LiveDateText
        align={align}
        dateTime={value}
        formatOption="fullDateTime"
        isCopyable={isCopyable}
        color={color ?? 'black'}
        onPress={onPress}
        numberOfLines={1}
        type={valueType ?? 'body2Medium'}
      />
    );
  }
  if (isCopyable) {
    return (
      <CopyableText
        value={value}
        align={align}
        color={color ?? 'black'}
        numberOfLines={1}
        type={valueType ?? 'body2Medium'}
      />
    );
  }
  return (
    <Text.Body2Medium
      onPress={onPress}
      align={align}
      color={color ?? (onPress ? 'primary' : 'black')}
      type={valueType ?? 'body2Medium'}
      numberOfLines={1}
    >
      {value}
    </Text.Body2Medium>
  );
};

interface FieldProps extends Omit<AppTextProps, 'type'> {
  label?: string;
  value: string;
  isDate?: boolean;
  isCopyable?: boolean;
  color?: ColorKeys;
  labelColor?: ColorKeys;
  onPress?: () => void;
  vertical?: boolean;

  labelType?: keyof Typography;
  valueType?: keyof Typography;
}
