import { useAppTheme } from '@droplet-tech-code/core-elements/module/theme';
import { Modal, useModal } from '@droplet-tech-code/core-elements/module/ui/Modal';
import { dayjs } from '@droplet-tech-code/core-elements/module/utils/utils.date';
import { PurchaseClientWithMetaWithTickets } from '@naus-code/naus-admin-types';
import { RouteTrip, RouteTripNoDetails } from '@naus-code/naus-client-types';
import { MutableRefObject } from 'react';

import { ModalScrollView } from '~/components/Modals/Modal.utils';
import { SearchConstructorPanel } from '~/components/Search/Search.Constructor';
import { purchaseActionManager } from '~/screens/Support/Purchases/PurchaseActions/ActionPanel/Common/ActionPanel.utils';
import { ModifyPanelProps } from '~/screens/Support/Purchases/PurchaseActions/ActionPanel/Modify/Modify.utils';
import { DATE_SERVER_FORMAT } from '~/utils/date.utils';
import { translate } from '~/utils/translation.utils';

export interface SearchTripInfo {
  destId: string;
  destDisplay: string;
  originId: string;
  originDisplay: string;
  depDateTime: string;
}

export const SearchPanelQuery = ({
  purchase,
  selectRouteRef,
  ...props
}: {
  purchase?: PurchaseClientWithMetaWithTickets;

  selectRouteRef: MutableRefObject<RouteTrip | RouteTripNoDetails | undefined>;
} & ModifyPanelProps) => {
  const { spacing } = useAppTheme();
  const { closeModal } = useModal();
  const [_, setManualRoute] = purchaseActionManager.state.useGlobalState(
    'manuallySelectedRoute',
  );
  return (
    <Modal.Container w={1000}>
      <Modal.Header
        title={translate('purchases.purchaseTool.manualTripSelector')}
        onPress={() => closeModal('searchTripQuery')}
        divider
      />
      <ModalScrollView contentContainerStyle={{ padding: spacing[3] }}>
        <SearchPanelBody
          {...props}
          purchase={purchase}
          selectRouteRef={selectRouteRef}
          noBorder
        />
      </ModalScrollView>
      <Modal.Footer
        confirm="Confirm & select route"
        onConfirm={() => {
          if (!selectRouteRef.current) {
            return;
          }
          setManualRoute(selectRouteRef.current);
          closeModal('searchTripQuery');
        }}
      />
    </Modal.Container>
  );
};

export const SearchPanelBody = ({
  purchase,
  selectRouteRef,
  noBorder,
  ...props
}: {
  noBorder?: boolean;
  purchase?: PurchaseClientWithMetaWithTickets;

  selectRouteRef: MutableRefObject<RouteTrip | RouteTripNoDetails | undefined>;
} & ModifyPanelProps) => {
  return (
    <SearchConstructorPanel
      purchaseId={props.purchaseId}
      destinationId={purchase?.destination ?? ''}
      originId={purchase?.origin ?? ''}
      destinationInitialValue={purchase?.destinationDisplay ?? ''}
      originInitialValue={purchase?.originDisplay ?? ''}
      noBorder={noBorder}
      depDate={
        purchase?.departureDateTime.split(' ')[0] ?? dayjs().format(DATE_SERVER_FORMAT)
      }
      onSelectRoute={(newRoute) => {
        if (newRoute?.type === 'direct') {
          selectRouteRef.current = newRoute.trip;
        } else {
          selectRouteRef.current = undefined;
        }
      }}
    />
  );
};
