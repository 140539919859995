import {
  CompanyConfigClientCreateCompanyRequest,
  CompanyConfigClientGetFerryConfigResponse,
  CompanyConfigClientGetListResponse,
  CompanyConfigClientUpdateActiveConfigRequest,
  CompanyConfigClientUpdateFerryOperationsRequest,
  CompanyConfigClientUpdateOperatorContactsRequest,
  CompanyConfigClientUpdateOperatorETicketConfigRequest,
  CompanyConfigClientUpdateOperatorOperationsRequest,
  CompanyConfigClientUpdateOperatorTermsRequest,
  CompanyConfigUpdateFerryCompanyOperatorRequest,
} from '@naus-code/naus-admin-types';
import { useCallback } from 'react';

import { useFormModal } from '~/components/Modals/FormModal';
import { companyConfigApi } from '~/screens/Configs/Company/CompanyConfigs.api';
import { translate } from '~/utils/translation.utils';

export const useUpdateFerryCompanyModal = (companyId: string) => {
  const { open: openForm, close: closeForm } = useFormModal();

  const useFormHookHandler = useCallback(() => {
    const { data, isLoading } =
      companyConfigApi.useUpdateFerryCompanyConfigFormConfigQuery(companyId);
    return { data, isLoading };
  }, [companyId]);

  const onConfirm = async ({
    action,
    value,
    check,
  }: {
    action: (
      req: CompanyConfigClientCreateCompanyRequest,
    ) => CompanyConfigClientGetListResponse;
    value: CompanyConfigClientCreateCompanyRequest['companyDetails'];
    check?: string;
  }) => {
    return await action({
      companyDetails: value,
      check: check || '',
    });
  };

  return {
    open: () => {
      openForm({
        modalTitle: translate('companyConfig.updateFerryCompany'),
        useFormHookHandler,
        onConfirm,
        actionHook: companyConfigApi.useUpdateFerryCompanyConfigMutation,
      });
    },
    close: () => {
      closeForm();
    },
  };
};

export const useUpdateActiveConfigModal = (companyId: string, check: string) => {
  const { open: openForm, close: closeForm } = useFormModal();

  const useFormHookHandler = useCallback(() => {
    const { data, isLoading } =
      companyConfigApi.useUpdateFerryActiveConfigFormConfigQuery(companyId);
    return { data, isLoading };
  }, [companyId]);

  const onConfirm = async ({
    action,
    value,
  }: {
    action: (
      req: CompanyConfigClientUpdateActiveConfigRequest,
    ) => CompanyConfigClientGetFerryConfigResponse;
    value: CompanyConfigClientUpdateActiveConfigRequest['activeConfig'];
  }) => {
    return await action({
      activeConfig: value,
      companyId,
      check,
    });
  };

  return {
    open: () => {
      openForm({
        modalTitle: translate('companyConfig.updateActiveConfig'),
        useFormHookHandler,
        onConfirm,
        actionHook: companyConfigApi.useUpdateFerryActiveConfigMutation,
      });
    },
    close: () => {
      closeForm();
    },
  };
};

export const useUpdateFerryCompanyOperatorModal = (
  operatorId: string,
  companyId: string,
) => {
  const { open: openForm, close: closeForm } = useFormModal();

  const useFormHookHandler = useCallback(() => {
    const { data, isLoading } =
      companyConfigApi.useUpdateFerryCompanyOperatorConfigFormConfigQuery(operatorId);
    return { data, isLoading };
  }, [operatorId]);

  const onConfirm = async ({
    action,
    value,
    check,
  }: {
    action: (
      req: CompanyConfigUpdateFerryCompanyOperatorRequest,
    ) => CompanyConfigClientGetFerryConfigResponse;
    value: CompanyConfigUpdateFerryCompanyOperatorRequest['operator'];
    check?: string;
  }) => {
    return await action({
      operator: value,
      companyId,
      check: check || '',
    });
  };

  return {
    open: () => {
      openForm({
        modalTitle: translate('companyConfig.updateFerryCompanyOperator'),
        useFormHookHandler,
        onConfirm,
        actionHook: companyConfigApi.useUpdateFerryCompanyOperatorConfigMutation,
      });
    },
    close: () => {
      closeForm();
    },
  };
};

export const useUpdateFerryOperatorContactModal = (
  operatorId: string,
  companyId: string,
) => {
  const { open: openForm, close: closeForm } = useFormModal();

  const useFormHookHandler = useCallback(() => {
    const { data, isLoading } =
      companyConfigApi.useUpdateFerryOperatorContactConfigFormConfigQuery({
        operatorId,
        companyId,
      });
    return { data, isLoading };
  }, [operatorId, companyId]);

  const onConfirm = async ({
    action,
    value,
    check,
  }: {
    action: (
      req: CompanyConfigClientUpdateOperatorContactsRequest,
    ) => CompanyConfigClientGetFerryConfigResponse;
    value: CompanyConfigClientUpdateOperatorContactsRequest['contactDetails'];
    check?: string;
  }) => {
    return await action({
      contactDetails: value,
      operatorId,
      companyId,
      check: check || '',
    });
  };

  return {
    open: () => {
      openForm({
        modalTitle: translate('companyConfig.updateFerryOperatorContact'),
        onConfirm,
        useFormHookHandler,
        actionHook: companyConfigApi.useAddFerryCompanyExtraConfigMutation,
      });
    },
    close: () => {
      closeForm();
    },
  };
};

export const useUpdateFerryOperatorETicketModal = (
  operatorId: string,
  companyId: string,
) => {
  const { open: openForm, close: closeForm } = useFormModal();

  const useFormHookHandler = useCallback(() => {
    const { data, isLoading } =
      companyConfigApi.useUpdateFerryOperatorETicketConfigFormConfigQuery({
        operatorId,
        companyId,
      });
    return { data, isLoading };
  }, [operatorId, companyId]);

  const onConfirm = async ({
    action,
    value,
    check,
  }: {
    action: (
      req: CompanyConfigClientUpdateOperatorETicketConfigRequest,
    ) => CompanyConfigClientGetFerryConfigResponse;
    value: CompanyConfigClientUpdateOperatorETicketConfigRequest['eTicketConfig'];
    check?: string;
  }) => {
    return await action({
      eTicketConfig: value,
      operatorId,
      companyId,
      check: check ?? '',
    });
  };

  return {
    open: () => {
      openForm({
        modalTitle: translate('companyConfig.updateFerryOperatorETicket'),
        useFormHookHandler,
        onConfirm,
        actionHook: companyConfigApi.useUpdateFerryOperatorETicketConfigMutation,
      });
    },
    close: () => {
      closeForm();
    },
  };
};

export const useUpdateFerryOperatorTermsModal = (
  operatorId: string,
  companyId: string,
) => {
  const { open: openForm, close: closeForm } = useFormModal();

  const useFormHookHandler = useCallback(() => {
    const { data, isLoading } =
      companyConfigApi.useUpdateFerryOperatorTermsConfigFormConfigQuery({
        operatorId,
        companyId,
      });
    return { data, isLoading };
  }, [operatorId, companyId]);

  const onConfirm = async ({
    action,
    value,
    check,
  }: {
    action: (
      req: CompanyConfigClientUpdateOperatorTermsRequest,
    ) => CompanyConfigClientGetFerryConfigResponse;
    value: CompanyConfigClientUpdateOperatorTermsRequest['terms'];
    check?: string;
  }) => {
    return await action({
      terms: value,
      operatorId,
      companyId,
      check: check || '',
    });
  };

  return {
    open: () => {
      openForm({
        modalTitle: translate('companyConfig.updateFerryOperatorTerms'),
        useFormHookHandler,
        onConfirm,
        actionHook: companyConfigApi.useUpdateFerryOperatorTermsConfigFormConfigQuery,
      });
    },
    close: () => {
      closeForm();
    },
  };
};

export const useUpdateFerryOperatorCustomerOperationModal = (
  operatorId: string,
  companyId: string,
) => {
  const { open: openForm, close: closeForm } = useFormModal();

  const useFormHookHandler = useCallback(() => {
    const { data, isLoading } =
      companyConfigApi.useUpdateFerryOperatorCustomerOperationFormConfigQuery({
        operatorId,
        companyId,
      });
    return { data, isLoading };
  }, [operatorId, companyId]);

  const onConfirm = async ({
    action,
    value,
    check,
  }: {
    action: (
      req: CompanyConfigClientUpdateOperatorOperationsRequest,
    ) => CompanyConfigClientGetFerryConfigResponse;
    value: CompanyConfigClientUpdateOperatorOperationsRequest['customOperationsConfig'];
    check?: string;
  }) => {
    return await action({
      customOperationsConfig: value,
      operatorId,
      companyId,
      check: check || '',
    });
  };

  return {
    open: () => {
      openForm({
        modalTitle: translate('companyConfig.updateFerryOperatorCustomerOperation'),
        useFormHookHandler,
        onConfirm,
        actionHook: companyConfigApi.useUpdateFerryOperatorCustomerOperationMutation,
      });
    },
    close: () => {
      closeForm();
    },
  };
};

export const useUpdateFerryCompanyOperationsModal = (companyId: string) => {
  const { open: openForm, close: closeForm } = useFormModal();

  const useFormHookHandler = useCallback(() => {
    const { data, isLoading } =
      companyConfigApi.useGetUpdateFerryOperationsFormConfigQuery(companyId);
    return { data, isLoading };
  }, [companyId]);

  const onConfirm = async ({
    action,
    value,
    check,
  }: {
    action: (
      req: CompanyConfigClientUpdateFerryOperationsRequest,
    ) => CompanyConfigClientGetFerryConfigResponse;
    value: CompanyConfigClientUpdateFerryOperationsRequest['config'];
    check?: string;
  }) => {
    return await action({
      config: value,
      companyId,
      check: check || '',
    });
  };
  return {
    open: () => {
      openForm({
        modalTitle: translate('companyConfig.updateFerryCompanyOperations'),
        useFormHookHandler,
        onConfirm,
        actionHook: companyConfigApi.useGetUpdateFerryOperationsFormConfigQuery,
      });
    },
    close: () => {
      closeForm();
    },
  };
};
