import { TicketsPricingPassengerForm } from './TicketsPricing.Passenger.Form';
import { TicketsPricingPassengerItem } from './TicketsPricing.TripItem';

export const TicketsPricingPassenger = (props: TicketsPricingPassengerItem) => {
  return (
    <>
      {/* <TicketPricingPassengerHeader {...props} /> */}
      <TicketsPricingPassengerForm {...props} />
    </>
  );
};
