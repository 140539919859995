import { createContext, PropsWithChildren, useContext, useState } from 'react';
import { StoreApi, UseBoundStore } from 'zustand';

import {
  createManualModificationStore,
  ManualProviderStore,
} from './Modify.ManualTrip.Provider.store';

const ManualContext = createContext({} as UseBoundStore<StoreApi<ManualProviderStore>>);

export const ManualModificationProvider = (props: PropsWithChildren) => {
  const [store] = useState(() => {
    return createManualModificationStore();
  });

  return <ManualContext.Provider value={store}>{props.children}</ManualContext.Provider>;
};

export const useManualStore = () => {
  return useContext(ManualContext);
};
