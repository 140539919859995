import { FormHandler } from '@droplet-tech-code/core-elements/module/ui/Inputs/Form';
import { dayjs } from '@droplet-tech-code/core-elements/module/utils/utils.date';
import {
  PurchaseClientWithClientTickets,
  PurchaseClientWithMetaWithTickets,
  TicketClient,
} from '@naus-code/naus-admin-types';

import { onSubmitValue } from '~/components/SupportRequest/utils/utils';

import { getBasePurchaseToUpdate } from './Modify.ManualTrip.purchase.utils';
import {
  getPassengerTicketsToUpdate,
  getPetTicketsToUpdate,
  getVehicleTicketsToUpdate,
} from './Modify.ManualTrip.ticket.utils';

export function createManualUpdatePurchases(
  modificationForms: {
    [key: string]: FormHandler[];
  },
  selectedTickets: string[],
  purchases: PurchaseClientWithMetaWithTickets[],
):
  | {
      manualModificationPurchases: PurchaseClientWithClientTickets[];
      operatorFareDifference: number | undefined;
      amendmentDatetime: string | undefined;
    }
  | undefined {
  const purchasesToUpdate = [] as PurchaseClientWithClientTickets[];
  const { operatorFareDifference, amendmentDatetime } = getBookingInfo(
    modificationForms,
    purchases[0].bookingRef,
  );
  const utcOffset = dayjs().utcOffset();
  const amendmentDatetimeUTC = dayjs(amendmentDatetime, 'YYYY-MM-DD HH:mm')
    .subtract(utcOffset, 'm')
    .format('YYYY-MM-DD HH:mm');

  for (const purchase of purchases) {
    const purchaseForms = modificationForms[purchase._id];
    const purchaseToUpdate = getBasePurchaseToUpdate(purchase, purchaseForms);
    if (!purchaseToUpdate) {
      return;
    }
    const purchaseTickets = purchase.tickets;
    const updatedTickets = getTicketsToUpdate(
      purchaseTickets,
      purchase._id,
      selectedTickets,
      modificationForms,
    );
    if (!updatedTickets) {
      return;
    }

    purchaseToUpdate['tickets'] = updatedTickets;
    purchasesToUpdate.push({ ...purchaseToUpdate, dateModified: amendmentDatetimeUTC });
  }

  return {
    manualModificationPurchases: purchasesToUpdate,
    operatorFareDifference,
    amendmentDatetime: amendmentDatetimeUTC,
  };
}

function getBookingInfo(
  modificationForms: {
    [key: string]: FormHandler[];
  },
  bookingRef: string,
) {
  const { valid, value } = modificationForms[bookingRef][0].getValue<BookingForm>();
  if (valid) {
    return {
      operatorFareDifference: onSubmitValue(value.operatorFareDifference),
      amendmentDatetime: value.amendmentDatetime,
    };
  }
  return { operatorFareDifference: undefined, ammendmentDatetime: undefined };
}

interface BookingForm {
  operatorFareDifference: number;
  amendmentDatetime: string;
}

function getTicketsToUpdate(
  purchaseTickets: TicketClient[],
  purchaseId: string,
  selectedTickets: string[],
  modificationForms: {
    [key: string]: FormHandler[];
  },
) {
  const ticketsToUpdate: TicketClient[] = [];
  for (const ticket of purchaseTickets) {
    const isSelected = selectedTickets.indexOf(ticket._id) >= 0;
    if (isSelected) {
      const ticketForms = modificationForms[purchaseId + ticket._id];
      let ticketToUpdate: TicketClient | undefined;
      switch (ticket.ticketType) {
        case 'Passenger':
          ticketToUpdate = getPassengerTicketsToUpdate(ticket, ticketForms);
          break;
        case 'Vehicle':
          ticketToUpdate = getVehicleTicketsToUpdate(ticket, ticketForms);
          break;
        case 'Pet':
          ticketToUpdate = getPetTicketsToUpdate(ticket, ticketForms);
          break;
        default:
          return undefined;
      }
      if (!ticketToUpdate) {
        return undefined;
      }

      ticketsToUpdate.push(ticketToUpdate);
    }
  }
  return ticketsToUpdate;
}
