import { isWeb } from '@droplet-tech-code/core-elements/module/utils/utils.helper';
import { useState } from 'react';

import { PageContainer, ScreenTwoPageLayout } from '~/components/Page/Page.Container';
import type { ScreenPropPendingSupportRequests } from '~/navigation/Navigation.types';

import { SupportRequestItem } from '../SupportRequestItem';
import { SupportRequestList, SupportRequestListNative } from './SupportRequest.List';

export const PendingSupportRequestsScreen = (props: ScreenPropPendingSupportRequests) => {
  return (
    <PageContainer>
      <PendingSupportRequestsLayout {...props} />
    </PageContainer>
  );
};

export const PendingSupportRequestsLayout = (props: ScreenPropPendingSupportRequests) => {
  const supportRequestId = props.route.params?.id;
  const [id, setId] = useState<string | undefined>(supportRequestId);

  return (
    <ScreenTwoPageLayout>
      {isWeb ? (
        <SupportRequestList
          supportRequestId={id}
          onPress={(supportRequest) => setId(supportRequest.id)}
        />
      ) : (
        <SupportRequestListNative />
      )}
      {id ? (
        <SupportRequestItem supportId={id} onClose={() => setId(undefined)} />
      ) : (
        <></>
      )}
    </ScreenTwoPageLayout>
  );
};
